<button mat-dialog-close class="close-btn">
  <mat-icon>close</mat-icon>
</button>
<mat-card class="navigation__wrapper">
  <h1 mat-dialog-title>Configuration</h1>
  <mat-dialog-content class="navigation">
    <div>
      <a mat-flat-button class="link" mat-dialog-close routerLink="/commande">
        <b>Gestion des commandes</b>
      </a>
      <a mat-flat-button class="link" mat-dialog-close routerLink="/gestion">
        Gestion de la boutique
      </a>
      <a mat-flat-button class="link" mat-dialog-close (click)="_redirectCatalog()">
        Catalogue produit
      </a>
      <a mat-flat-button class="link" mat-dialog-close routerLink="/stripe">
        Gestion Stripe
      </a>
      <a mat-flat-button class="link" mat-dialog-close routerLink="/reporting">
        Rapports statistiques
      </a>
      <a mat-flat-button class="link no-border-bottom" *ngIf="guidePdf.length > 0" [attr.href]="guidePdf" target="_blank">Guide d'utilisation</a>

    </div>
    <div>
      <a mat-flat-button class="link" mat-dialog-close routerLink="/profiles">
        Changer de profil
      </a>
      <mat-slide-toggle 
        (toggleChange)="toggleDarkMode()" 
        [checked]="(isDarkMode$ | async)"
      >
        Mode sombre
      </mat-slide-toggle>
      <a mat-flat-button class="link" mat-dialog-close routerLink="/settings">
        Paramètres
      </a>
    </div>
    <!-- <a mat-flat-button class="link" mat-dialog-close routerLink="/commande/test-status-commande">
      Test status commande
    </a> -->
  </mat-dialog-content>
  <div class="disconnect-btn">
    <button mat-stroked-button mat-dialog-close (click)="_logout()">Se déconnecter</button>
  </div>
</mat-card>