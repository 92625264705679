<div class="gestion__container">
    <div class="gestion__header">
      <div class="gestion__row">
        <a mat-icon-button class="back_button" routerLink="/catalog/add"></a>
        <div class="titles">
          <h2>Ajouter un pack</h2>
          <span>de produits</span>
        </div>
        <a mat-flat-button color="primary" class="commande__ready catalog-create-product" (click)="createProduct()">
          <mat-icon aria-hidden="true" class="ok">done</mat-icon>Créer le pack
        </a>
      </div>
      <div class="gestion__row filters">
        <div class="sort">
          <a href="#!" [ngClass]="{'active': currentTab === 'config'}" (click)="changeFiltre($event, 'config')">
            Configuration
          </a>
          <!-- <a href="#!" [ngClass]="{'active': currentTab === 'attr'}" (click)="changeFiltre($event, 'attr')">
            Attributs
          </a> -->
          <a href="#!" [ngClass]="{'active': currentTab === 'products'}" (click)="changeFiltre($event, 'products')">
            Produits du pack
          </a>
          <a href="#!" [ngClass]="{'active': currentTab === 'market'}" (click)="changeFiltre($event, 'market')">
            Mise en avant marketing
          </a>
        </div>
      </div>
    </div>
    <div class="gestion__body">
      <div class="gestion__row">
        <div class="shop__container">
          <div [hidden]="currentTab !== 'config'">
            <app-catalog-config
              [(data)]="dataConfig"
            ></app-catalog-config>
          </div>
          <div [hidden]="currentTab !== 'attr'">
            <app-catalog-attributes
              [(data)]="dataConfig"
            ></app-catalog-attributes>
          </div>
          <div [hidden]="currentTab !== 'products'">
            <app-catalog-products-pack
              [(data)]="dataConfig"
            ></app-catalog-products-pack>
          </div>
          <div [hidden]="currentTab !== 'market'">
            <app-catalog-market
            [(data)]="dataConfig"
            ></app-catalog-market>
          </div>
        </div>
      </div>
    </div>
  </div>