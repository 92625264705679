import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { selectProfilShopName } from 'src/app/reducers/profil/profil.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styles: [
  ],
  host: {
    'class': 'gestion-home__wrapper'
  }
})
export class CatalogAddComponent implements OnInit {
  shopName$: Observable<string> = this.store.select(selectProfilShopName)

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
  }

}
