import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "../onBoarding/login/login.component";
import { AutoLoginComponent } from "../onBoarding/autologin/autologin.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { OnBoardingGuard } from "./on-boarding.guard";

const appRoutes: Routes = [
  {
    path: "auth",
    canActivateChild: [OnBoardingGuard],
    children: [
      { path: "", component: LoginComponent },
      { path: "autologin", component: AutoLoginComponent },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "reset-password", component: ResetPasswordComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule],
})
export class OnBoardingRoutingModule {}
