<form [formGroup]="formGroup">
  <ng-container *ngIf="!loader; else loadBlock">
    <div class="row">
      <div class="column small-12 medium-6">
        <div class="gestion__block">
          <div class="gestion__input-box">
            <label for="product-name" class="fz12">Nom du produit *</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="product-name" 
                type="text"
                [formControl]="name"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="product-ref">Référence *</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="product-ref" 
                type="text"
                [formControl]="ref"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="product-major">+ 18 ans</label>
            <mat-checkbox id="product-major" [formControl]="majority_validation" (change)="toggleProductMajor($event)"></mat-checkbox>  
          </div>
        </div>
        <div class="gestion__block">
          <div class="gestion__input-box">
            <label for="product-tax">TVA</label>
            <mat-form-field class="gestion__input">
              <mat-select 
                id="product-tax" 
                [formControl]="tax"
                (selectionChange)="changeSelect($event)"
              >
                <mat-option *ngFor="let opt of data?.dataForm?.tax" [value]="opt.value">{{opt.label}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="product-categories">Catégorie *</label>
            <mat-form-field class="gestion__input">
              <mat-select 
                #categ
                id="product-categories" 
                multiple
                [formControl]="categories"
                (selectionChange)="changeSelect($event)"
                [disableRipple]="true"
                [disableOptionCentering]="true"
                placeholder="Sélectionnez"
              >
                <button class="close_select_categories" (click)="categ.close()"></button>
                <ng-container *ngFor="let item of data?.dataForm?.categories">
                  <mat-optgroup *ngIf="item.children.length > 0" [label]="item.name">
                    <ng-container *ngFor="let subitem of item.children">
                        <mat-accordion *ngIf="subitem.children.length > 0">
                          <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-optgroup [label]="subitem.name"></mat-optgroup>
                            </mat-expansion-panel-header>
                            <mat-option *ngFor="let opt of subitem.children" [value]="opt.id">
                              {{opt.name}}
                            </mat-option>
                          </mat-expansion-panel>
                        </mat-accordion>
                    </ng-container>
                  </mat-optgroup>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="column small-12 medium-6 column_photo">
        <div class="gestion__block">
          <div class="gestion__block--title">
            <span>Photos du produit</span>
            <button mat-button color="primary" type="button">
              <label for="uploadImage">
                <ng-container>Ajouter</ng-container>
              </label>
            </button>
          </div>
          <input 
            hidden  
            id="uploadImage" 
            type="file" 
            (change)="fileChangeEvent($event)" 
            class="uploadImageInput" 
            placeholder="Yes"
            multiple
          >
          <div class="imageError" *ngIf="imageError">{{imageError}}</div>
          <div class="catalog-add-photo-product-container">
            <div class="catalog-add-photo-product" *ngFor="let opt of data?.dataConfig?.images; let index = index">
              <img [src]="opt.url"  class="catalog-add-photo-image">
              <button mat-icon-button class="catalog-delete-photo-product" (click)="deleteImageProduct(index)"></button>
            </div>
          </div>
        </div>
        <div class="gestion__block">
          <div class="gestion__input-box">
            <label for="product-weight">Poids *</label>
            <mat-form-field class="gestion__input">
              <span matSuffix class="input-icon">Kg</span>
              <input 
                matInput
                id="product-weight" 
                type="text"
                [formControl]="weight"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="product-qty">Quantité</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="product-qty" 
                type="text"
                [formControl]="qty"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column small-12 large-12">
        <div class="gestion__block">
          <div class="gestion__input-box">
            <label for="product-description">Catégorie marchande</label>
            <mat-form-field class="gestion__input">
              <mat-select 
                id="product-categoryMerchant" 
                [formControl]="categoryMerchant"
                (selectionChange)="changeSelect($event)"
                placeholder="Sélectionnez une catégorie produit"
              >
                <mat-option *ngFor="let opt of data?.dataForm?.categoryMerchant" [value]="opt.id">{{opt.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column small-12">
        <div class="gestion__block">
          <div class="gestion__input-box">
            <label for="product-description">Description du produit</label>
            <mat-form-field class="gestion__input">
              <textarea 
                matInput
                id="product-description" 
                [formControl]="description"
                (input)="changeInputText()"
                placeholder="Détaillez votre produit pour une meilleure compréhension client"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="delete_product_button" *ngIf="isEdit">
      <button type="button"  mat-flat-button color="secondary" class="delete" (click)=deleteProduct(data?.dataConfig?.sku)>
        Supprimer le produit
      </button> 
    </div> -->
  </ng-container>
  <ng-template #loadBlock>
    <div class="loader_container">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </ng-template>
</form>
