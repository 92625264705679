import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, first, switchMap, map, take, share, delay } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { Store } from '@ngrx/store';
import { ShopState, ShopHoraire } from 'src/app/models/shop';
import { setShop, setEmail, setDescription, setDelay, setDisableToday, addExceptionalClosure, removeExceptionalClosure, setHoraires, setVisualUrl, setQuota } from 'src/app/reducers/shop/shop.action';
import { MessageService } from '../message/message.service';
import { selectShopExceptionalClosings } from 'src/app/reducers/shop/shop.selector';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  storeExceptionalClosings$ = this.store.select(selectShopExceptionalClosings)

  constructor(
    private httpService: HttpService,
    private store: Store,
    private messageService: MessageService
  ) { }

  getShop(){
    return this.httpService.apiRequest('get', 'shop/', {}, {}).pipe(
      first(),
    ).subscribe((shop) => {
      // on met à jour le store
      this.store.dispatch(setShop({shop}))
    })
  }

  postShop(obj:any): Observable<any>{
    return this.httpService.apiRequest('post', 'shop/', {shopData: obj}, {})
  }

  setImage(visual_url:string): void {
    let imageUpload = visual_url.split(',')[1];
    let obj = {visual_url: imageUpload}
    this.postShop(obj).subscribe(success => {
      // on met à jour le store
      this.store.dispatch(setVisualUrl({visual_url}))
      // on montre une popin success
      this.messageService.openSnackBar('La photo de la boutique a été modifiée avec succès', 'success')
    }, error => {
      // on montre une popin error
      this.messageService.openSnackBar('Une erreur est survenue', 'error')
    })
  }

  setEmail(contact_email:string): void {
    let obj = {contact_email}
    this.postShop(obj).subscribe(success => {
      // on met à jour le store
      this.store.dispatch(setEmail({contact_email}))
      // on montre une popin success
      this.messageService.openSnackBar('L\'adresse e-mail a été modifiéee avec succès', 'success')
    }, error => {
      // on montre une popin error
      this.messageService.openSnackBar('Une erreur est survenue', 'error')
    })
  }

  setDescription(description:string): void {
    let obj = {description}
    this.postShop(obj).subscribe(success => {
      // on met à jour le store
      this.store.dispatch(setDescription({description}))
      // on montre une popin success
      this.messageService.openSnackBar('La description de la boutique a été modifiée avec succès', 'success')
    }, error => {
      // on montre une popin error
      this.messageService.openSnackBar('Une erreur est survenue', 'error')
    })
  }

  setDelay(preparation_time: string): void {
    let obj = {preparation_time}
    this.postShop(obj).subscribe(success => {
      // on met à jour le store
      this.store.dispatch(setDelay({preparation_time}))
      // on montre une popin success
      // this.messageService.openSnackBar('Le délai de préparation a été modifié avec succès', 'success')
    }, error => {
      // on montre une popin error
      // this.messageService.openSnackBar('Une erreur est survenue', 'error')
    })
  }

  setQuota(quota: string): void {
    let obj = {order_quota: quota}
    this.postShop(obj).subscribe(success => {
      // on met à jour le store
      this.store.dispatch(setQuota({order_quota: quota}))
      // on montre une popin success
      this.messageService.openSnackBar('Les modifications ont bien été prises en compte', 'success')
    }, error => {
      // on montre une popin error
      this.messageService.openSnackBar('Une erreur est survenue', 'error')
    })
  }

  setDisableToday(time_slot_disable_today: string): void {
          let obj = {time_slot_disable_today}
          this.postShop(obj).subscribe(success => {
          // on met à jour le store
          this.store.dispatch(setDisableToday({time_slot_disable_today}))
          // on montre une popin success
          this.messageService.openSnackBar('Changement effectué avec succès', 'success')
        }, error => {
          // on montre une popin error
          this.messageService.openSnackBar('Une erreur est survenue', 'error')
        })
  }

  addExceptionalClosure(date: string): void {
    // on met à jour le store
    this.storeExceptionalClosings$.pipe(first()).subscribe(days => {
      let obj = {exceptionalClosings: [...days, date]}
      this.postShop(obj).pipe(first()).subscribe(success => {
        this.store.dispatch(addExceptionalClosure({date}))
        this.messageService.openSnackBar('Date ajoutée avec succès', 'success')
      }, error => {
        // on montre une popin error
        this.messageService.openSnackBar('Une erreur est survenue', 'error')  
      })
    });
  }

  removeExceptionalClosure(date: string): void {
    // on met à jour le store
    this.storeExceptionalClosings$.pipe(first()).subscribe(days => {
      let filterArray = days.filter(el => ( el != date))
      let obj = {exceptionalClosings: filterArray}
      this.postShop(obj).pipe(first()).subscribe(success => {
        this.store.dispatch(removeExceptionalClosure({date}))
        this.messageService.openSnackBar('Date supprimée avec succès', 'success')
      }, error => {
        // on montre une popin error
        this.messageService.openSnackBar('Une erreur est survenue', 'error')
      })
    })
  }

  setHoraires(horaires: ShopHoraire[]) {
    let obj = {horaires}
    this.postShop(obj).pipe(first()).subscribe(success => {
      this.store.dispatch(setHoraires({horaires}))
      // on met à jour le store
      this.messageService.openSnackBar('L\'horaire a été modifiée avec succès', 'success')
    }, error => {
        // on montre une popin error
        this.messageService.openSnackBar('Une erreur est survenue', 'error')
      }
    )}
  }

const fakeRequest = (success = 0.9): Observable<boolean> => {
  const isSuccess = success - Math.random() > 0;
  const delay = getRandomInt(600, 1400);
  return Observable.create(observer => {
    setTimeout(() => {
      if (isSuccess) {
        observer.next()
      } else {
        observer.error("Une erreur est arrivée")
      }
      observer.complete()
    }, delay);
  })
}
const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// const fakeData: ShopState = {
//   visual_url: 'https://picsum.photos/id/163/400/200',
//   contact_email: 'Maboutique@laposte.net',
//   description: 'MaBoutique est un restaurant spécialisé dans la nourriture.',
//   delay: 15,
//   preparation_time: "10",
//   time_slot_disable_today: "0",
//   exceptionalClosings: [],
//   horaires: [
//     {closed: false, slots: [{start: '9h30', end: '18h00', quota: 10}, {start: null, end: null, quota: 10}]},
//     {closed: false, slots: [{start: '8h30', end: '12h30', quota: 10}, {start: '13h30', end: '18h00', quota: 10} ]},
//     {closed: false, slots: [{start: '8h30', end: '12h30', quota: 10}, {start: null, end: null, quota: 10} ]},
//     {closed: false, slots: [{start: '8h00', end: '13h00', quota: 10}, {start: '13h30', end: '19h00', quota: 10} ]},
//     {closed: false, slots: [{start: '8h30', end: '12h30', quota: 10}, {start: '13h30', end: '18h00', quota: 10} ]},
//     {closed: false, slots: [{start: '8h30', end: '12h30', quota: 10}, {start: '13h30', end: '18h00', quota: 10} ]},
//     {closed: true , slots: [{start: '8h30', end: '12h30', quota: 10}, {start: null, end: null, quota: 10}]},
//   ]
// } 