<div class="menu" [ngClass]="{'small': !showLinksMenu()}">
  <div class="menu__navigation">
    <a mat-flat-button routerLink="/commande" routerLinkActive="active" *ngIf="showLinksMenu()">Commandes</a>
    <a mat-flat-button routerLink="/historique" routerLinkActive="active" *ngIf="showLinksMenu()">Historique</a>
    <a mat-flat-button routerLink="/reporting" routerLinkActive="active" *ngIf="showLinksMenu()">Statistiques</a>
  </div>
  <div class="menu__bloc">
    <a routerLink="/commande"><img src="assets/images/logo-lagalerie.png"></a>
    <button mat-flat-button (click)="openMenu()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</div>