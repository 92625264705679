import { Component, OnInit, Input, HostBinding, EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectProfilPhoto } from 'src/app/reducers/profil/profil.selector';
import { Commande } from 'src/app/models/commande';
import { Router, UrlTree } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from 'src/app/_components/search-modal/search-modal.component';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { MultiboutiqueWaitingModalComponent } from 'src/app/_components/multiboutique-waiting/multiboutique-waiting-modal.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styles: [],
  host: { 
    '[class.wide]': 'wide',
    class: 'sidebar mat-elevation-z10'
  }
})
export class AppSidebarComponent implements OnInit {

  profilPhoto$: Observable<string> = this.store.select(selectProfilPhoto);
  formatToday:string
  minDate: any = new Date(2020, 0, 1);
  maxDate: any =  new Date()
  item_per_page: number = this.commandesService.item_per_page
  _pageIndex = 0
  
	@HostBinding('class.is--open') isOpen: boolean = false;
  @Input() wide: boolean
  @Input() orders: Commande[]
  @Input() beforeOrders: Commande[]
  @Input() waitingOrders: Commande[]
  @Input() totalOrders: number = 0  
  @Input() max_page: number = 0  
  @Input() search: {key:string, value: string}
  @Input() 
  set pageIndex(pageIndex: number) {
    this._pageIndex = pageIndex - 1
  }
  get pageIndex():number {return this._pageIndex + 1}

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    public commandesService: CommandesService
	) { }

  ngOnInit() {
    this.formatToday = this.wide ? 'Aujourd\'hui' : 'En préparation'
  }

  toggleSidebar(): void {
      this.isOpen = !this.isOpen
  }

  onSelectDatePicker(value:string): void {
    const date = moment(value).format('YYYY-MM-DD')
    this.router.navigate(['historique'], {
      queryParams: {date}
    })
  }

  onClick(): void {
    this.isOpen = true;
  }

  showWaitingModal(): void {      
    const dialogRef3 = this.dialog.open(MultiboutiqueWaitingModalComponent, {
      panelClass: "modal__ticket",
      disableClose: true
    });
  }

  
  openModaleSearch(){        
    const dialogRef = this.dialog.open(SearchModalComponent, {
      panelClass: "modal__search",
      data: {
        onSubmit: (data) => this.onSubmit(data),
        label: 'Entrez le numéro de commande :'
      }
    });
  }

  onSubmit(id: string): void {
    this.router.navigate(['/historique'], {
      queryParams: { id }
    })
  }

  pageChange(event: PageEvent) {
    // on récupère les params d'url
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);
    const {id, date} = urlTree.queryParams;
    const page = event.pageIndex + 1
    const segments = urlTree.root.children.primary.segments;
    const urlSegments = segments.map(seg => seg.path) || []
    this.router.navigate(['historique'], {
      queryParams: { date, id, page }
    })
  }

  searchWording(): string {
    // Exemples: 
    // Résultat de recherche pour le 21/08/2020
    // Résultat de recherche pour le numéro de commande FE_00128
    // Aucune commande trouvée pour le 21/08/2020
    // Aucune commande trouvée pour le numéro de commande FE_00128
    return(
      (this.orders.length ? 'Résultat de recherche' : 'Aucune commande trouvée') +
      ' pour le ' +
      '<strong>' +
      (this.search.key === 'id' ?
      `numéro de commande ${this.search.value}` : 
      `${moment(this.search.value).format('DD/MM/YYYY')}`) +
      '</strong>'
    )
  }
}

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl {
  itemsPerPageLabel = 'Stavki po stranici';

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 od ' + length;
    }
    length = Math.max(length, 0); 
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return 'Page ' + (startIndex + 1) + ' / ' + length;
  };

}
