<span class="title">Etes-vous sûr de vouloir annuler ?</span>
<sidebar-line  [wide]="false" [order]="data | async"></sidebar-line>
<span class="label_refus">Motif d'annulation</span>
<mat-form-field class="gestion__input">
    <mat-select 
        id="motif-select" 
        (selectionChange)="changeMotifSelect($event.value)"
        >
        <mat-option *ngFor="let opt of listMotif" [value]="opt">{{opt}}</mat-option>
    </mat-select>
</mat-form-field>
<button class="confirm" (click)="confirmCancel()" [disabled]="disabledButton">Confirmer l'annulation</button>
<button mat-button class="cancel" (click)="closeModal()">Retour</button>    
