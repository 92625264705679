<div class="ticket-modale--item">
    <section class="text">
        <p style="padding-top: 1rem">
            Renseignez le nouveau numéro SIREN
        </p>
        <div class="row">
            <div class="column small-12 medium-12">
              <div class="gestion__block">
                <div class="gestion__input-box">
                  <label for="stripe-tax_id">SIREN</label>
                    <mat-form-field class="gestion__input">
                      <input 
                        matInput
                        id="stripe-tax_id" 
                        type="text"
                        [formControl]="tax_id"
                      >
                    </mat-form-field>
                </div>
              </div>
            </div>
        </div>
    </section>
    <div class="button_container">
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="closeModal()" [disabled]="!formGroup.valid">  
            Valider
        </button> 
    </div>  
</div>