import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Commande } from 'src/app/models/commande';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'multiboutique-waiting-modal',
  templateUrl: './multiboutique-waiting-modal.component.html',
  styles: []
})
export class MultiboutiqueWaitingModalComponent implements OnInit {
  subscription: Subscription;
  checked = false;

  constructor(
    public router: Router,
    public store: Store,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MultiboutiqueWaitingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Observable<Commande>
    ) { }

  ngOnInit() {
    
  }

  closeModal() {
    this.dialogRef.close();
  }
}
