<div class="row">
  <div class="column small-12 medium-6">
    <div class="gestion__block">
      <div class="gestion__block--title">
        <span>Photo de la boutique</span>
        <button mat-button color="primary" type="button">
          <label for="uploadImage">
            <ng-container *ngIf="(image$ | async)">Modifier</ng-container>
            <ng-container *ngIf="!(image$ | async)">Ajouter</ng-container>
          </label>
        </button>
      </div>
      <input 
        hidden
        id="uploadImage" 
        type="file" 
        (change)="fileChangeEvent($event)" 
        class="uploadImageInput" 
        placeholder="Yes"
      >
      <div class="imageError" *ngIf="imageError">{{imageError}}</div>
      <img [src]="image$ | async" class="shop__photo-boutique">
    </div>
  </div>
  <div class="column small-12 medium-6">
    <div class="gestion__block">
      <div class="gestion__block--title">
        <span>Adresse e-mail</span>
        <button mat-button color="primary" type="button" 
          (click)="submitEmail()"
          *ngIf="email.valid && email.value !== (email$ | async)">
          Valider
        </button>
      </div>
      <form [formGroup]="emailForm">
        <mat-form-field class="gestion__input">
          <input matInput [formControl]="email">
          <mat-error *ngIf="email.invalid">{{getErrorMessageMail(email)}}</mat-error>
        </mat-form-field>
      </form>
    </div>
    <div class="gestion__block">
      <div class="gestion__block--title">
        <span>Description de la boutique</span>
        <button mat-button color="primary" type="button" 
          (click)="submitDescription()"
          *ngIf="description.valid && description.value !== (description$ | async)">
          Valider
        </button>
      </div>
      <form [formGroup]="descriptionForm">
        <mat-form-field class="gestion__input">
          <textarea matInput [formControl]="description"></textarea>
          <mat-error *ngIf="description.invalid">{{getErrorMessageDescription(description)}}</mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>
