import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'src/app/utils/message/message.service';
import { emailValidator } from 'src/app/utils/validator/validators.service';
import { ShopService } from 'src/app/utils/shop/shop.service';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectShopVisualUrl, selectShopEmail, selectShopDescription } from 'src/app/reducers/shop/shop.selector';

@Component({
  selector: 'app-boutique',
  templateUrl: './boutique.component.html',
  styles: [
  ]
})
export class BoutiqueComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = []

  emailForm : FormGroup 
  email = new FormControl('', [
    Validators.required,
    emailValidator()
  ]);
  email$ = this.store.select(selectShopEmail)

  descriptionForm : FormGroup 
  description = new FormControl('', [
    Validators.required
  ]);
  description$ = this.store.select(selectShopDescription)

  image$ : Observable<string> = this.store.select(selectShopVisualUrl)
  imageError: string = null

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private shopService: ShopService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: this.email
    })
    this.descriptionForm = this.formBuilder.group({
      description: this.description
    })

    this.subscriptions = [
      this.email$.subscribe(email => this.email.setValue(email)),
      this.description$.subscribe(desc => this.description.setValue(desc)),
    ]
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  getErrorMessageDescription(control: FormControl): string {
    return this.messageService.getErrorMessage(control, {
      required: () => `Veuillez ajouter une description`,
    });
  }

  getErrorMessageMail(control: FormControl): string {
    return this.messageService.getErrorMessage(control);
  }

  submitEmail(): void {
    if(this.email.valid){
      this.shopService.setEmail(this.email.value)
    }
  }
  submitDescription(): void {
    if(this.description.valid){
      this.shopService.setDescription(this.description.value)
    }
  }

  /**
   * Upload en base64
   * @param fileInput 
   */
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError = 'Taille maximum autorisée ' + max_size / 1000 + 'Mb';
        return false;
      }
      if (!allowed_types.includes(fileInput.target.files[0].type)) {
        this.imageError = 'Extensions autorisées : ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > max_height && img_width > max_width) {
            return false;
          } else {
            const imgBase64Path = e.target.result;
            // On met à jour le back
            this.shopService.setImage(imgBase64Path)
            // this.image = imgBase64Path;
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);

    }
  }
}
