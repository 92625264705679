import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { CatalogProductService } from 'src/app/utils/catalog/catalog-product.service';
import { MessageService } from 'src/app/utils/message/message.service';
import * as moment from 'moment';
import { CatalogDataConfig } from 'src/app/models/catalog';

type Tab = "config" | "price" | "attr" | "market";

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styles: [
  ]
})
export class CatalogEditComponent implements OnInit {
  currentTab: Tab = "config";
  loader: boolean = true;
  subscription: Subscription;
  product$: Observable<any>;
  dataConfig;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private catalogProductService: CatalogProductService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit(){ 
    // Récupère dans le store la commande en fonction de l'id passé en param
    this.product$ = this.route.paramMap.pipe()
    
    this.subscription = this.product$.subscribe(  
      product => {
        this.catalogProductService.getEditProductDetails(product.params.id).pipe(first()).subscribe(
          data => {
            this.loader = false;
            this.dataConfig = data;
            //Fix pour les attributs de type boolean => select single
            this.dataConfig.dataForm.attributes.forEach(elem => {
              if(elem.type == 'boolean'){
                elem.values.forEach(value => {
                  value.value = value.value.toString()
                })
              }
            })
          },
          error => {
            this.loader = false;
          }
        );
      }
    )
  }

  ngOnDestroy(){
    this.subscription.unsubscribe() 
  }

  onChangeSetAttribute(attribute_set_id: string): void {
    this.loader = true;
    this.catalogProductService.getAddProductEditAttributeSet(attribute_set_id).pipe(first()).subscribe(
      data => {
        this.dataConfig.dataForm.attributes = data.dataForm.attributes
        this.loader = false;  
      },
      error => {
        this.loader = false;
      }
    )
  }

  editProduct(id: number) {
    let copyDataConfig: CatalogDataConfig;
    copyDataConfig = {
      ...this.dataConfig.dataConfig,
      special_from_date: (this.dataConfig.dataConfig.special_from_date) ? moment(this.dataConfig.dataConfig.special_from_date).format('YYYY-MM-DD') : null,
      special_to_date: (this.dataConfig.dataConfig.special_to_date) ? moment(this.dataConfig.dataConfig.special_to_date).format('YYYY-MM-DD') : null,
      special_shipping_price_from: (this.dataConfig.dataConfig.special_shipping_price_from) ?  moment(this.dataConfig.dataConfig.special_shipping_price_from).format('YYYY-MM-DD') : null,
      special_shipping_price_to: (this.dataConfig.dataConfig.special_shipping_price_to) ?  moment(this.dataConfig.dataConfig.special_shipping_price_to).format('YYYY-MM-DD') : null,
    }

    //On vérifie avant que certains champs sont remplis
    if(copyDataConfig.name.length === 0){
      this.messageService.openSnackBar('Veuillez renseigner le nom du produit', 'error')
      return false;
    }
    if(copyDataConfig.ref.length === 0){
      this.messageService.openSnackBar('Veuillez renseigner la référence du produit', 'error')
      return false;
    }
    if(copyDataConfig.categories.length === 0){
      this.messageService.openSnackBar('Veuillez renseigner la catégorie du produit', 'error')
      return false;
    }
    if(!copyDataConfig.categoryMerchant){
      this.messageService.openSnackBar('Veuillez renseigner la catégorie marchande du produit', 'error')
      return false;
    }
    if(copyDataConfig?.weight?.length === 0){
      this.messageService.openSnackBar('Veuillez renseigner le poids du produit', 'error')
      return false;
    }
    if(copyDataConfig.price.length === 0 && copyDataConfig.type != "configurable"){
      this.messageService.openSnackBar('Veuillez renseigner le prix du produit', 'error')
      return false;
    }
    
    this.loader = true;
    this.catalogProductService.postEditProductDetails(copyDataConfig).pipe(first()).subscribe(
      data => {
        this.loader = false;
        this.dataConfig = data;
        this.messageService.openSnackBar('Modifications sauvegardées', 'success')
        this.router.navigate(['/catalog']);
      },
      error => {
        this.loader = false;
        this.messageService.openSnackBar('Une erreur est survenue', 'error')
        this.router.navigate(['/catalog']);
      }
  )}

  changeFiltre(ev: Event, tab: Tab):void {
    ev.preventDefault();
    this.currentTab = tab
  }

  

  onClickPrev(): void {
    switch (this.currentTab) {
      case 'price':
        this.currentTab = 'config'
        break;
      case 'attr':
        this.currentTab = 'price'
        break;
      case 'market':
        this.currentTab = 'attr'
        break;
    }
  }

  onClickNext(): void {
    switch (this.currentTab) {
      case 'config':
        this.currentTab = 'price'
        break;
      case 'price':
        this.currentTab = 'attr'
        break;
      case 'attr':
        this.currentTab = 'market'
        break;
    }
  }

}
