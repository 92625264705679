<div class="login-container">
  <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
    <img class="logo" src='/assets/images/logo-lagalerie.png' />
    <div class="form_container">
      <mat-form-field>
        <mat-label>Nom d'utilisateur</mat-label>
        <input matInput type="text" formControlName="login">
        <mat-error *ngIf="login.invalid">{{getErrorMessage(login)}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Mot de passe</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
        <button mat-icon-button matSuffix (click)="hide = !hide" type="button">
          <mat-icon matSuffix fontSet="fontello" [fontIcon]="hide ? 'eye-off' : 'eye'"></mat-icon>
        </button>
        <mat-error *ngIf="password.invalid">{{getErrorMessage(password)}}</mat-error>
      </mat-form-field>
      <a class="forgot__password" routerLink="/auth/forgot-password">Mot de passe oublié ?</a>
    </div>
    <button mat-flat-button color="primary" type="submit" [disabled]="loginForm.invalid" class="button__connect">
      Se connecter
    </button>
  </form>
</div>