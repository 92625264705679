import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StripeAccount, StripeUpdatePerson, StripeUpdateAccount, StripeUpdateBusiness, StripeUpdateExtern, StripeUpdateFile } from 'src/app/models/stripe';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class StripeService {  
  constructor(
    private httpService: HttpService,
  ) {}

  fetchStripeAccountId(): Observable<StripeAccount> {
    return this.httpService.apiRequest('get', 'stripe/account')
  }

  fetchStripeAccount(idAccount: string): Observable<any> {
    return this.httpService.apiRequest('get', `stripe/accounts/${idAccount}`)
  }

  fetchStripeGetAllPersons(idAccount: string): Observable<any> {
    return this.httpService.apiRequest('get', `stripe/accounts/${idAccount}/persons`)
  }

  fetchStripeGetExtern(idAccount: string): Observable<any> {
    return this.httpService.apiRequest('get', `stripe/accounts/${idAccount}/external_accounts`)
  }

  fetchStripeGetFiles(idAccount: string): Observable<any> {
    return this.httpService.apiRequest('get', `stripe/accounts/${idAccount}/files`)
  }

  createStripePerson(idAccount: string, payload: StripeUpdatePerson): Observable<any> {
    const {first_name, last_name, email, phone, indicatif, day, month, year, postal_code, city, country, line1, title} = payload
    let obj = {
        data: {
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: indicatif+phone,
            dob: {
                day: day, 
                month: month, 
                year: year
            },
            address: {
                postal_code: postal_code,
                city: city,
                country: country,
                line1: line1,
            },
            relationship: {
              director: "true",
              owner: "true",
              executive: "true",
              representative: "true",
              title: title
            }
        }
    }
    
    return this.httpService.apiRequest('post', `stripe/accounts/${idAccount}/persons`, obj)
  }

  updateStripePerson(idAccount: string, idPerson: string, payload: StripeUpdatePerson): Observable<any> {
    const {first_name, last_name, email, phone, day, month, year, postal_code, city, country, line1, title} = payload
    let obj = {
        data: {
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone,
            dob: {
                day: day, 
                month: month, 
                year: year
            },
            address: {
                postal_code: postal_code,
                city: city,
                country: country,
                line1: line1,
            },
            relationship: {
              director: "true",
              owner: "true",
              executive: "true",
              representative: "true",
              title: title
            }
        }
    }
    
    return this.httpService.apiRequest('post', `stripe/accounts/${idAccount}/persons/${idPerson}`, obj)
  }

  updateStripeBusiness(idAccount: string, payload: StripeUpdateBusiness): Observable<any> {
    const {name, mcc, url} = payload
    let obj = {
        data: {
            business_profile: {
                name: name,
                mcc: mcc,
                url: url
            }
        }
    }
    
    return this.httpService.apiRequest('post', `stripe/accounts/${idAccount}/business_profile`, obj)
  }

  updateStripeAccount(idAccount: string, payload: StripeUpdateAccount): Observable<any> {
    const {tax_id} = payload
    let obj = {
        data: {
            account: {
                company: {
                    tax_id,
                    directors_provided: "true",
                    owners_provided: "true",
                    executives_provided: "true"
                }
            }
        }
    }
    
    return this.httpService.apiRequest('post', `stripe/accounts/${idAccount}`, obj)
  }

  updateStripeAccountTos(idAccount: string, ip): Observable<any> {
    let obj = {
      data: {
        tos_acceptance: {
          ip: ip,
          date: Math.floor(Date.now() / 1000)
        }
      }
    }
    
    return this.httpService.apiRequest('post', `stripe/accounts/${idAccount}/business_profile`, obj)
  }

  createStripeExtern(idAccount: string, payload: StripeUpdateExtern): Observable<any> {
    const {account_holder_name, account_holder_type, country, account_number} = payload
    let obj = {
        data: {
          external_account: {
            object: "bank_account", 
            account_holder_name, 
            account_holder_type, 
            country, 
            currency: "EUR", 
            account_number
          }
        }
    }
    return this.httpService.apiRequest('post', `stripe/accounts/${idAccount}/external_accounts`, obj)
  }

  updateStripeExtern(idAccount: string, payload: StripeUpdateExtern): Observable<any> {
    const {idExternalAccount, account_holder_name, account_holder_type, country, account_number} = payload
    let obj = {
        data: {
          external_account: {
            object: "bank_account", 
            account_holder_name, 
            account_holder_type, 
            country, 
            currency: "EUR", 
            account_number
          }
        }
    }
    
    return this.httpService.apiRequest('post', `stripe/accounts/${idAccount}/external_accounts/${idExternalAccount}`, obj)
  }

  createStripeFile(idAccount: string, file: StripeUpdateFile): Observable<any> {
    let obj = {
      file
    }
    return this.httpService.apiRequest('post', `stripe/accounts/${idAccount}/files`, obj)
  }

  updateStripeFile(idAccount: string, idPerson: string, identityFront: any, identityBack: any): Observable<any> {
    let obj = {
      identityFront,
      identityBack
    }
    return this.httpService.apiRequest('post', `stripe/accounts/${idAccount}/persons/${idPerson}/documents`, obj)
  }

  getIp(): Observable<any> {
    return this.httpService.apiRequest('get', `stripe/accounts/ip`)
  }
}