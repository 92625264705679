
<div class="loader_container" *ngIf="isLoading">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>
<div class="colissimo-modale--item" *ngIf="!isLoading && !isConfirmation">
    <section class="item--head">
        <span class="title">Formulaire de demande de remboursement des frais de commissions</span>
        <button mat-icon-button class="close__modale" (click)="closeModal()"></button>
    </section>
    <form [formGroup]="formGroup" (ngSubmit)="submit()" class="item--list">
        <div *ngIf="formFraisCommission?.length > 0" formArrayName="formFraisCommission">
            <div [formGroupName]="i" class="frais_commission_line" *ngFor="let item of formGroup.controls?.formFraisCommission?.controls; let last = last; let i = index">
                <mat-checkbox formControlName="checked">{{item.value.name}}</mat-checkbox>
                <mat-form-field class="gestion__input selectQty">
                    <mat-select formControlName="qty">
                        <mat-option *ngFor="let opt of formFraisCommission[i].qty" [value]="opt">{{opt}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="gestion__input textarea">
                    <textarea matInput formControlName="comment" placeholder="Votre commentaire"></textarea>    
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="button_container">
        <button mat-flat-button [disabled]="disableButton()" color="primary" class="commande__ready accept" (click)="checkConfirmation()">  
            <mat-icon aria-hidden="true" class="ok">done</mat-icon>
            Envoyer
        </button> 
    </div>  
</div>
<!-- Confirmation envoi -->
<div class="confirmation_container" *ngIf="isConfirmation">
    <p>Confirmez-vous la demande de remboursement des frais de commissions ?</p>
    <div class="button_container">
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="submit()">  
            Confirmer
        </button> 
        <button mat-flat-button color="warn" class="commande__ready delete" (click)="cancelConfirmation()">  
            Annuler
        </button> 
    </div>  
</div>
