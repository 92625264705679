import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { AppSidebarModule } from 'src/app/_layout/app-sidebar/app-sidebar.module';
import { MaterialModule } from 'src/app/material.module';
import { MultiboutiqueListModalComponent } from './multiboutique-list-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CustomPipeModule,
    AppSidebarModule,
    MaterialModule
  ],
  declarations: [
    MultiboutiqueListModalComponent
  ],
  exports: [
    MultiboutiqueListModalComponent
  ]
})
export class MultiboutiqueListModalModule { }
