<div class="gestion__container">
  <div class="gestion__header">
    <div class="gestion__row">
      <a mat-icon-button class="back_button" routerLink="/catalog/add"></a>
      <div class="titles">
        <h2>Ajouter un produit</h2>
        <span>individuel</span>
      </div>
      <a mat-flat-button color="primary" class="commande__ready catalog-create-product" (click)="createProduct()">
        <mat-icon aria-hidden="true" class="ok">done</mat-icon>Valider le produit
      </a>
    </div>
    <div class="gestion__row filters">
      <div class="sort">
        <a href="#!" [ngClass]="{'active': currentTab === 'config'}" (click)="changeFiltre($event, 'config')">
          Configuration
        </a>
        <a href="#!" [ngClass]="{'active': currentTab === 'price'}" (click)="changeFiltre($event, 'price')">
          Prix
        </a>
        <!-- <a href="#!" [ngClass]="{'active': currentTab === 'attr'}" (click)="changeFiltre($event, 'attr')">
          Attributs
        </a> -->
        <a href="#!" [ngClass]="{'active': currentTab === 'market'}" (click)="changeFiltre($event, 'market')">
          Mise en avant marketing
        </a>
      </div>
    </div>
  </div>
  <div class="gestion__body">
    <div class="gestion__row">
      <div class="shop__container add_product_form">
        <ng-container *ngIf="loader">
          <div class="loader_container">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!loader">
          <div [hidden]="currentTab !== 'config'">
            <app-catalog-config
              [(data)]="dataConfig"
            ></app-catalog-config>
          </div>
          <div [hidden]="currentTab !== 'price'">
            <app-catalog-prices
              [(data)]="dataConfig"
            ></app-catalog-prices>
          </div>
          <div [hidden]="currentTab !== 'attr'">
            <app-catalog-attributes
              [(data)]="dataConfig"
              (onChangeSetAttribute)="onChangeSetAttribute($event)"
            ></app-catalog-attributes>
          </div>
          <div [hidden]="currentTab !== 'market'">
            <app-catalog-market
            [(data)]="dataConfig"
            ></app-catalog-market>
          </div>
        </ng-container>
        <div class="create_product_buttons" [ngClass]="{'oneButton': currentTab === 'config'}">
          <button mat-flat-button
            class="create_product_prev"
            *ngIf="currentTab !== 'config'"
            (click)="onClickPrev()"
          >Précédent</button>
          <button mat-flat-button
            class="create_product_next"
            *ngIf="currentTab !== 'market'"
            (click)="onClickNext()"
          >Suivant</button>
          <button mat-flat-button
            class="create_product_next"
            *ngIf="currentTab == 'market'"
            (click)="createProduct()"
          >Terminé</button>
        </div>
      </div>
    </div>
  </div>
</div>