import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { StripeModalComponent } from './stripe-modal.component';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    CustomPipeModule,
    RouterModule,
    MaterialModule
  ],
  declarations: [
    StripeModalComponent
  ],
  exports: [
    StripeModalComponent
  ]
})
export class StripeModalModule { }
