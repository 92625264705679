<div class="commande-modale" *ngIf="enabled$ | async">
    <div class="commande-modale--backdrop" (click)="close()"></div>
    <div class="commande-modale--wrapper">
        <owl-carousel-o [options]="customOptions" #owlCar (changed)="getData($event)">
            <ng-container *ngFor="let slide of editableOrdersForQuantities; let i = index">
                <ng-template carouselSlide [id]="'slide_'+slide.sub_order_id">
                    <div class="commande-modale--item">
                        <section class="item--head">
                            <button mat-icon-button class="close__modale"
                                (click)="closeModale(slide)"></button>
                            <div class="item--id_container">
                                <span class="item--id"><strong>{{slide.reference}}</strong></span>
                                <span class="item--id--label">N° de commande</span>
                                <span class="commande__details--idsuborder">{{ slide.order_increment_id }}</span> 
                            </div>
                            <span><span class="multiboutique" *ngIf="slide.sub_orders_related.length > 0"><span>M</span></span></span>
                            <div class="item__time__container" *ngIf="slide.delivery_type != 'colissimo'">
                                <span class="item--time--left">Pour le {{ moment(slide.delivery_date).format("DD/MM/YY")}} à {{ slide | preparationHour | async | hour }}</span>
                            </div>
                            <category-price [price]="slide.total" [type]="slide.delivery_type"></category-price>    
                        </section>
                        <div class="item__list">
                            <section class="item--list" [id]="'scrollable_content_'+slide.sub_order_id">
                                <div class="historique__details__list">
                                    <div class="historique__details--menus"
                                        *ngIf="slide?.items?.menus.length > 0">
                                        <span class="category">Menu</span>
                                        <mat-list-item
                                            *ngFor="let menu of slide.items.menus; let last = last">
                                            <div class="line" [ngClass]="{ last: last }">
                                                <button type="button" (click)="changeQtyMenu(slide, menu, i)" *ngIf="editModeIds.includes(slide.sub_order_id)" class="line_less_button">-</button>
                                                <div class="line--qty menu--text">x{{menu.qty}}</div>
                                                <div class="line--name menu--text">{{menu.name}}</div>
                                                <div class="line--total menu--text">{{menu.total}} €</div>
                                            </div>
                                            <div class="menu__details">
                                                <ng-container *ngFor="let info of menu.bundle_info">
                                                    <div class="menu__details--type">{{info.type}}</div>
                                                    <ng-container *ngFor="let choice of info.choices">
                                                        <div class="menu__details--name">{{choice.name}}</div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </mat-list-item>
                                    </div>
                                    <ng-container *ngIf="slide?.items?.categories.length > 0">
                                        <div class="historique__details--cat"
                                            *ngFor="let cat of slide.items.categories">
                                            <span class="category">{{cat.type}}</span>
                                            <mat-list-item *ngFor="let choice of cat.choices; let last = last">
                                                <div class="line" [ngClass]="{ last: last }">
                                                    <button type="button" (click)="changeQtyProduct(slide, cat, choice, i)" *ngIf="editModeIds.includes(slide.sub_order_id) && choice.qty > 0" class="line_less_button">-</button>
                                                    <div class="line--qty">x{{choice.qty}}</div>
                                                    <div class="line--name">
                                                        {{choice.name}}
                                                        <span *ngIf="choice.config.length > 0">
                                                          (
                                                            <span *ngFor="let conf of choice.config; let lastConfig = last">
                                                              {{conf}} <span *ngIf="!lastConfig">/</span> 
                                                            </span>
                                                          )
                                                        </span>
                                                      </div>
                                                    <div class="line--total">{{choice.total}} €</div>
                                                </div>
                                            </mat-list-item>
                                        </div>
                                    </ng-container>
                                </div>
                            </section>
                        </div>
                        <section class="item--foot">
                            <button mat-flat-button class="close" (click)="refuse(slide)">
                                <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="close"></mat-icon>
                            </button>
                            <ng-container *ngIf="!editModeIds.includes(slide.sub_order_id)">
                                <loader></loader>
                            </ng-container>
                            <button *ngIf="!editModeIds.includes(slide.sub_order_id)" mat-flat-button color="secondary" class="edit_button" (click)="edit(slide)">
                                <mat-icon aria-hidden="true" class="ok">edit</mat-icon><span class="edit_button_label"> Modifier</span> 
                            </button>
                            <button *ngIf="!editModeIds.includes(slide.sub_order_id)" mat-flat-button color="primary" class="commande__ready accept" (click)="accept(slide)" [disabled]="isRequesting">
                                <mat-icon aria-hidden="true" class="ok">done</mat-icon> Accepter
                            </button>
                            <button *ngIf="editModeIds.includes(slide.sub_order_id)" mat-flat-button color="primary" class="commande__ready accept" (click)="validate(slide)" [disabled]="isRequesting">
                                <mat-icon aria-hidden="true" class="ok">done</mat-icon> Valider
                            </button>
                        </section>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>