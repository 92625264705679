import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InAppGuard } from './in-app.guard';
import { SelectProfilComponent } from './select-profil/select-profil.component';
import { AppLayoutComponent } from 'src/app/_layout/app-layout/app-layout.component';
import { HistoriqueComponent } from './historique/historique.component';
import { HistoriqueDetailsComponent } from './historique/historique-details/historique-details.component';
import { HistoriqueBlankComponent } from './historique/historique-blank/historique-blank.component';
import { CommandeComponent } from './commande/commande.component';
import { CommandeDetailsComponent } from './commande/commande-details/commande-details.component';
import { CommandeBlankComponent } from './commande/commande-blank/commande-blank.component';
import { ReportingComponent } from './reporting/reporting.component';
import { CollaborateurComponent } from './collaborateur/collaborateur.component';
import { CollaborateurListComponent } from './collaborateur/collaborateur-list/collaborateur-list.component';
import { CommandeTestComponent } from './commande/commande-test/commande-test.component';
import { SettingsComponent } from './settings/settings.component';
import { HelpPrintComponent } from './settings/help-print/help-print.component';
import { CatalogComponent } from './catalog/catalog.component';
import { GestionComponent } from './gestion/gestion.component';
import { ShopComponent } from './shop/shop.component';
import { CatalogAddComponent } from './catalog/add/add.component';
import { CatalogAddSimpleComponent } from './catalog/add/simple/simple.component';
import { CatalogAddConfigurableComponent } from './catalog/add/configurable/configurable.component';
import { CatalogAddPackComponent } from './catalog/add/pack/pack.component';
import { CatalogEditComponent } from './catalog/edit/edit.component';
import { RefundComponent } from './refund/refund.component';
import { VirementComponent } from './virement/virement.component';
import { StripeComponent } from './stripe/stripe.component';


const appRoutes: Routes = [
  {
    path: 'profiles',
    component: SelectProfilComponent,
    canActivate: [InAppGuard],
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivateChild: [InAppGuard],
    children: [
      {
        path: 'historique',
        component: HistoriqueComponent,
        children: [
          {
            'path': ':id',
            component: HistoriqueDetailsComponent
          },
          {
            'path': '',
            pathMatch: 'full',
            component: HistoriqueBlankComponent
          }
        ]
      },
      {
        path: 'commande',
        component: CommandeComponent,
        children: [
          {
            path: 'test-status-commande',
            component: CommandeTestComponent
          },
          {
            path: ':id',
            component: CommandeDetailsComponent
          },
          {
            path: '',
            pathMatch: 'full',
            component: CommandeBlankComponent
          }
        ]
      },
      {
        path: 'catalog',
        children: [
          {
            path: 'add',
            children: [
              {
                path: 'simple',
                component: CatalogAddSimpleComponent
              },{
                path: 'configurable',
                component: CatalogAddConfigurableComponent
              },{
                path: 'pack',
                component: CatalogAddPackComponent
              },{
                path: '',
                pathMatch: 'full',
                component: CatalogAddComponent
              }
            ]
          },
          {
            path: 'edit',
            children: [
              {
                path: ':id',
                component: CatalogEditComponent
              }
            ]
          },
          {
            path: '',
            pathMatch: 'full',
            component: CatalogComponent,
          }
        ]
      },
      {
        path: 'reporting',
        component: ReportingComponent,
      },
      {
        path: 'settings',
        children: [
          {
            path: 'help-print',
            component: HelpPrintComponent
          },{
            path: '',
            pathMatch: 'full',
            component: SettingsComponent,
          }
        ]
      },
      {
        path: 'gestion',
        component: GestionComponent,
      },
      {
        path: 'stripe',
        component: StripeComponent,
      },
      {
        path: 'shop',
        component: ShopComponent,
      },
      {
        path: 'refund',
        component: RefundComponent,
      },
      {
        path: 'virements',
        component: VirementComponent,
      },
      {
        path: 'admin',
        component: CollaborateurComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CollaborateurListComponent,
          }
        ]
      },
      {
        path: '',
        redirectTo: '/commande',
        pathMatch: 'full'
      },
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(
      appRoutes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class InAppRoutingModule { }