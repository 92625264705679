<div class="gestion__container">
  <div class="gestion__header">
    <div class="gestion__row">
      <a mat-icon-button class="back_button" routerLink="/" routerLinkActive="active"></a>
      <div class="titles">
        <h2>Configuration stripe</h2>
      </div>
      <div *ngIf="stripe_status_loader" class="stripe_status_loader">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
      <div class="stripe_status" [ngClass]="{'limited': !stripeIsValid}" *ngIf="!stripe_status_loader">
        <span class="stripe_status_bulle"></span>
        {{stripeIsValid ? 'Activé' : 'Limité'}}
      </div>
    </div> 
    <div class="gestion__row filters">
      <div class="sort">
        <a href="#!" [ngClass]="{'active': currentTab === 'identite'}" (click)="changeTab($event, 'identite')">
          Identité              
        </a>
        <a href="#!" [ngClass]="{'active': currentTab === 'entreprise'}" (click)="changeTab($event, 'entreprise')">
          Entreprise
        </a>
        <a href="#!" [ngClass]="{'active': currentTab === 'externe'}" (click)="changeTab($event, 'externe')">
          Compte Externe
        </a>
        <a href="#!" [ngClass]="{'active': currentTab === 'attachments'}" (click)="changeTab($event, 'attachments')">
          Pièces jointes
        </a>
      </div>
    </div>
  </div>  
  <ng-container *ngIf="loader">
    <div class="loader_container">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="!loader">
    <div class="gestion__body">
        <div class="gestion__row">
          <div class="shop__container">
            <div [hidden]="currentTab !== 'identite'">
              <stripe-identite [(data)]="dataIdentity"></stripe-identite>
            </div>
            <div [hidden]="currentTab !== 'entreprise'">
              <stripe-entreprise [(data)]="dataEntreprise"></stripe-entreprise>
            </div>
            <div [hidden]="currentTab !== 'externe'">
              <stripe-externe [(data)]="dataExtern"></stripe-externe>
            </div>
            <div [hidden]="currentTab !== 'attachments'">
              <stripe-attachments [(data)]="dataFiles"></stripe-attachments>
            </div>
          </div>
        </div>
    </div>
  </ng-container>
</div>