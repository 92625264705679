export const MCC = [
  {id: "0742", value: "ACTIVITES VETERINAIRES"},  
  {id: "0763", value: "ELEVAGE DE BOVINS"},
  {id: "0763", value: "PECHE"},
  {id: "0763", value: "ELEVAGE D'AUTRES ANIMAUX"},
  {id: "0763", value: "CULTURE DE LEGUMES ET MARAICHAGE"},
  {id: "0763", value: "CHASSE"},
  {id: "0763", value: "ELEVAGE D'OVINS, CAPRINS ET EQUIDES"},
  {id: "0763", value: "SYLVICULTURE"},
  {id: "0763", value: "CULTURE ET ELEVAGE ASSOCIE"},
  {id: "0763", value: "CULTURE FRUITIERE"},
  {id: "0763", value: "SERVICES AUX CULTURES PRODUCTIVES"},
  {id: "0763", value: "ELEVAGE DE PORCINS"},
  {id: "0763", value: "EXPLOITATION FORESTIERE"},
  {id: "0763", value: "ELEVAGE DE VOLAILLES"},
  {id: "0763", value: "VITICULTURE"},
  {id: "0763", value: "CULTURE DE CEREALES ET CULTURES INDUSTRIELLES"},
  {id: "0763", value: "SERVICES FORESTIERS"},
  {id: "0763", value: "COMMERCE DE GROS DE VOLAILLES ET GIBIERS"},
  {id: "0763", value: "COMMERCE DE GROS DE PRODUITS A BASE DE VIANDE"},
  {id: "0763", value: "COMMERCE DE GROS DE VIANDES DE BOUCHERIE"},
  {id: "0763", value: "COMMERCE DE GROS DE FRUITS ET LEGUMES"},
  {id: "0763", value: "COMMERCE DE GROS DE CEREALES ET ALIMENTS POUR LE B"},
  {id: "0763", value: "INTERMEDIAIRES DU COMMERCE EN MATIERES PREMIERES A"},
  {id: "0763", value: "PISCICULTURE, AQUACULTURE"},
  {id: "0763", value: "COMMERCE DE DETAIL DE POISSONS, CRUSTACES ET MOLLU"},
  {id: "0763", value: "COMMERCE DE DETAIL DE VIANDES ET PRODUITS A BASE D"},
  {id: "0763", value: "COMMERCES DE GROS ALIMENTAIRES SPECIALISES DIVERS"},
  {id: "0763", value: "COMMERCE DE GROS DE POISSONS, CRUSTACES ET COQUILL"},
  {id: "0763", value: "COMMERCE DE GROS DE CAFE, THE, CACAO, EPICES"},
  {id: "0763", value: "FABRICATION DE MARGARINE"},
  {id: "0763", value: "FABRICATION D'HUILES ET GRAISSES RAFFINEES"},
  {id: "0763", value: "FABRICATION D'HUILES ET GRAISSES BRUTES"},
  {id: "0763", value: "TRANSFORMATION ET CONSERVATION DE FRUITS"},
  {id: "0763", value: "TRANSFORMATION ET CONSERVATION DE LEGUMES"},
  {id: "0763", value: "PREPARATION DE JUS DE FRUITS ET LEGUMES"},
  {id: "0763", value: "TRANSFORMATION ET CONSERVATION DE POMMES DE TERRE"},
  {id: "0763", value: "INDUSTRIE DU POISSON"},
  {id: "0763", value: "CHARCUTERIE ARTISANALE"},
  {id: "0763", value: "PREPARATION INDUSTRIELLE DE PRODUITS A BASE DE VIA"},
  {id: "0763", value: "PRODUCTION DE VIANDES DE VOLAILLES"},
  {id: "0763", value: "PRODUCTION DE VIANDES DE BOUCHERIE"},
  {id: "0763", value: "PRODUCTION DE SEL"},
  {id: "0763", value: "PRODUCTION DE BOISSONS RAFRAICHISSANTES"},
  {id: "0763", value: "INDUSTRIE DES EAUX DE TABLE"},
  {id: "0763", value: "MALTERIE"},
  {id: "0763", value: "BRASSERIE"},
  {id: "0763", value: "PRODUCTION D'AUTRES BOISSONS FERMENTEES"},
  {id: "0763", value: "CIDRERIE"},
  {id: "0763", value: "VINIFICATION"},
  {id: "0763", value: "CHAMPAGNISATION"},
  {id: "0763", value: "PRODUCTION D'ALCOOL ETHYLIQUE DE FERMENTATION"},
  {id: "0763", value: "FABRICATION DE SPIRITUEUX"},
  {id: "0763", value: "PRODUCTION D'EAUX DE VIE NATURELLES"},
  {id: "0763", value: "INDUSTRIES ALIMENTAIRES NCA"},
  {id: "0763", value: "FABRICATION D'ALIMENTS ADAPTES A L'ENFANT ET DIETE"},
  {id: "0763", value: "FABRICATION DE CONDIMENTS ET ASSAISONNEMENTS"},
  {id: "0763", value: "TRANSFORMATION DU THE ET DU CAFE"},
  {id: "0763", value: "FABRICATION DE PATES ALIMENTAIRES"},
  {id: "0763", value: "FABRICATION D'ALIMENTS POUR ANIMAUX DE COMPAGNIE"},
  {id: "0763", value: "FABRICATION D'ALIMENTS POUR ANIMAUX DE FERME"},
  {id: "0780", value: "REALISATIONS ET ENTRETIEN DE PLANTATIONS ORNEMENTA"},
  {id: "1520", value: "AGENCEMENT DE LIEUX DE VENTE"},
  {id: "1520", value: "CONSTRUCTION DE BATIMENTS DIVERS"},
  {id: "1520", value: "CONSTRUCTION DE MAISONS INDIVIDUELLES"},
  {id: "1520", value: "TERRASSEMENTS DIVERS, DEMOLITION"},
  {id: "1711", value: "INSTALLATION D'EQUIPEMENTS THERMIQUES ET DE CLIMAT"},
  {id: "1711", value: "INSTALLATION D'EAU ET DE GAZ"},
  {id: "1711", value: "TRAVAUX D'ISOLATION"},
  {id: "1711", value: "CHAUDRONNERIE-TUYAUTERIE"},
  {id: "1711", value: "FAB DE RADIATEURS ET DE CHAUDIERES POUR LE CHAUFFA"},
  {id: "1711", value: "FABRICATION D'AUTRES PRODUITS CERAMIQUES A USAGE T"},
  {id: "1711", value: "FABRICATION D'APPAREILS SANITAIRES EN CERAMIQUE"},
  {id: "1731", value: "TRAVAUX D'INSTALLATION ELECTRIQUE"},
  {id: "1731", value: "CONSTRUCTION DE LIGNES ELECTRIQUES ET DE TELECOMMU"},
  {id: "1731", value: "FABRICATION D'ISOLATEURS ET PIECES ISOLANTES EN CE"},
  {id: "1731", value: "FABRICATION D'ISOLATEURS EN VERRE"},
  {id: "1731", value: "FABRICATION DE PRODUITS CERAMIQUES REFRACTAIRES"},
  {id: "1740", value: "TERRASSEMENTS EN GRANDE MASSE"},
  {id: "1740", value: "PLATRERIE"},
  {id: "1740", value: "FABRICATION DE CARREAUX EN CERAMIQUE"},
  {id: "1740", value: "FABRICATION DE TUILES"},
  {id: "1740", value: "FABRICATION DE BRIQUES"},
  {id: "1740", value: "FABRICATION DE PLATRE"},
  {id: "1740", value: "TRAVAIL DE LA PIERRE"},
  {id: "1740", value: "FABRICATION D'ELEMENTS EN PLATRE POUR LA CONSTRUCT"},
  {id: "1740", value: "EXTRACTION DE PIERRES POUR LA CONSTRUCTION"},
  {id: "1750", value: "MENUISERIE BOIS ET MATIERES PLASTIQUES"},
  {id: "1750", value: "MENUISERIE METALLIQUEET SERRURERIE"},
  {id: "1750", value: "INTERMEDIAIRES DU COMMERCE EN BOIS ET MATERIAUX DE"},
  {id: "1750", value: "TRAVAUX DE CHARPENTE"},
  {id: "1750", value: "FABRICATION D'EMBALLAGES EN BOIS"},
  {id: "1750", value: "FABRICATION DE CHARPENTES ET DE MENUISERIES"},
  {id: "1750", value: "FABRICATION DE PANNEAUX DE BOIS"},
  {id: "1750", value: "IMPREGNATION DU BOIS"},
  {id: "1750", value: "SCIAGE ET RABOTAGE DU BOIS"},
  {id: "1761", value: "TRAVAUX D'ETANCHEIFICATION"},
  {id: "1761", value: "REALISATION DE COUVERTURES PAR ELEMENTS"},
  {id: "1761", value: "FABRICATION DE CHARPENTES ET MENUISERIE METALLIQUE"},
  {id: "1761", value: "TRAITEMENT ET REVETEMENT DES METAUX"},
  {id: "1761", value: "DECOUPAGE, EMBOUTISSAGE"},
  {id: "1761", value: "FABRICATION D'ARTICLES EN FILS METALLIQUES"},
  {id: "1771", value: "CONSTRUCTION DE VOIES FERREES"},
  {id: "1771", value: "TRAVAUX DIVERS DE GROS OEUVRE"},
  {id: "1771", value: "REALISATION DE RESEAUX"},
  {id: "1771", value: "TRAVAUX SOUTERRAINS"},
  {id: "1771", value: "FORAGES ET SONDAGES"},
  {id: "1771", value: "CONSTRUCTION D'OUVRAGES D'ART"},
  {id: "1771", value: "TRAVAUX DE MACONNERIE GENERALE"},
  {id: "1771", value: "AUTRES TRAVAUX SPECIALISES DE CONSTRUCTION"},
  {id: "1771", value: "LEVAGE, MONTAGE"},
  {id: "1771", value: "TRAVAUX MARITIMES ET FLUVIAUX"},
  {id: "1771", value: "CONSTRUCTION DE CHAUSSEES ET DE SOLS SPORTIFS"},
  {id: "1771", value: "FABRICATION D'ELEMENTS EN BETON POUR LA CONSTRUCTI"},
  {id: "1771", value: "FABRICATION DE MORTIERS ET BETONS SECS"},
  {id: "1771", value: "FABRICATION DE BETON PRET A L'EMPLOI"},
  {id: "1771", value: "FABRICATION D'AUTRES OUVRAGES EN BETON OU EN PLATR"},
  {id: "1771", value: "FABRICATION D'OUVRAGES EN FIBRE-CIMENT"},
  {id: "1771", value: "FABRICATION DE CHAUX"},
  {id: "1771", value: "FABRICATION DE CIMENT"},
  {id: "1771", value: "COMMERCE DE GROS MACHINES POUR EXTRACTION"},
  {id: "1771", value: "PRODUCTION DE MATERIAUX DIVERS"},
  {id: "1799", value: "MIROITERIE DE BATIMENT, VITRERIE"},
  {id: "1799", value: "AUTRES TRAVAUX D'INSTALLATION"},
  {id: "1799", value: "TRAVAUX DE FINITION NCA"},
  {id: "1799", value: "FABRICATION DE PRODUITS DIVERS EN TERRE CUITE"},
  {id: "1799", value: "FABRICATION D'AUTRES PRODUITS CERAMIQUES"},
  {id: "1799", value: "FABRICATION DE PIECES TECHNIQUES EN MATIERES PLAST"},
  {id: "1799", value: "FABRICATION D'ARTICLES DIVERS EN MATIERES PLASTIQU"},
  {id: "1799", value: "FABRICATION D'ELEMENTS EN MATIERES PLASTIQUES POUR"},
  {id: "1799", value: "FABRICATION D'EMBALLAGES EN MATIERES PLASTIQUES"},
  {id: "1799", value: "FAB. DE PLAQUES, FEUILLES, TUBES ET PROFILES EN MA"},
  {id: "1799", value: "FABRICATION D'AUTRES ARTICLES EN CAOUTCHOUC"},
  {id: "2741", value: "AGENCES DE PRESSE"},
  {id: "2741", value: "AUTRES ACTIVITES GRAPHIQUES"},
  {id: "2741", value: "COMPOSITION ET PHOTOGRAVURE"},
  {id: "2741", value: "RELIURE ET FINITION"},
  {id: "2741", value: "AUTRE IMPRIMERIE (LABEUR)"},
  {id: "2741", value: "IMPRIMERIE DE JOURNAUX"},
  {id: "2741", value: "AUTRES ACTIVITES D'EDITION"},
  {id: "2741", value: "EDITION DE REVUES ET PERIODIQUES"},
  {id: "2741", value: "EDITION DE JOURNAUX"},
  {id: "2741", value: "EDITION DE LIVRES"},
  {id: "4011", value: "CONSTRUCTION DE MATERIEL FERROVIAIRE ROULANT"},
  {id: "4011", value: "TRANSPORTS FERROVIAIRES"},
  {id: "4111", value: "TRANSPORTS URBAINS DE VOYAGEURS"},
  {id: "4119", value: "AMBULANCES"},
  {id: "4121", value: "TRANSPORT DE VOYAGEURS PAR TAXIS"},
  {id: "4131", value: "AUTRES TRANSPORTS ROUTIERS DE VOYAGEURS"},
  {id: "4131", value: "TRANSPORTS ROUTIERS REGULIERS DE VOYAGEURS"},
  {id: "4214", value: "DEMENAGEMENT"},
  {id: "4214", value: "TRANSPORTS ROUTIERS DE MARCHANDISES INTERURBAINS"},
  {id: "4214", value: "TRANSPORTS ROUTIERS DE MARCHANDISES DE PROXIMITE"},
  {id: "4215", value: "AUTRES ACTIVITES DE COURRIER"},
  {id: "4215", value: "MESSAGERIE, FRET EXPRESS"},
  {id: "4215", value: "ROUTAGE"},
  {id: "4225", value: "ENTREPOSAGE NON FRIGORIFIQUE"},
  {id: "4225", value: "ENTREPOSAGE FRIGORIFIQUE"},
  {id: "4411", value: "TRANSPORTS MARITIMES"},
  {id: "4457", value: "LOCATION DE MATERIELS DE TRANSPORT PAR EAU"},
  {id: "4468", value: "MANUTENTION PORTUAIRE"},
  {id: "4468", value: "SERVICES AEROPORTUAIRES"},
  {id: "4468", value: "SERVICES PORTUAIRES, MARITIMES ET FLUVIAUX"},
  {id: "4511", value: "TRANSPORTS AERIENS NON REGULIERS"},
  {id: "4511", value: "TRANSPORTS AERIENS REGULIERS"},
  {id: "4722", value: "AGENCES DE VOYAGE"},
  {id: "4784", value: "GESTION D'INFRASTRUCTURES DE TRANSPORTS TERRESTRES"},
  {id: "4789", value: "CONSTRUCTION DE CELLULES D'AERONEFS"},
  {id: "4789", value: "FABRICATION DE PROPULSEUR D'AERONEFS"},
  {id: "4789", value: "FABRICATION DE VEHICULES POUR INVALIDES"},
  {id: "4789", value: "CONSTRUCTION DE LANCEURS ET ENGINS SPATIAUX"},
  {id: "4789", value: "FABRICATION DE MATERIELS DE TRANSPORT NCA"},
  {id: "4789", value: "TRANSPORTS FLUVIAUX"},
  {id: "4789", value: "TRANSPORTS SPATIAUX"},
  {id: "4789", value: "TRANSPORTS PAR CONDUITES"},
  {id: "4789", value: "NAVIGATION COTIERE ET D ESTUAIRE"},
  {id: "4789", value: "TRANSPORTS CITIERS"},
  {id: "4789", value: "AFFRETEMENT"},
  {id: "4789", value: "ORGANISATION DES TRANSPORTS INTERNATIONAUX"},
  {id: "4812", value: "FABRICATION D'APPAREILS DE TELEPHONIE"},
  {id: "4814", value: "FAB. D'EQUIPEMENTS D'EMISSION ET DE TRANSMISSION H"},
  {id: "4814", value: "FAB.D'APPAREILS DE RECEPTION, ENREGISMT OU REPRO."},
  {id: "4814", value: "TELECOMMUNICATIONS HORS TRANSMISSION AUDIOVISUELLE"},
  {id: "4816", value: "AUTRES ACTIVITES RATTACHEES A L'INFORMATIQUE"},
  {id: "4899", value: "DISTRIBUTION BOUQUETS PROGRAMMES RADIO & TELEVISIO"},
  {id: "4899", value: "EDITION DE CHAINES THEMATIQUES"},
  {id: "4899", value: "EDITION DE CHAINES GENERALISTES"},
  {id: "4899", value: "TRANSMISSION D'EMISSION DE RADIOS ET DE TELEVISION"},
  {id: "4900", value: "CAPTAGE, TRAITEMENT ET DISTRIBUTION D'EAU"},
  {id: "4900", value: "PRODUCTION ET DISTRIBUTION DE CHALEUR"},
  {id: "4900", value: "DISTRIBUTION DE COMBUSTIBLES GAZEUX"},
  {id: "4900", value: "PRODUCTION DE COMBUSTIBLES GAZEUX"},
  {id: "4900", value: "DISTRIBUTION ET COMMERCE D'ELECTRICITE"},
  {id: "4900", value: "TRANSPORT D'ELECTRICITE"},
  {id: "4900", value: "PRODUCTION D'ELECTRICITE"},
  {id: "5021", value: "FABRICATION DE MEUBLES DE BUREAU ET DE MAGASIN"},
  {id: "5039", value: "COMM. DE GROS DE MATERIAUX"},
  {id: "5045", value: "FABRICATION D'ORDINATEURS ET D'AUTRES EQUIPEMENTS"},
  {id: "5045", value: "COMMERCE DE GROS ORDINATEURS EQUIPEMENTS INFORMAT."},
  {id: "5046", value: "COMMERCE DE GROS D'AUTRES MACHINES ET EQUIPEMENTS"},
  {id: "5047", value: "FABRICATION D'APPAREILS MEDICO-CHIRURGICAUX"},
  {id: "5047", value: "COMMERCE DE GROS DE PRODUITS PHARMACEUTIQUES"},
  {id: "5065", value: "COMMERCE DE GROS COMPOSANTS ET AUTRES EQUIPEMENTS"},
  {id: "5065", value: "COMMERCE DE GROS DE MATERIEL ELECTRONIQUE"},
  {id: "5072", value: "INT. DU COMM EN MEUBLES, ARTICLES DE MENAGE ET QUI"},
  {id: "5072", value: "INT. DU COMM EN MACHINES, EQUIPEMENTS INDUSTRIELS,"},
  {id: "5072", value: "FABRICATION D'EQUIPEMENTS D'AIDE A LA NAVIGATION"},
  {id: "5072", value: "FABRICATION DE MATERIEL D'IMAGERIE MEDICALE ET DE"},
  {id: "5072", value: "FABRICATION D'EQUIPEMENTS DE CONTROLE DES PROCESSU"},
  {id: "5072", value: "FABRICATION D'INSTRUMENTATION SCIENTIFIQUE ET TECH"},
  {id: "5072", value: "RECUPERATION DE MATIERES NON METALLIQUES RECYCLABL"},
  {id: "5072", value: "INDUSTRIE DE LA BROSSERIE"},
  {id: "5072", value: "FABRICATION DE MACHINES DE BUREAU"},
  {id: "5072", value: "FABRICATION DE MOTEURS, GENERATRICES ELEC. DE GRAN"},
  {id: "5072", value: "FABRICATION DE MOTEURS, GENERATRICES ELEC. DE PETI"},
  {id: "5072", value: "FABRICATION D'APPAREILS MENAGERS NON ELECTRIQUES"},
  {id: "5072", value: "FABRICATION D'ARMES DE CHASSE, DE TIR ET DE DEFENS"},
  {id: "5072", value: "FABRICATION D'ARMEMENT"},
  {id: "5072", value: "FABRICATION DE MACHINES SPECIALISEES DIVERSES"},
  {id: "5072", value: "FABRICATION DE MACHINES D'ASSEMBLAGE AUTOMATIQUE"},
  {id: "5072", value: "FABRICATION DE MOULES ET MODELES"},
  {id: "5072", value: "FAB DE MACHINES POUR LE TRAVAIL DU CAOUTCHOUC OU D"},
  {id: "5072", value: "FABRICATION DE MACHINES D'IMPRIMERIE"},
  {id: "5072", value: "FAB DE MACHINES POUR LES INDUSTRIES DU PAPIER ET D"},
  {id: "5072", value: "FABRICATION DE MACHINES POUR LES INDUSTRIES TEXTIL"},
  {id: "5072", value: "FABRICATION DE MACHINES POUR L'INDUSTRIE AGRO-ALIM"},
  {id: "5072", value: "FABRICATION DE COMPOSANTS PASSIFS ET DE CONDENSATE"},
  {id: "5072", value: "FABRICATION DE MATERIELS ELECTRIQUES NCA"},
  {id: "5072", value: "FABRICATION DE MATERIEL ELECTRO-MAGNETIQUE INDUSTR"},
  {id: "5072", value: "FABRICATION DE MATERIELS ELECTRIQUES POUR MOTEURS"},
  {id: "5072", value: "FABRICATION D'APPAREILS D'ECLAIRAGE"},
  {id: "5072", value: "FABRICATION D'APPAREILS ELECTRIQUES AUTONOMES DE S"},
  {id: "5072", value: "FABRICATION DE LAMPES"},
  {id: "5072", value: "FABRICATION D'ACCUMULATEURS ET DE PILES ELECTRIQUE"},
  {id: "5072", value: "FABRICATION DE FILS ET CABLES ISOLES"},
  {id: "5072", value: "FAB. DE MAT. DE DISTRIBUTION ET DE COMMANDEELEC.PO"},
  {id: "5072", value: "FAB DE MAT. DE DISTRIBUTION ET DE COMMANDE ELEC PO"},
  {id: "5072", value: "REPARATION DE MATERIELS ELECTRIQUES"},
  {id: "5072", value: "FABRICATION DE MATERIEL DE TRAVAUX PUBLIQUES"},
  {id: "5072", value: "FABRICATION DE MATERIEL DE MINES POUR L'EXTRACTION"},
  {id: "5072", value: "FABRICATION DE MACHINES POUR LA METALLURGIE"},
  {id: "5072", value: "FABRICATION D'AUTRES MACHINES-OUTILS"},
  {id: "5072", value: "FABRICATION DE MATERIEL DE SOUDAGE"},
  {id: "5072", value: "FABRIC.MACHINES-OUTILS PORTATIVES MOTEUR INCORPORE"},
  {id: "5072", value: "FABRICATION DE MACHINES-OUTILS A BOIS"},
  {id: "5072", value: "FABRICATION DE MACHINES-OUTILS A METAUX"},
  {id: "5072", value: "FABRICATION DE TRACTEURS AGRICOLES"},
  {id: "5072", value: "FABRICATION D'AUTRES MACHINES D'USAGE GENERAL"},
  {id: "5072", value: "FABRICATION MATERIELS POUR INDUSTRIES CHIMIQUES"},
  {id: "5072", value: "FABRICATION D'APPAREILS DE PESAGE"},
  {id: "5072", value: "FABRICATION D'EQUIPEMENTS D'EMBALLAGE ET DE CONDIT"},
  {id: "5072", value: "FABRICATION D'EQUIPEMENTS AERAULIQUES ET FRIGORIFI"},
  {id: "5072", value: "FABRICATION D'EQUIPEMENTS DE LEVAGE ET DE MANUTENT"},
  {id: "5072", value: "FABRICATION D'ASCENSEURS, MONTE-CHARGES ET ESCALIE"},
  {id: "5072", value: "FABRICATION DE FOURS ET BRULEURS"},
  {id: "5072", value: "FABRICATION D'ORGANES MECANIQUES DE TRANSMISSION"},
  {id: "5072", value: "FABRICATION DE ROULEMENTS"},
  {id: "5072", value: "FABRICATION D'ARTICLES DE ROBINETTERIE"},
  {id: "5072", value: "FABRICATION DE COMPRESSEURS"},
  {id: "5072", value: "FABRICATION DE TRANSMISSIONS HYDRAULIQUES ET PNEUM"},
  {id: "5072", value: "FABRICATION DE POMPES"},
  {id: "5072", value: "FABRICATION DE MOTEURS ET TURBINES"},
  {id: "5072", value: "FABRICATION D'ARTICLES METALIQUES DIVERS"},
  {id: "5072", value: "FABRICATION DE PETITS ARTICLES METALLIQUES"},
  {id: "5072", value: "FABRICATION D'ARTICLES DE MENAGE"},
  {id: "5072", value: "FABRICATION DE CHAINES"},
  {id: "5072", value: "FABRICATION DE RESSORTS"},
  {id: "5072", value: "VISSERIE ET BOULONNERIE"},
  {id: "5072", value: "TREFILAGE A FROID"},
  {id: "5072", value: "PROFILAGE A FROID PAR FORMAGE OU PLIAGE"},
  {id: "5072", value: "LAMINAGE A FROID DE FEUILLARDS"},
  {id: "5072", value: "ETIRAGE A FROID"},
  {id: "5072", value: "FABRICATION DE TUBES EN ACIER"},
  {id: "5072", value: "FABRICATION DE TUBES EN FONTE"},
  {id: "5072", value: "SIDERURGIE"},
  {id: "5072", value: "FABRICATION D'EMBALLAGES LEGERS EN METAL"},
  {id: "5072", value: "FABRICATION DE FUTS ET EMBALLAGES SIMILAIRES EN ME"},
  {id: "5072", value: "FABRICATION DE SERRURES ET FERRURES"},
  {id: "5072", value: "FABRICATION D'OUTILLAGE MECANIQUE"},
  {id: "5072", value: "FABRICATION D'OUTILLAGE A MAIN"},
  {id: "5072", value: "FABRICATION DE COUTELLERIE"},
  {id: "5072", value: "METALLURGIE DES POUDRES"},
  {id: "5072", value: "DECOLLETAGE"},
  {id: "5072", value: "FORGE, ESTAMPAGE, MATRICAGE"},
  {id: "5072", value: "CHAUDRONNERIE NUCLEAIRE"},
  {id: "5072", value: "FABRICATION DE GENERATEURS DE VAPEUR"},
  {id: "5072", value: "FABRICATION DE CONSTRUCTIONS METALLIQUES"},
  {id: "5072", value: "FABRICATION RESERVOIRS CITERNES ET CONTENEURS"},
  {id: "5072", value: "FONDERIE D'AUTRES METAUX NON FERREUX"},
  {id: "5072", value: "FONDERIE DE METAUX LEGERS"},
  {id: "5072", value: "FONDERIE D'ACIER"},
  {id: "5072", value: "FONDERIE DE FONTE"},
  {id: "5072", value: "METALLURGIE DES AUTRES METAUX NON FERREUX"},
  {id: "5072", value: "PREMIERE TRANSFORMATION DU CUIVRE"},
  {id: "5072", value: "PRODUCTION DE CUIVRE"},
  {id: "5072", value: "PREMIERE TRANSFORMATION DU PLOMB, DU ZINC OU DE L'"},
  {id: "5072", value: "PRODUCTION DE PLOMB, DE ZINC OU D'ETAIN"},
  {id: "5072", value: "PREMIERE TRANSFORMATION DE L'ALUMINIUM"},
  {id: "5072", value: "PRODUCTION D'ALUMINIUM"},
  {id: "5072", value: "PRODUCTION DE METAUX PRECIEUX"},
  {id: "5072", value: "COMMERCE DE GROS FOURNITURES ET EQUIPEMENTS INDUST"},
  {id: "5072", value: "COMMERCE DE GROS FOURNITURES ET EQUIPEMENTS DIVERS"},
  {id: "5072", value: "COMMERCE DE GROS MACHINES POUR INDUSTRIE TEXTILE"},
  {id: "5072", value: "COMMERCE DE GROS DE MACHINES OUTILS"},
  {id: "5072", value: "FABRICATION DE PATE A PAPIER"},
  {id: "5072", value: "FABRICATION D'ARTICLES EN PAPIER A USAGE SANITAIRE"},
  {id: "5072", value: "FABRICATION D'EMBALLAGES EN PAPIER"},
  {id: "5072", value: "FABRICATION DE CARTONNAGES"},
  {id: "5072", value: "INDUSTRIE DU CARTON ONDULE"},
  {id: "5072", value: "FABRICATION DE PAPIER ET DE CARTON"},
  {id: "5074", value: "COMMERCE DE GROS DE FOURNITURES POUR PLOMBERIE ET"},
  {id: "5085", value: "COMMERCE DE GROS DE QUINCAILLERIE"},
  {id: "5094", value: "BIJOUTERIE, JOAILLERIE, ORFEVRERIE"},
  {id: "5122", value: "FABRICATION D'AUTRES PRODUITS PHARMACEUTIQUES"},
  {id: "5122", value: "FABRICATION DE MEDICAMENTS"},
  {id: "5122", value: "FABRICATION DE PRODUITS PHARMACEUTIQUES DE BASE"},
  {id: "5131", value: "PREPARATION DE LA LAINE"},
  {id: "5131", value: "FILATURE DE L'INDUSTRIE LAINIERE - CYCLE CARDE"},
  {id: "5131", value: "FABRICATION DE PULL-OVERS ET ARTICLES SIMILAIRES"},
  {id: "5131", value: "FABRICATION D'ARTICLES CHAUSSANTS A MAILLE"},
  {id: "5131", value: "FABRICATION D'ETOFFES A MAILLE"},
  {id: "5131", value: "INDUSTRIES TEXTILES NCA"},
  {id: "5131", value: "FABRICATION DE NON-TISSES"},
  {id: "5131", value: "FICELLERIE, CORDERIE, FABRICATION DE FILETS"},
  {id: "5131", value: "FABRICATION D'AUTRES ARTICLES CONFECTIONNES EN TEX"},
  {id: "5131", value: "FABRICATION DE PETITS ARTICLES TEXTILES DE LITERIE"},
  {id: "5131", value: "FABRICATION DE LINGE DE MAISON ET D'ARTICLES D'AME"},
  {id: "5131", value: "ENNOBLISSEMENT TEXTILE"},
  {id: "5131", value: "TISSAGE D'AUTRES TEXTILES"},
  {id: "5131", value: "TISSAGE DE SOIERIES"},
  {id: "5131", value: "TISSAGE DE L'INDUSTRIE LAINIERE - CYCLE PEIGNE"},
  {id: "5131", value: "TISSAGE DE L'INDUSTRIE LAINIERE - CYCLE CARDE"},
  {id: "5131", value: "TISSAGE DE L'INDUSTRIE COTONNIERE"},
  {id: "5131", value: "PREPARATION ET FILATURE D'AUTRES FIBRES"},
  {id: "5131", value: "FABRICATION DE FILS A COUDRE"},
  {id: "5131", value: "MOULINAGE ET TEXTURATION DE LA SOIE ET DES TEXTILE"},
  {id: "5131", value: "FILATURE DU LIN"},
  {id: "5137", value: "FABRICATION DE VETEMENTS DE TRAVAIL"},
  {id: "5169", value: "FABRICATION DE COLLES ET GELATINES"},
  {id: "5169", value: "FABRICATION DE PRODUITS EXPLOSIFS"},
  {id: "5169", value: "FABRICATION DE PRODUITS AGROCHIMIQUES"},
  {id: "5169", value: "FABRICATION DE CAOUTCHOUC SYNTHETIQUE"},
  {id: "5169", value: "FABRICATION DE MATIERES PLASTIQUES DE BASE"},
  {id: "5169", value: "FABRICATION DE PRODUITS AZOTES ET D'ENGRAIS"},
  {id: "5169", value: "FABRICATION DE FIBRES ARTIFICIELLES OU SYNTHETIQUE"},
  {id: "5169", value: "FABRICATION DE PRODUITS CHIMIQUES A USAGE INDUSTRI"},
  {id: "5169", value: "FABRICATION DE PRODUITS CHIMIQUES POUR LA PHOTOGRA"},
  {id: "5169", value: "FABRICATION DE PRODUITS MINERAUX NON METALLIQUES N"},
  {id: "5169", value: "FABRICATION DE PRODUITS ABRASIFS"},
  {id: "5169", value: "FABRICATION DE FIBRES DE VERRE"},
  {id: "5169", value: "COMMERCE DE GROS DE PRODUITS CHIMIQUES"},
  {id: "5169", value: "COMMERCE DE GROS DE MINERAIS ET METAUX"},
  {id: "5169", value: "COMMERCE DE GROS DE DECHETS ET DEBRIS"},
  {id: "5169", value: "COMMERCE DE GROS D'AUTRES PRODUITS INTERMEDIAIRES"},
  {id: "5169", value: "FABRICATION D'AUTRES PRODUITS CHIMIQUES ORGANIQUES"},
  {id: "5169", value: "FABRICATION D'AUTRES PRODUITS CHIMIQUES INORGANIQU"},
  {id: "5169", value: "FABRICATION DE COLORANTS ET DE PIGMENTS"},
  {id: "5169", value: "FABRICATION DE GAZ INDUSTRIELS"},
  {id: "5169", value: "ELABORATION ET TRANSFORMATION DE MATIERES NUCLEAIR"},
  {id: "5169", value: "EXTRACTION ET AGGLOMERATION DE LA HOUILLE"},
  {id: "5169", value: "EXTRACTION D'ARGILES ET DE KAOLIN"},
  {id: "5169", value: "EXTRACTION D'ARDOISE"},
  {id: "5169", value: "EXTRACTION DE CALCAIRE INDUSTRIEL, DE GYPSE ET DE"},
  {id: "5169", value: "EXTRACTION DE MINERAUX POUR L'INDUS. CHIMIQUE ET D"},
  {id: "5169", value: "ACTIVITES EXTRACTIVES NCA"},
  {id: "5169", value: "EXTRACTION DE MINERAIS DE METAUX NON FERREUX"},
  {id: "5169", value: "EXTRACTION DE MINERAIS DE FER"},
  {id: "5169", value: "EXTRACTION DE MINERAIS D'URANIUM"},
  {id: "5169", value: "EXTRACTION ET AGGLOMERATION DE LA TOURBE"},
  {id: "5169", value: "EXTRACTION ET AGGLOMERATION DU LIGNITE"},
  {id: "5172", value: "SERVICES ANNEXES A L'EXTRACTION D'HYDROCARBURES"},
  {id: "5172", value: "EXTRACTION D'HYDROCARBURES"},
  {id: "5172", value: "RAFFINAGE DE PETROLE"},
  {id: "5172", value: "COKEFACTION"},
  {id: "5193", value: "COMMERCE DE GROS DE FLEURS ET PLANTES"},
  {id: "5198", value: "PEINTURE"},
  {id: "5198", value: "FABRICATION DE PEINTURES ET VERNIS"},
  {id: "5200", value: "FAB D'ARTICLES CERAMIQUES A USAGE DOMESTIQUE OU OR"},
  {id: "5200", value: "AUTRES ACTIVITES MANUFACTURIERES NCA"},
  {id: "5200", value: "COMMERCE DE DETAIL NON ALIMENTAIRE SUR EVENTAIRES"},
  {id: "5200", value: "COMMERCES DE DETAIL DIVERS EN MAGASIN SPECIALISE"},
  {id: "5200", value: "VENTE PAR AUTOMATE"},
  {id: "5200", value: "FABRICATION DE PAPIERS PEINTS"},
  {id: "5200", value: "FABRICATION D'OBJETS DIVERS EN BOIS"},
  {id: "5211", value: "COMMERCE DE GROS DE BOIS ET DE PRODUITS DERIVES"},
  {id: "5231", value: "COMMERCE DE DETAIL DE BRICOLAGE"},
  {id: "5251", value: "CENTRALES D'ACHATS NON ALIMENTAIRES"},
  {id: "5251", value: "INTERMEDIAIRES NON SPECIALISES DU COMMERCE"},
  {id: "5251", value: "INTERMEDIAIRES SPECIALISES DU COMMERCE"},
  {id: "5251", value: "COMMERCE DE DETAIL DE QUINCAILLERIE"},
  {id: "5251", value: "COMMERCE DE DETAIL D'EQUIPEMENT DU FOYER"},
  {id: "5251", value: "COMMERCE DE GROS NON SPECIALISES"},
  {id: "5251", value: "AUTRES COMMERCES DE GROS SPECIALISES"},
  {id: "5251", value: "AUTRES COMMERCE NON ALIMENTAIRE NON SPECIALISE"},
  {id: "5261", value: "HORTICULTURE ET PEPINIERES"},
  {id: "5311", value: "GRANDS MAGASINS"},
  {id: "5331", value: "HYPERMARCHES"},
  {id: "5411", value: "CENTRALES D'ACHATS ALIMENTAIRES"},
  {id: "5411", value: "INTERMEDIAIRES DU COMMERCE EN PRODUITS ALIMENTAIRE"},
  {id: "5411", value: "MAGASINS POPULAIRES"},
  {id: "5411", value: "SUPERMARCHES"},
  {id: "5411", value: "SUPERETTES"},
  {id: "5411", value: "COMMERCE DE DETAIL DE FRUITS ET LEGUMES"},
  {id: "5411", value: "COMMERCE D'ALIMENTATION GENERALE"},
  {id: "5411", value: "COMMERCE DE DETAIL DE BOISSONS"},
  {id: "5411", value: "COMMERCES DE DETAIL ALIMENTAIRES SPECIALISES DIVER"},
  {id: "5411", value: "COMMERCE DETAIL ALIMENTAIRE EVENTAIRES ET MARCHES"},
  {id: "5411", value: "COMMERCE DE GROS ALIMENTAIRE NON SPECIALISE"},
  {id: "5422", value: "COMMERCE DE GROS DE PRODUITS SURGELES"},
  {id: "5422", value: "COMMERCE DE DETAIL DE PRODUITS SURGELES"},
  {id: "5441", value: "COMMERCE DE GROS DE SUCRE, CHOCOLAT, CONFISERIE"},
  {id: "5441", value: "CHOCOLATERIE, CONFISERIE"},
  {id: "5441", value: "FABRICATION DE SUCRE"},
  {id: "5451", value: "COMMERCE DE GROS DE PRODUITS LAITIERS, OEUFS, HUIL"},
  {id: "5451", value: "COMMERCE DE DETAIL DE PRODUITS LAITIERS"},
  {id: "5451", value: "FABRICATION DE LAIT LIQUIDE ET DE PRODUITS FRAIS"},
  {id: "5451", value: "FABRICATION DE GLACES ET SORBETS"},
  {id: "5451", value: "FABRICATION D'AUTRES PRODUITS LAITIERS"},
  {id: "5451", value: "FABRICATION DE FROMAGES"},
  {id: "5451", value: "FABRICATION DE BEURRE"},
  {id: "5462", value: "COMMERCE DE DETAIL DE PAIN, PATISSERIE ET CONFISER"},
  {id: "5462", value: "BISCOTTERIE, BISCUITERIE, PATISSERIE DE CONSERVATI"},
  {id: "5462", value: "PATISSERIE ARTISANALE"},
  {id: "5462", value: "FABRICATION DE PRODUITS AMYLACES"},
  {id: "5462", value: "AUTRES ACTIVITES DE TRAVAIL DES GRAINS"},
  {id: "5462", value: "MEUNERIE"},
  {id: "5462", value: "FABRICATION INDUSTRIELLE DE PAIN ET DE PATISSERIE"},
  {id: "5462", value: "BOULANGERIE ET BOULANGERIE-PATISSERIE ARTISANALES"},
  {id: "5462", value: "CUISSON DE PRODUITS DE BOULANGERIE"},
  {id: "5511", value: "FABRICATION DE MATERIEL AGRICOLE"},
  {id: "5511", value: "REPARATION DE MATERIEL AGRICOLE"},
  {id: "5511", value: "COMMERCE DE VEHICULES AUTOMOBILES"},
  {id: "5511", value: "FABRICATION DE CARROSSERIES AUTOMOBILES"},
  {id: "5511", value: "CONSTRUCTION DE VEHICULES AUTOMOBILES"},
  {id: "5511", value: "COMMERCE DE GROS DE MATERIEL AGRICOLE"},
  {id: "5532", value: "FABRICATION DE PNEUMATIQUES"},
  {id: "5533", value: "COMMERCE DE DETAIL D'EQUIPEMENTS AUTOMOBILES"},
  {id: "5533", value: "COMMERCE DE GROS D'EQUIPEMENTS AUTOMOBILES"},
  {id: "5533", value: "FABRICATION D'EQUIPEMENTS AUTOMOBILES"},
  {id: "5541", value: "COMMERCE DE DETAIL DE CARBURANTS"},
  {id: "5542", value: "NAF FICTIF GIE CB POUR TRANSCODIFICATION MCC 5542"},
  {id: "5551", value: "CONSTRUCTION DE BATEAUX DE PLAISANCE"},
  {id: "5551", value: "CONSTRUCTION DE BATIMENTS DE GUERRE"},
  {id: "5551", value: "CONSTRUCTION DE NAVIRES CIVILS"},
  {id: "5571", value: "FABRICATION DE MOTOCYCLES"},
  {id: "5571", value: "COMMERCE ET REPARATION DE MOTOCYCLES"},
  {id: "5592", value: "FABRICATIONS DE CARAVANES ET VEHICULES DE LOISIRS"},
  {id: "5611", value: "FABRICATION DE VETEMENTS DE DESSUS POUR HOMMES ET"},
  {id: "5621", value: "FABRICATION DE VETEMENTS DE DESSUS POUR FEMMES ET"},
  {id: "5631", value: "BIJOUTERIE FANTAISIE"},
  {id: "5631", value: "FABRICATION D'AUTRES VETEMENTS ET ACCESSOIRES"},
  {id: "5651", value: "COMMERCE DE DETAIL D'HABILLEMENT"},
  {id: "5651", value: "FABRICATION DE VETEMENTS DE DESSOUS"},
  {id: "5661", value: "COMMERCE DE DETAIL DE LA CHAUSSURE"},
  {id: "5661", value: "COMMERCE DE GROS DE LA CHAUSSURE"},
  {id: "5661", value: "FABRICATION DE CHAUSSURES"},
  {id: "5681", value: "APPRET ET TANNAGE DES CUIRS"},
  {id: "5681", value: "INDUSTRIE DES FOURRURES"},
  {id: "5691", value: "INT. DU COMM EN TEXTILES,HABILLEMENT,CHAUSSURES ET"},
  {id: "5691", value: "COMMERCE DE GROS D'HABILLEMENT"},
  {id: "5691", value: "FABRICATION DE VETEMENTS EN CUIR"},
  {id: "5697", value: "FABRICATION DE VETEMENTS SUR MESURE"},
  {id: "5712", value: "FABRICATION DE MEUBLES MEUBLANTS"},
  {id: "5712", value: "COMMERCE DE DETAIL DE MEUBLES"},
  {id: "5713", value: "REVATEMENT DES SOLS ET DES MURS"},
  {id: "5713", value: "COMMERCE DE DETAIL DE REVETEMENTS DE SOLS ET DE MU"},
  {id: "5714", value: "COMMERCE DE GROS DE TEXTILES"},
  {id: "5714", value: "COMMERCE DE DETAIL DE TEXTILES"},
  {id: "5719", value: "FABRICATION DE MATELAS"},
  {id: "5719", value: "FABRICATION DE SIEGES"},
  {id: "5719", value: "FABRICATION DE MEUBLES DE CUISINE"},
  {id: "5719", value: "INDUSTRIES CONNEXES DE L'AMEUBLEMENT"},
  {id: "5719", value: "FABRICATION DE MEUBLES NCA"},
  {id: "5719", value: "FABRICATION DE MEUBLES DE JARDIN ET D'EXTERIEUR"},
  {id: "5719", value: "AUTRES COMMERCES DE GROS DE BIENS DE CONSOMMATION"},
  {id: "5719", value: "COMM. DE GROS DE PRODUITS POUR ENTRETIEN ET AMENAG"},
  {id: "5719", value: "COMMERCE DE GROS DE VAISSELLE ET VERRERIE DE MENAG"},
  {id: "5719", value: "FABRICATION DE TAPIS ET MOQUETTES"},
  {id: "5719", value: "FABRICATION D'OBJETS EN LIEGE, VANNERIE ET SPARTER"},
  {id: "5722", value: "FABRICATION DE COMPOSANTS ELECTRONIQUES ACTIFS"},
  {id: "5722", value: "FABRICATION D'APPAREILS ELECTROMENAGERS"},
  {id: "5722", value: "ASSEMBLAGE CARTES ELECTRONIQUES COMPTE DE TIERS"},
  {id: "5722", value: "COMM.DE GROS D'APPAREILS ELECTRO MENAGERS ET DE RA"},
  {id: "5732", value: "COMM. DE DETAIL D'APPAREILS ELECTROMENAGERS"},
  {id: "5733", value: "FABRICATION D'INSTRUMENTS DE MUSIQUE"},
  {id: "5733", value: "REPRODUCTION D'ENREGISTREMENTS SONORES"},
  {id: "5733", value: "EDITION D'ENREGISTREMENTS SONORES"},
  {id: "5734", value: "EDITION DE LOGICIELS (NON PERSONNALISES)"},
  {id: "5811", value: "TRAITEURS, ORGANISATION DE RECEPTIONS"},
  {id: "5812", value: "RESTAURATION DE TYPE TRADITIONNEL"},
  {id: "5813", value: "DISCOTHEQUES"},
  {id: "5813", value: "DEBITS DE BOISSONS"},
  {id: "5813", value: "CAFES TABACS"},
  {id: "5814", value: "CANTINES ET RESTAURANTS D'ENTREPRISES"},
  {id: "5814", value: "RESTAURATION COLLECTIVE SOUS CONTRAT"},
  {id: "5814", value: "RESTAURATION DE TYPE RAPIDE"},
  {id: "5912", value: "COMMERCE DE DETAIL DE PRODUITS PHARMACEUTIQUES"},
  {id: "5921", value: "COMMERCE DE GROS DE BOISSONS"},
  {id: "5931", value: "COMMERCE DE DETAIL DE BIENS D'OCCASION EN MAGASIN"},
  {id: "5935", value: "RECUPERATION DE MATIERES METALLIQUES RECYCLABLES"},
  {id: "5940", value: "FABRICATION DE BICYCLETTES"},
  {id: "5941", value: "FABRICATION D'ARTICLES DE SPORT"},
  {id: "5941", value: "COMMERCE DE DETAIL D'ARTICLES DE SPORT ET DE LOISI"},
  {id: "5942", value: "COMMERCE DE DETAIL DE LIVRES, JOURNAUX ET PAPETERI"},
  {id: "5943", value: "COMMERCE DE GROS DE PAPETERIE"},
  {id: "5943", value: "FABRICATION D'AUTRES ARTICLES EN PAPIER OU EN CART"},
  {id: "5943", value: "FABRICATION D'ARTICLES DE PAPETERIE"},
  {id: "5944", value: "COMMERCE DE DETAIL D'HORLOGERIE ET DE BIJOUTERIE"},
  {id: "5945", value: "FABRICATION DE JEUX ET JOUETS"},
  {id: "5945", value: "COMMERCE DE GROS DE JOUETS"},
  {id: "5946", value: "COMMERCE DE DETAIL D'OPTIQUE ET DE PHOTOGRAPHIE"},
  {id: "5948", value: "COMMERCE DE GROS DE CUIRS ET PEAUX"},
  {id: "5948", value: "COMMERCE DE DETAIL DE MAROQUINERIE ET D'ARTICLES D"},
  {id: "5948", value: "FABRICATION D'ARTICLES DE VOYAGE ET DE MAROQUINERI"},
  {id: "5949", value: "FILATURE DE L'INDUSTRIE LAINIERE - CYCLE PEIGNE"},
  {id: "5949", value: "FILATURE DE L'INDUSTRIE COTONNIERE"},
  {id: "5950", value: "FACONNAGE ET TRANSFORMATION DU VERRE PLAT"},
  {id: "5950", value: "FABRICATION DE VERRE CREUX"},
  {id: "5950", value: "FABRICATION ET FACONNAGE D'ARTICLES TECHNIQUES EN"},
  {id: "5950", value: "FABRICATION DE VERRE PLAT"},
  {id: "5963", value: "VENTE A DOMICILE"},
  {id: "5965", value: "VENTE PAR CORRESPONDANCE SUR CATALOGUE GENERAL"},
  {id: "5965", value: "VENTE PAR CORRESPONDANCE SPECIALISEE"},
  {id: "5972", value: "FABRICATION DE MONNAIES ET MEDAILLES"},
  {id: "5976", value: "COMMERCE DE DETAIL D'ARTICLES MEDICAUX ET ORTHOPED"},
  {id: "5977", value: "FABRICATION DE PARFUMS ET DE PRODUITS POUR LA TOIL"},
  {id: "5977", value: "FABRICATION DE SAVONS, DETERGENTS ET PRODUITS D'EN"},
  {id: "5977", value: "FABRICATION D'HUILES ESSENTIELLES"},
  {id: "5977", value: "COMMERCE DE DETAIL DE PARFUMERIE ET DE PRODUITS DE"},
  {id: "5977", value: "COMMERCE DE GROS DE PARFUMERIE ET PRODUITS BEAUTE"},
  {id: "5983", value: "INT. DU COMM.EN COMBUSTIBLES, METAUX, MINERAUX ET"},
  {id: "5983", value: "COMMERCE DE DETAIL DE CHARBONS ET COMBUSTIBLES"},
  {id: "5983", value: "COMMERCE DE GROS DE COMBUSTIBLES"},
  {id: "5992", value: "COMMERCE DE DETAIL DE FLEURS"},
  {id: "5993", value: "COMMERCE DE GROS DE TABAC NON MANUFACTURE"},
  {id: "5993", value: "COMMERCE DE GROS DE TABAC"},
  {id: "5993", value: "COMMERCE DE DETAIL DE TABAC"},
  {id: "5993", value: "INDUSTRIE DU TABAC"},
  {id: "5995", value: "COMMERCE DE GROS D'ANIMAUX VIVANTS"},
  {id: "6010", value: "BANQUE CENTRALE"},
  {id: "6010", value: "DISTRIBUTION DE CREDIT"},
  {id: "6010", value: "CAISSES D'EPARGNE"},
  {id: "6010", value: "CREDIT BAIL"},
  {id: "6010", value: "BANQUES MUTUALISTES"},
  {id: "6010", value: "BANQUES"},
  {id: "6012", value: "INTERMEDIATIONS FINANCIERES DIVERSES"},
  {id: "6051", value: "AUTRES AUXILIAIRES FINANCIERS"},
  {id: "6211", value: "GESTION DE PORTEFEUILLES"},
  {id: "6211", value: "ADMINISTRATION DE MARCHES FINANCIERS"},
  {id: "6211", value: "ORGANISMES DE PLACEMENT EN VALEURS MOBILIERES"},
  {id: "6300", value: "ASSURANCE VIE ET CAPITALISATION"},
  {id: "6300", value: "AUXILIAIRES D'ASSURANCE"},
  {id: "6300", value: "ASSURANCE RELEVANT DU CODE DE LA MUTUALITE"},
  {id: "6300", value: "ASSURANCE DOMMAGES"},
  {id: "6300", value: "REASSURANCE"},
  {id: "6300", value: "CAISSES DE RETRAITE"},
  {id: "6513", value: "LOCATION D'AUTRES BIENS IMMOBILIERS"},
  {id: "6513", value: "LOCATION DE TERRAINS"},
  {id: "6513", value: "LOCATION DE LOGEMENTS"},
  {id: "6513", value: "MARCHANDS DE BIENS IMMOBILIERS"},
  {id: "6513", value: "PROMOTION IMMOBILIERE D'INFRASTRUCTURES"},
  {id: "6513", value: "PROMOTION IMMOBILIERE DE BUREAUX"},
  {id: "6513", value: "PROMOTION IMMOBILIERE DE LOGEMENTS"},
  {id: "6513", value: "ADMINISTRATION D'AUTRES BIENS IMMOBILIERS"},
  {id: "6513", value: "AGENCES IMMOBILIERES"},
  {id: "7011", value: "AUTRES HOTELS"},
  {id: "7011", value: "HOTELS AVEC RESTAURANT"},
  {id: "7011", value: "HOTELS DE TOURISME SANS RESTAURANT"},
  {id: "7012", value: "ADMINISTRATION D'IMMEUBLES RESIDENTIELS"},
  {id: "7032", value: "AUBERGES DE JEUNESSE ET REFUGES"},
  {id: "7032", value: "HEBERGEMENT COLLECTIF NON TOURISTIQUE"},
  {id: "7033", value: "AUTRE HEBERGEMENT TOURISTIQUE"},
  {id: "7033", value: "EXPLOITATION DE TERRAINS DE CAMPING"},
  {id: "7210", value: "BLANCHISSERIE - TEINTURERIE DE GROS"},
  {id: "7211", value: "BLANCHISSERIE - TEINTURERIE DE DETAIL"},
  {id: "7221", value: "STUDIOS ET AUTRES ACTIVITES PHOTOGRAPHIQUES"},
  {id: "7230", value: "COIFFURE"},
  {id: "7230", value: "SOINS DE BEAUTE"},
  {id: "7251", value: "REPARATION DE CHAUSSURES ET ARTICLES EN CUIR"},
  {id: "7261", value: "POMPES FUNEBRES"},
  {id: "7261", value: "SOINS AUX DEFUNTS"},
  {id: "7297", value: "AUTRES SOINS CORPORELS"},
  {id: "7311", value: "AGENCES, CONSEIL EN PUBLICITE"},
  {id: "7311", value: "GESTION DE SUPPORTS DE PUBLICITE"},
  {id: "7339", value: "SECRETARIAT ET TRADUCTION"},
  {id: "7339", value: "CENTRES D'APPELS"},
  {id: "7349", value: "AUTRES SERVICES PERSONNELS"},
  {id: "7349", value: "SERVICES DOMESTIQUES"},
  {id: "7349", value: "ACTIVITES DE NETTOYAGE"},
  {id: "7361", value: "TRAVAIL TEMPORAIRE"},
  {id: "7372", value: "FABRICATION DE SUPPORTS DE DONNEES"},
  {id: "7372", value: "AUTRES ACTIVITES DE REALISATION DE LOGICIELS"},
  {id: "7372", value: "ACTIVITES DE BANQUES DE DONNEES"},
  {id: "7372", value: "TRAITEMENT DE DONNEES"},
  {id: "7372", value: "CONSEIL EN SYSTEMES INFORMATIQUES"},
  {id: "7372", value: "REPRODUCTION D'ENREGISTREMENTS INFORMATIQUES"},
  {id: "7379", value: "ENTRETIEN"},
  {id: "7392", value: "CONSEIL POUR LES AFFAIRES ET LA GESTION"},
  {id: "7392", value: "ETUDES DE MARCHE ET SONDAGES"},
  {id: "7392", value: "SELECTION ET MISE A DISPOSITION DE PERSONNEL"},
  {id: "7392", value: "ORGANISATION DE FOIRES ET SALONS"},
  {id: "7393", value: "ENQUETES ET SECURITE"},
  {id: "7394", value: "LOCATION AVEC OPERATEUR DE MATERIEL DE CONSTRUCTIO"},
  {id: "7394", value: "LOCATION D'AUTRES BIENS PERSONNELS ET DOMESTIQUES"},
  {id: "7394", value: "LOCATION DE LINGE"},
  {id: "7394", value: "LOCATION DE MACHINES ET EQUIPEMENTS DIVERS"},
  {id: "7394", value: "LOCATION DE MACHINES DE BUREAU ET DE MATERIEL INFO"},
  {id: "7394", value: "LOCATION DE MACHINES ET EQUIPEMENTS POUR LA CONSTR"},
  {id: "7395", value: "LABORATOIRES TECHNIQUES DE DEVELOPPEMENT ET DE TIR"},
  {id: "7399", value: "CONDITIONNEMENT A FACON"},
  {id: "7399", value: "SERVICES ANNEXES A LA PRODUCTION"},
  {id: "7399", value: "ADMINISTRATION D'ENTREPRISES"},
  {id: "7399", value: "MANUTENTION NON PORTUAIRE"},
  {id: "7512", value: "LOCATION D'AUTRES MATERIELS DE TRANSPORT TERRESTR"},
  {id: "7512", value: "LOCATION DE LONGUE DUREE DE VEHICULES AUTOMOBILES"},
  {id: "7512", value: "LOCATION DE COURTE DUREE DE VEHICULES AUTOMOBILES"},
  {id: "7513", value: "LOCATION DE MATERIEL AGRICOLE"},
  {id: "7513", value: "LOCATION D'APPAREILS DE TRANSPORT AERIEN"},
  {id: "7513", value: "LOCATION DE CAMIONS AVEC CONDUCTEUR"},
  {id: "7534", value: "RECHAPAGE DE PNEUMATIQUES"},
  {id: "7538", value: "CONTROLE TECHNIQUE AUTOMOBILE"},
  {id: "7538", value: "REPARATION NAVALE"},
  {id: "7538", value: "MECANIQUE GENERALE"},
  {id: "7538", value: "ENTRETIEN ET REPARATION DE VEHICULES AUTOMOBILES"},
  {id: "7622", value: "REPARATION DE MATERIEL ELECTRONIQUE GRAND PUBLIC"},
  {id: "7629", value: "REPARATION D'AUTRES ARTICLES ELECTRIQUES A USAGE D"},
  {id: "7629", value: "REPARATIONS DIVERSES"},
  {id: "7629", value: "REPARATION D'ARTICLES PERSONNELS ET DOMESTIQUES NC"},
  {id: "7631", value: "HORLOGERIE"},
  {id: "7631", value: "REPARATION DE MONTRES, HORLOGES ET BIJOUX"},
  {id: "7829", value: "PRODUCTION DE PROGRAMMES DE TELEVISION"},
  {id: "7829", value: "ACTIVITES DE RADIO"},
  {id: "7829", value: "EDITION ET DISTRIBUTION VIDEO"},
  {id: "7829", value: "DISTRIBUTION DE FILMS CINEMATOGRAPHIQUES"},
  {id: "7829", value: "PRESTATIONS TECHNIQUES POUR LE CINEMA ET LA TELEVI"},
  {id: "7829", value: "PRODUCTION DE FILMS POUR LE CINEMA"},
  {id: "7829", value: "PRODUCTION DE FILMS INSTITUTIONNELS ET PUBLICITAIR"},
  {id: "7829", value: "PRODUCTION DE FILMS POUR LA TELEVISION"},
  {id: "7829", value: "REPRODUCTION D'ENREGISTREMENTS VIDEO"},
  {id: "7832", value: "PROJECTION DE FILMS CINEMATOGRAPHIQUES"},
  {id: "7911", value: "ACTIVITES ARTISTIQUES"},
  {id: "7922", value: "GESTION DE SALLES DE SPECTACLES"},
  {id: "7941", value: "TELEPHERIQUES, REMONTEES MECANIQUES"},
  {id: "7991", value: "GESTION DU PATRIMOINE NATUREL"},
  {id: "7991", value: "AUTRES ACTIVITES RECREATIVES"},
  {id: "7991", value: "GESTION D'INSTALLATIONS SPORTIVES"},
  {id: "7991", value: "SERVICES ANNEXES AUX SPECTACLES"},
  {id: "7991", value: "ACTIVITES DIVERSES DU SPECTACLE"},
  {id: "7995", value: "JEUX DE HASARD ET D'ARGENT"},
  {id: "7996", value: "MANEGES FORAINS ET PARCS D'ATTRACTIONS"},
  {id: "7997", value: "AUTRES ACTIVITES SPORTIVES"},
  {id: "8011", value: "PRATIQUE MEDICALE"},
  {id: "8021", value: "PRATIQUE DENTAIRE"},
  {id: "8043", value: "FAB. D'INSTRUMENTS D'OPTIQUE ET DE MATERIEL PHOTOG"},
  {id: "8043", value: "FABRICATION DE LUNETTES"},
  {id: "8050", value: "ACTIVITES DES AUXILAIRES MEDICAUX"},
  {id: "8062", value: "ACTIVITES HOSPITALIERES"},
  {id: "8071", value: "LABORATOIRES D'ANALYSES MEDICALES"},
  {id: "8099", value: "CENTRES DE COLLECTE ET BANQUES D'ORGANES"},
  {id: "8099", value: "ACCUEIL DES PERSONNES AGEES"},
  {id: "8099", value: "SOINS HORS D'UN CADRE REGLEMENTE"},
  {id: "8099", value: "ACTIVITES THERMALES ET DE THALASSOTHERAPIE"},
  {id: "8111", value: "ACTIVITES JURIDIQUES"},
  {id: "8211", value: "ENSEIGNEMENT SECONDAIRE GENERAL"},
  {id: "8211", value: "ENSEIGNEMENT PRIMAIRE"},
  {id: "8220", value: "ENSEIGNEMENT SUPERIEUR"},
  {id: "8249", value: "ENSEIGNEMENT SECONDAIRE TECHNIQUE OU PROFESSIONNEL"},
  {id: "8299", value: "AUTRES ENSEIGNEMENTS"},
  {id: "8299", value: "FORMATION DES ADULTES ET FORMATION CONTINUE"},
  {id: "8299", value: "ECOLES DE CONDUITE"},
  {id: "8351", value: "CRECHES ET GARDERIES D'ENFANTS"},
  {id: "8398", value: "AIDE A DOMICILE"},
  {id: "8641", value: "AIDE PAR LE TRAVAIL, ATELIERS PROTEGES"},
  {id: "8641", value: "AUTRES HEBERGEMENTS SOCIAUX"},
  {id: "8641", value: "AUTRES FORMES D'ACTION SOCIALE"},
  {id: "8641", value: "ORGANISATIONS ASSOCIATIVES NCA"},
  {id: "8641", value: "SYNDICATS DE SALARIES"},
  {id: "8641", value: "ORGANISATIONS PROFESSIONNELLES"},
  {id: "8641", value: "ORGANISATIONS PATRONALES ET CONSULAIRES"},
  {id: "8641", value: "ACCUEIL DES ADULTES HANDICAPES"},
  {id: "8641", value: "ACCUEIL DES ENFANTS EN DIFFICULTE"},
  {id: "8641", value: "ACCUEIL DES ENFANTS HANDICAPES"},
  {id: "8641", value: "DISTRIBUTION SOCIALE DE REVENUS"},
  {id: "8641", value: "GESTION DES RETRAITES COMPLEMENTAIRES"},
  {id: "8641", value: "ACTIVITE GENERALE DE SECURITE SOCIALE"},
  {id: "8651", value: "ORGANISATIONS POLITIQUES"},
  {id: "8661", value: "ORGANISATIONS RELIGIEUSES"},
  {id: "8734", value: "RECHERCHE DEVELOPPEMENT EN SCIENCES HUMAINES ET SO"},
  {id: "8734", value: "RECHERCHE DEVELOPPEMENT EN SCIENCES PHYSIQUES ET N"},
  {id: "8734", value: "ESSAIS ET ANALYSES TECHNIQUES"},
  {id: "8911", value: "ACTIVITES D'ARCHITECTURE"},
  {id: "8911", value: "INGENIERIE, ETUDES TECHNIQUES"},
  {id: "8911", value: "METREURS, GEOMETRES"},
  {id: "8931", value: "ACTIVITES COMPTABLES"},
  {id: "8931", value: "SUPPORTS JURIDIQUES DE GESTION DE PATRIMOINE"},
  {id: "8931", value: "SUPPORTS JURIDIQUES DE PROGRAMME"},
  {id: "8999", value: "ACTIVITE INDETERMINEE"},
  {id: "9211", value: "JUSTICE"},
  {id: "9399", value: "POLICE"},
  {id: "9399", value: "DEFENSE"},
  {id: "9399", value: "AFFAIRES ETRANGERES"},
  {id: "9399", value: "ACTIVITES DE SOUTIEN AUX ADMINISTRATIONS"},
  {id: "9399", value: "TUTELLE DES ACTIVITES ECONOMIQUES"},
  {id: "9399", value: "TUTELLE DES ACTIVITES SOCIALES"},
  {id: "9399", value: "ADMINISTRATION PUBLIQUE GENERALE"},
  {id: "9399", value: "PROTECTION CIVILE"},
  {id: "9399", value: "AUTRES TRAVAUX D'ASSAINISSEMENT ET DE VOIRIE"},
  {id: "9399", value: "EPURATION DES EAUX USEES"},
  {id: "9399", value: "TRAITEMENT DES AUTRES DECHETS SOLIDES"},
  {id: "9399", value: "ENLEVEMENT ET TRAITEMENT DES ORDURES MENAGERES"},
  {id: "9399", value: "ACTIVITES EXTRA-TERRITORIALES"},
  {id: "9399", value: "GESTION DU PATRIMOINE CULTUREL"},
  {id: "9399", value: "GESTION DES BIBLIOTHEQUES"},
  {id: "9402", value: "POSTES NATIONALES"}
]