<div class="ticket-modale--item">
    <section class="text" style="margin: 2rem;">
        <div class="gestion__input-box cgu_stripe">
            <mat-checkbox id="tos_acceptance" [formControl]="tos_acceptance" (change)="toggleTos($event)"></mat-checkbox>  
            <label for="tos_acceptance">Vous acceptez notre Contrat d’utilisation du service et le <a href="https://stripe.com/fr/connect-account/legal" target="_blank">Contrat de comptes connectés Stripe.</a></label>
        </div>
    </section>
    <div class="button_container">
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="validateTos()" [disabled]="buttonDisabled">  
            Valider 
        </button>   
    </div> 
</div>