<div class="gestion__block--attributes">
  <div class="gestion__block large-6">
      <div class="gestion__input-box">
          <label for="product-tax">Catégorie</label>
          <mat-form-field class="gestion__input">
            <mat-select 
              id="product-attributes" 
              multiple
              [formControl]="attributes"
              (selectionChange)="changeSelect($event)"
            >
              <!-- <mat-option *ngFor="let opt of availableAttributes" [value]="opt.value">{{opt.label}}</mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
  </div>
  <div class="gestion__block large-6">
    <div class="gestion__input-box">
        <label for="product-tax">Allergènes</label>
        <mat-form-field class="gestion__input">
          <mat-select 
            id="product-allergenes" 
            multiple
            [formControl]="allergenes"
            (selectionChange)="changeSelect($event)"
          >
            <!-- <mat-option *ngFor="let opt of availableAllergenes" [value]="opt.value">{{opt.label}}</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
  </div>
</div>
