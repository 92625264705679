<div class="ticket-modale--item">
    <section class="text">
        <div class="image_container">
            <img src="assets/images/deco/attention_blue.png.png" alt="">
        </div>
        <p>
            Lorsqu'une commande est en attente, celle-ci est en attente de l'acceptation de tout les commerçants concernés.
        </p>

        <p>
            Afin d'éviter une prise en charge inutile, ne préparez la commande que lorsque celle-ci change de status pour "En préparation".
        </p>
    </section>
    <div class="button_container">
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="closeModal()">
            J'ai compris
        </button>
    </div>
</div>