import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Commande } from 'src/app/models/commande';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { setShowModalMultiboutiqueFirst } from 'src/app/reducers/settings/settings.action';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';

@Component({
  selector: 'multiboutique-ready-modal',
  templateUrl: './multiboutique-ready-modal.component.html',
  styles: []
})
export class MultiboutiqueReadyModalComponent implements OnInit {
  subscription: Subscription;
  checked = false;

  constructor(
    public router: Router,
    public store: Store,
    public commandeService: CommandesService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MultiboutiqueReadyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Observable<Commande>
    ) { }

  ngOnInit() {
    
  }

  toggleModalTicket(ev: MatCheckboxChange) {
    const {checked} = ev
    this.checked = checked    
  }

  closeModal() {
    //Si l'on a choché ne plus afficher alors on stock dans le redux le fait de ne plus afficher la modale
    if(this.checked)
      this.store.dispatch(setShowModalMultiboutiqueFirst()) 
    this.dialogRef.close();
    this.commandeService.refreshAccepted()
  }
}
