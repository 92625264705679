<div class="gestion__container">
  <div class="gestion__header">
    <div class="gestion__row">
      <a mat-icon-button class="back_button" routerLink="/gestion" routerLinkActive="active"></a>
      <div class="titles">
        <h2>Configuration boutique</h2>
        <span>{{shopName$ | async}}</span>
      </div>
    </div> 
    <div class="gestion__row filters">
      <div class="sort">
        <a href="#!" [ngClass]="{'active': currentTab === 'boutique'}" (click)="changeTab($event, 'boutique')">
          Boutique
        </a>
        <a href="#!" [ngClass]="{'active': currentTab === 'horaires'}" (click)="changeTab($event, 'horaires')">
          Horaires
        </a>
        <a href="#!" [ngClass]="{'active': currentTab === 'online'}" (click)="changeTab($event, 'online')">
          Commandes en ligne
        </a>
      </div>
    </div>
  </div>  
  <div class="gestion__body">
      <div class="gestion__row">
        <div class="shop__container">
          <div [hidden]="currentTab !== 'boutique'">
            <app-boutique></app-boutique>
          </div>
          <div [hidden]="currentTab !== 'horaires'">
            <app-horaires></app-horaires>
          </div>
          <div [hidden]="currentTab !== 'online'">
            <app-online></app-online>
          </div>
        </div>
      </div>
  </div>
</div>