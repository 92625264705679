<div class="collab-modale--item">
    <section class="item--head">
        <button mat-icon-button class="close__modale" (click)="closeModal()"></button>
    </section>
    <ng-container *ngIf="(profil$ | async)">
        <form class="collab-edit" (ngSubmit)="onSubmit()" [formGroup]="editForm">
          <div class="image__container">
            <label for="uploadImage" class="uploadImageButton"></label>
            <input 
              id="uploadImage" 
              type="file" 
              formControlName="image"
              (change)="fileChangeEvent($event)" 
              class="uploadImageInput" 
              placeholder="Yes"
            >
            <div class="img" [style.backgroundImage]="'url('+image+')'"></div>
            <span class="imageError">{{imageError}}</span>
          </div>
          <mat-form-field>
            <mat-label>Nom</mat-label>
            <input matInput type="text" formControlName="name">
          </mat-form-field>
          <button mat-flat-button color="primary" type="submit" class="save" [disabled]="editForm.invalid" *ngIf="edit">
            <mat-icon aria-hidden="true" class="ok">done</mat-icon>Sauvegarder modif.
          </button>
          <button mat-flat-button color="primary" type="submit" class="save" [disabled]="editForm.invalid" *ngIf="!edit">
            <mat-icon aria-hidden="true" class="ok">done</mat-icon>Ajouter le profil
          </button>
          <button type="button"  mat-flat-button *ngIf="edit && !(myProfil() | async)" color="secondary" class="delete" (click)="delete()">
            Supprimer le profil
          </button> 
        </form>
    </ng-container> 
</div>