import { AppState } from '..';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { CommandeState, Commande, CommandeDate } from 'src/app/models/commande';
import * as moment from 'moment';


const _selectCommande = createFeatureSelector<AppState, CommandeState>('commande');


/**
 * Notification sonore
 * @return boolean
 */
export const selectCommandeSound = createSelector(
  _selectCommande,
  (state: CommandeState) => state.sound
);


/**
 * Les commandes en préparation (demain et + // C&C + livraison)
 * @return CommandeDate[]
 */
 export const selectCommandeAccepted = createSelector(
  _selectCommande,
  (state: CommandeState) => {
    // Les commandes accepted sauf :
    // - la date du jour
    // - commandes colissimo
    // - commandes en attente

    let commandesDate: CommandeDate[] = []

    state.accepted.forEach(com => {
      // pas aujourd'hui
      if( !moment().isSame(com.date, 'day') ){
        com.orders.forEach(order => {
          // si pas status en attente
          if(order.status !== 'pending_validation'){
            // si pas de type colissimo
            if(order.delivery_type !== "colissimo") {
              // On check si la date n'existe pas déjà
              const indexDate: number = commandesDate.findIndex(el => el.date === com.date)
              if(indexDate === -1){
                // Nouvelle date: On ajoute à la suite de notre tableau 'CommandeDate'
                commandesDate.push({
                  date: com.date,
                  total: null, // inutile pour les commande "en cours"
                  orders: [order]
                  
                })
              }
              else {
                // Date déjà existante:
                // On ajoute la nouvelle commande
                // On trie l'heure des commandes en même temps
                commandesDate = [
                  ...commandesDate.slice(0,indexDate),
                  {
                    ...commandesDate[indexDate], 
                    orders: [...commandesDate[indexDate].orders, order ]
                  },
                  ...commandesDate.slice(indexDate+1),
                ]
              }
            }
          }
        })
      }
    })

    return commandesDate;
  }
);

/**
 * Les commandes en préparation (aujourd'hui + Colissimo)
 * @return CommandeDate[]
 */
export const selectCommandeAcceptedBefore = createSelector(
  _selectCommande,
  (state: CommandeState) => {
    // Les commandes accepted sauf :
    // - la date du jour
    // - commandes collisimo
    // - commandes en attente

    let commandeDate: CommandeDate = {
      date: moment().format('YYYY-MM-DD'),
      total: null,
      orders: []
    };
    
    state.accepted.forEach(com => {
      // Si aujourd'hui
      if( moment().isSame(com.date, 'day') ) {
        com.orders.forEach(order => {
          // si pas status en attente (global_status => multicommandes) ou que c'est une commande partielle
          if((order.status !== 'pending_validation' && order.global_status !== 'pending_validation' && order.status != "canceled") || order.is_partial == "1"){
            commandeDate.orders = [...commandeDate.orders, order];  
          }
        })
      }
      else {
        com.orders.forEach(order => {
          // si type colissimo (vu que pas de date)
          if(order.delivery_type === "colissimo") {
            commandeDate.orders = [...commandeDate.orders, order];
          }
        })
      }
    })

    return [commandeDate]
  }
);

/**
 * Les commandes en attente (aujourd'hui + Colissimo)
 * @return CommandeDate[]
 */
export const selectCommandeAcceptedWaiting = createSelector(
  _selectCommande,
  (state: CommandeState) => {
    let commandeDate: CommandeDate = {
      date: null,
      total: null,
      orders: []
    };
    
    state.accepted.forEach(com => {
      com.orders.forEach(order => {
        if(
          order.status === 'pending_validation' 
          || (order.global_status === 'pending_validation' && order.sub_orders_related.length > 0 && order.is_partial == "0") 
          || (order.status === 'canceled' && order.sub_orders_related.length > 0 && order.is_partial == "0") 
        ){
          commandeDate.orders = [...commandeDate.orders, order];
        }
      })
    })

    return [commandeDate]
  }
);

/**
 * Les commandes en attente
 * @return Commande[]
 */
 export const selectCommandePending = createSelector(
  _selectCommande,
  (state: CommandeState) => {
    // on transforme le resultat regroupé par date en un tableau contenant toutes les commandes
    let orders: Commande[] = []
    state.pending.forEach(el => {
      orders = [...orders, ...el.orders]
    })
    return orders
  }
);

/**
 * Les commandes archivées dans historique
 * @retur CommandeDate[]
 */
 export const selectCommandeArchived = createSelector(
  _selectCommande,
  (state: CommandeState) => state.archived
);

/**
 * Les commandes dans la modale
 * @return Commande[]
 */
 export const selectCommandeShowInModal = createSelector(
  _selectCommande,
  (state: CommandeState) => {
    // on transforme le resultat regroupé par date en un tableau contenant toutes les commandes
    let orders: Commande[] = []
    state.showInModal.forEach(el => {
      orders = [...orders, ...el.orders]
    })
    return orders
  }
);

/**
 * La commande de départ à afficher dans la modale
 * @return string
 */
export const selectCommandeStartIdSlider = createSelector(
  _selectCommande,
  (state: CommandeState) => state.idStartInModal
);

/**
 * Nombre de commande aujourd'hui
 * @return number
 */
export const selectCommandeToday = createSelector(
  _selectCommande,
  (state: CommandeState) => state.info.nb_received_orders_today
);
 
/**
 * Nombre de commande cette semaine
 * @return number
 */
export const selectCommandeLastWeek = createSelector(
  _selectCommande,
  (state: CommandeState) => state.info.nb_received_orders_last_week
);

/**
 * Temps de preparation de commande
 * @return string
 */
export const selectCommandePreparationTime = createSelector(
  _selectCommande,
  (state: CommandeState) => state.info.preparation_time
);

/**
 * Temps de preparation de commande
 * @return string
 */
export const selectCommandeDeliveryTime = createSelector(
  _selectCommande,
  (state: CommandeState) => state.info.delivery_time
);

/**
 * Recherche de commande avec l'id et le type
 * @return Commande
 */
export const selectCommandeByIdAndType = createSelector(
  _selectCommande,
  (state: CommandeState, props:{id: string, type: string} ) => {
    // on créé un tableau 'simple' avec les tableaux de comandes regroupées par date
    let orders: Commande[] = []
    state[props.type].forEach((el: CommandeDate) => {
      orders = [...orders, ...el.orders]
    })
    const order = orders.find(elem => elem.sub_order_id === props.id)
    return order
  }
);