<div class="gestion__container">
  <div class="gestion__header">
      <div class="gestion__row">
          <a mat-icon-button class="back_button" routerLink="/catalog"></a>
          <div class="titles">
              <h2>Ajouter un produit</h2>
              <span>{{shopName$ | async}}</span>
          </div>
      </div>
  </div>  
  <div class="gestion-home gestion__body">
    <div class="gestion-home__container">
      <a routerLink="./simple" class="gestion-home__vignette product_indiv">
        <div class="gestion-home__text">
          Produit<br/>
           individuel
        </div>
      </a>
      <!-- <a routerLink="./configurable" class="gestion-home__vignette product_config">
        <div class="gestion-home__text">
          Produit<br/>
           configurable
        </div>
      </a>
      <a routerLink="./pack" class="gestion-home__vignette product_pack">
        <div class="gestion-home__text">
          Pack de<br/>
           produits
        </div>
      </a> -->
    </div>
  </div>
</div>