import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'src/app/utils/message/message.service';
import { Store, select } from '@ngrx/store';
import { setIp } from 'src/app/reducers/settings/settings.action';
import { take } from 'rxjs/operators';
import { selectSettingsPrintIP } from 'src/app/reducers/settings/settings.selector';
import { PrintService } from 'src/app/utils/print/print.service';
import { ipValidator } from 'src/app/utils/validator/validators.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styles: [
  ]
})
export class SettingsComponent implements OnInit {
  settingsForm: FormGroup;
  ip = new FormControl('', [Validators.required, ipValidator()]);
  step = 0;
  testRappel: HTMLAudioElement = new Audio('/assets/sounds/rappel.mp3');
  testNotif: HTMLAudioElement = new Audio('/assets/sounds/notif.mp3');

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private store: Store,
    public printService: PrintService,
    private route: ActivatedRoute
  ) { 
    this.step = parseInt(this.route.snapshot.queryParams.step) || 0
  }

  ngOnInit(): void {
    this.settingsForm = this.fb.group({
      ip: this.ip,
    })
    this.store.pipe(
      take(1),
      select(selectSettingsPrintIP)
    ).subscribe( ipAdress => this.ip.setValue(ipAdress))
  }
  
  onSubmit(): void {
    // Met à jour l'imprimante
    this.store.dispatch(setIp({ip: this.ip.value}))
  }
  
  getErrorMessage(control: FormControl): string {
    return this.messageService.getErrorMessage(control);
  }

  reload(): void{
    window.location.reload()
  }

  disconnect(): void {
    this.printService.disconnect()
  }
  connect(): void {
    this.store.dispatch(setIp({ip: this.ip.value}))
    this.printService.connect()
  }
  testPrint(): void {
    this.printService.testPrint();
  }

  testSound() : void {
    this.testNotif.pause();
    this.testNotif.currentTime = 0;
    this.testNotif.play();
  }

  testSoundWarning() : void {
    this.testRappel.pause();
    this.testRappel.currentTime = 0;
    this.testRappel.play();
  }

  
  setStep(index: number): void {
    this.step = index;
  }
}
