import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/utils/message/message.service';
import { StripeService } from 'src/app/utils/stripe/stripe.service';

@Component({
  selector: 'stripe-externe',
  templateUrl: './stripe-externe.component.html',  
  host: {
    'class': 'stripe-home__wrapper'
  }
})
export class StripeExterneComponent implements OnInit {
  loader: boolean = false;
  isRequesting: boolean = false;
  formGroup: FormGroup;
  account_holder_name = new FormControl('', Validators.required);
  account_holder_type = new FormControl('', Validators.required);
  country = new FormControl('', Validators.required);
  account_number = new FormControl('', Validators.required);
  
  @Input() data: any

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private stripeService: StripeService
  ) { }

  ngOnInit(): void {
    // Construction du formulaire
    this.account_holder_name.setValue(this.data?.account_holder_name)
    this.account_holder_type.setValue(this.data?.account_holder_type)
    this.country.setValue(this.data?.country)
    if(this.data?.last4)
      this.account_number.setValue('***********************'+this.data.last4)
    else this.account_number.setValue('')

    this.formGroup = this.formBuilder.group({
      account_holder_name: this.account_holder_name,
      account_holder_type: this.account_holder_type,
      country: this.country,
      account_number: this.account_number,
    })
  }

  changeInputText() {
    this.data = {...this.data, ...this.formGroup.value} 
  }

  changeSelect(e) {
    this.data = {...this.data, ...this.formGroup.value}
  }


  saveForm() {
    let payload = this.data;
    console.log("🚀 ~ file: stripe-externe.component.ts ~ line 58 ~ StripeExterneComponent ~ saveForm ~ payload", payload)
    const {idAccount} = payload
    //On vérifie si tous les champs sont remplis, sont corrects et si l'on n'est pas déjà en requete pending
    if(this.formGroup.valid && !this.isRequesting){
      if(payload.idExternalAccount){
        this.isRequesting = true;
        this.stripeService.updateStripeExtern(idAccount, payload).subscribe(_ => {
          this.messageService.openSnackBar('Profil sauvegardé avec succès', 'success')
        }, error => {
          this.messageService.openSnackBar('Echec lors de la sauvegarde du profil', 'error')
        }, () => {
          this.isRequesting = false; 
        });
      }else{
        this.isRequesting = true;
        this.stripeService.createStripeExtern(idAccount, payload).subscribe(_ => {
          this.messageService.openSnackBar('Profil sauvegardé avec succès', 'success')
        }, error => {
          this.messageService.openSnackBar('Echec lors de la sauvegarde du profil', 'error')
        }, () => {
          this.isRequesting = false; 
        });
      }
    }else{
      this.messageService.openSnackBar('Veuillez renseigner tous les champs.', 'error')
    }
  }
}
