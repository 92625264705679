import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class RefundService {
  constructor(
    private httpService: HttpService,
  ) {}

  demandeRemboursement(payload: any): Observable<any> {    
    return this.httpService.apiRequest('post', `insitaction-refund/refund`, payload)
  }

  getRefunds(): Observable<any> {    
    return this.httpService.apiRequest('get', `insitaction-refund/refund`)
  }

  cancelRefund(refund_id: number): Observable<any> {    
    return this.httpService.apiRequest('post', `insitaction-refund/cancel/${refund_id}`, {})
  }
}