import { Injectable } from '@angular/core';
import { Commande } from 'src/app/models/commande';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { zip, Subject } from 'rxjs';
import { take, map } from 'rxjs/operators';
import * as moment from 'moment';
import { selectSettingsPrintIP } from 'src/app/reducers/settings/settings.selector';
import { selectProfilShop } from 'src/app/reducers/profil/profil.selector';
import { selectCommandeDeliveryTime } from 'src/app/reducers/commande/commande.selector';
import { MessageService } from '../message/message.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PrintService {  
  private printer:any = null;
  private ePosDev:any = new epson.ePOSDevice();
  private ctx: any;

  private printSubject: Subject<Commande[]> = new Subject()
  private cmdTemp: Commande[] = [];
  private isPrinting: boolean = false;

  public status: "disconnected" | "pending" | "connected" = "disconnected";

  constructor(
    private store: Store,
    private messageService: MessageService,
    public router: Router
  ) {
    this._createCanvasLogo();
    
    // Connexion à l'imprimante
    this.store.pipe(
      select(selectSettingsPrintIP)
    ).subscribe( ipAddress => {
      this._connect(ipAddress);
    })

    // On écoute les commandes à imprimer
    this.printSubject.subscribe( (cmds) => {
      if (this.isConnected()) {
        this.isPrinting = true;
        cmds.forEach(cmd => {
          this._createData(cmd);
        })
        
        setTimeout(() => {
          this._send();
        }, 1000);
      }
      else{
        switch (this.status) {
          case "disconnected":
            this.messageService.openSnackBar(
              'Aucune imprimante connectée. Vérifier vos paramètres', 
              'error',
              5000,
              'Configuration',
              () => this.router.navigate(['/settings'], { queryParams: { step: 1 }})
            )
            break;
          case "pending":
            this.messageService.openSnackBar(
              'Imprimante en cours de connexion, veuillez réssayer dans quelques secondes', 
              'error', 
              5000,
            )
            break;
        }
      }
    })
  }


  /**
   * Print
   */
  print(cmd: Commande, repeat:number = 1):void {
    // transforme la commande en array de commande en fonction du nombre de répétition
    let cmds = []
    for (let index = 0; index < repeat; index++) {
      cmds = [...cmds, cmd]
    }

    // On n'imprime rien en ce moment
    if(!this.isPrinting && cmds.length){
      this.printSubject.next(cmds);
    }
    // On n'imprime déjà quelque chose en ce moment
    else {
      this.cmdTemp = [...this.cmdTemp, ...cmds]
    }
  }

  /**
   * Créer un canvas pour l'impression du logo
   */
  private _createCanvasLogo():void {
    let canvas, body, source: any
    body = document.getElementsByTagName("body")[0];

    // création canvas
    canvas = document.createElement('canvas');
    canvas.width = 246;
    canvas.height = 122;
    canvas.style.position = "absolute";
    canvas.style.left = "-9999px";
    canvas.style.opacity = 0;
    body.appendChild(canvas);

    this.ctx = canvas.getContext("2d");

    // création image logo
    source = new Image();
    source.width = 246;
    source.height = 122;
    source.style.position = "absolute";
    source.style.left = "-9999px";
    source.style.opacity = 0;
    body.appendChild(source);
    source.onload = () => {
      this.ctx.drawImage(source, 0, 0)
    };
    source.src = "/assets/images/logo-print.jpg";
  }

  /**
   * Intégration du ticket de test
   */
  private _createData = (cmd:Commande):void => {
    // On récupère les info du shop et du temps de livraison
    const shop$ = this.store.select(selectProfilShop)
    const deliverytime$ = this.store.select(selectCommandeDeliveryTime)
    zip(shop$, deliverytime$).pipe(
      take(1),
      map(([shop, deliveryTime]) => ({shop, deliveryTime: parseInt(deliveryTime)}))
    ).subscribe(
      ({shop, deliveryTime}) => {
        // Impression logo
        this.printer.addTextAlign(this.printer.ALIGN_CENTER);
        this.printer.addImage(this.ctx, 0, 0, 246, 122, this.printer.COLOR_1, this.printer.MODE_MONO)
        this.printer.addFeedLine(2);

        const isLivraison = cmd.delivery_type === "home_delivery"
        const isColissimo = cmd.delivery_type === "colissimo";
    
        // Type de retrait
        let deliveryWord:string; 
        if(isLivraison){
          deliveryWord = "LIVRAISON"
        }
        else if(isColissimo){
          deliveryWord = "COLISSIMO"
        }else{
          deliveryWord = "CLICK&COLLECT"
        }
        this.printer.addTextSize(3,3)
        this.printer.addText(deliveryWord);
        this.printer.addFeedLine(1);
        this._addDottedLine();
    
        // Numéro de commande + date
        this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
        this.printer.addText(`COMMANDE: ${cmd.reference}`)
        this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
        // date calculé avec le temps de livraison
        const date = isLivraison ? moment(cmd.delivery_date).subtract(deliveryTime, 'minutes') : moment(cmd.delivery_date)
        this.printer.addFeedLine(2)
        this.printer.addText(`pour le ${date.format("DD/MM/YYYY à HH:mm")}`);
        this._addDottedLine();
    
        // Info Client + Adresse de livraison 
        this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
        this.printer.addText(`${cmd.address.customer_firstname} ${cmd.address.customer_lastname.slice(0,1).toUpperCase()}.`)
        this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
        this.printer.addFeedLine(2);
        
        if(cmd.address.street_line_1){
          this.printer.addText(cmd.address.street_line_1)
          this.printer.addFeed()
        }
        if(cmd.address.street_line_2){
          this.printer.addText(cmd.address.street_line_2)
          this.printer.addFeed()
        }
        this.printer.addText(`${cmd.address.postcode} ${cmd.address.city}`)
        this.printer.addFeedLine(1)
        this._addDottedLine(false);

        // Récap commande
        // menus
        if(cmd.items.menus.length){
          this.printer.addFeedLine(2)
          this.printer.addText("MENU")
          this.printer.addFeed()
          cmd.items.menus.forEach(element => {
            const left = this._addBlank(element.qty) + this.truncateString(element.name, 35)
            const right = element.total + " €"  
            this._addSpaceBetweenLine(left, right)
            element.bundle_info.forEach(elem => {
              this._addSpaceBetweenLine(`       ${this._addBlank(elem.type)}`, ``)
              elem.choices.forEach(el => {
                this._addSpaceBetweenLine(`          ${this._addBlank(el.qty.toString())}${el.name}`, ``)
              })
            })
          });
        }
        // categories
        if(cmd.items.categories.length){
          cmd.items.categories.forEach(element => {
            this.printer.addFeedLine(2)
            this.printer.addTextAlign(this.printer.ALIGN_CENTER);
            this.printer.addText(element.type.toUpperCase())
            this.printer.addFeed()
            element.choices.forEach(elem => {
              const left = this._addBlank(elem.qty) + this.truncateString(elem.name, 35)
              const right = elem.total + " €"
              this._addSpaceBetweenLine(left, right)
              if(elem.config.length > 0){
                elem.config.forEach(el => {
                  this._addSpaceBetweenLine(`       ${this._addBlank(el)}`, ``)
                })
              }
            })
          });
        }


        this._addDottedLine();
        // Réduction / Remise / Code Promo ?
        if(this._convertPriceToNumber(cmd.discount_amount) > 0){
          this._addSpaceBetweenLine("Remise(s)", `${cmd.discount_amount} €`)
        }

        // Frais de livraison
        // if(isLivraison){
        //   this._addSpaceBetweenLine("Frais de livraison", `${cmd.shipping_amount} €`)
        // }
        
        // Total
        this._addSpaceBetweenLine("Montant total", `${cmd.total} €`)
        this._addSpaceBetweenLine(`hors frais de livraison`, ``)
    
        // Adresse restaurant
        this._addDottedLine();
        this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
        this.printer.addText(shop.name.toUpperCase())
        this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
        this.printer.addFeed()
        if(shop.street_line_1){
          this.printer.addText(shop.street_line_1)
          this.printer.addFeed()
        }
        if(shop.street_line_2){
          this.printer.addText(shop.street_line_2)
          this.printer.addFeed()
        }
        this.printer.addText(`${shop.postcode} ${shop.city}`.toUpperCase())
        
        if(shop.phone){
          this.printer.addFeed()
          this.printer.addText(shop.phone)
        }
        this._addDottedLine();

        // Réassurance facture
        this.printer.addText(`Ce ticket n'est pas une facture\nRécapitulatif de commande disponible sur votre\nespace en ligne`)
    
        // Fin du ticket
        this.printer.addFeedLine(3);
        this.printer.addCut();
      }
    )
  }

  /**
   * Ajoute une ligne pointillé
   */
  private _addDottedLine(space = true):void{
    this.printer.addFeedLine(space ? 2 : 1);
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addTextSize(1,1)
    let dotted = "."
    for (let index = 0; index < 40; index++) {
      dotted += ".";
    }
    this.printer.addText(dotted);
    this.printer.addFeedLine(space ? 2 : 1);
  }


  /**
   * Ajoute une ligne avec un text à gauche et un texte à droite
   */
  private _addSpaceBetweenLine(left:string, right:string ,gap = 4):void {
    // 48 caractères max par ligne
    const maxByLine = 48;
    const leftLength = left.length;
    const rightLength = right.length;

    this.printer.addTextAlign(this.printer.ALIGN_LEFT);

    // Cas où il y a assez de place pour tout mettre sur une ligne
    if(leftLength + rightLength + gap < maxByLine) {
      this.printer.addText(left)
      let blank = "";
      const blankLength = maxByLine - leftLength - rightLength
      for (let index = 0; index < blankLength; index++) {
        blank += " ";
      }
      this.printer.addText(blank)
      this.printer.addText(right)
    } else {
      // Cas où le texte à droite passe à la ligne
      if( ((leftLength + gap) % maxByLine) > (maxByLine - rightLength)){
        this.printer.addText(left)
        this.printer.addFeed();
        this.printer.addTextAlign(this.printer.ALIGN_RIGHT);
        this.printer.addText(right);
        this.printer.addFeed();
      }
      // Cas où le texte à gauche passe sur deux lignes
      else { 
        this.printer.addText(left)
        let blank = "";
        const blankLength = maxByLine - (leftLength % maxByLine) - rightLength
        for (let index = 0; index < blankLength; index++) {
          blank += " ";
        }
        this.printer.addText(blank)
        this.printer.addText(right)
      }
    }

    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
  }

  // 
  private truncateString(str:string, num:number) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  /**
   * Ajoute des espace blanc pour avoir le nombre total de caractère souhaité
   */
  private _addBlank(text:string, total = 4, align = "left"):string {
    let blank = "";
    if(text.length < total){
      for (let index = 0; index < total - text.length; index++) {
        blank += " ";
      }
    }
    return align === "left" ? text + blank : blank +text
  }

  /**
   * Convertit un prix string en nombre
   */
  _convertPriceToNumber(price:string):number {
    return parseFloat(price.replace(",","."))
  }


  /**
   * Envoie pour impression
   */
  private _send = () => {
    this.printer.send();
  }


  /**
   * Etablir la connexion avec l'imprimante
   */
  public connect = ():void => {
    this.store.pipe(
      take(1),
      select(selectSettingsPrintIP)
    ).subscribe( ipAddress => {
      this._connect(ipAddress);
    })
  }
  private _connect = (ipAddress: string):void => {
    this.status = "pending"
    const port = environment.port;
    this.ePosDev.connect(ipAddress, port, this._callback_connect);
  }
  public isConnected = ():boolean => {
    return (this.status === "connected")
  }
  


  /**
   * Callback de la connexion à l'imprimante
   * On crée l'objet qui va nous servir à imprimer
   */
  private _callback_connect = (resultConnect:any):void => {
    const deviceId = 'local_printer';
    const options = {'crypto' : false, 'buffer' : false};

    if ((resultConnect == 'OK') || (resultConnect == 'SSL_CONNECT_OK')) {
      this.ePosDev.createDevice(deviceId, this.ePosDev.DEVICE_TYPE_PRINTER, options, this._callback_createDevice);
    }
    else {
      this.status = "disconnected"
     //Displays error messages
    }
  }


  /**
   * Callback de la création de l'objet imprimante
   * On écoute la réception de données par l'imprimante pour pouvoir afficher des messages de succès / erreur
   */
  private _callback_createDevice = (deviceObj:any , errorCode:any ):void =>{
    if (deviceObj === null) {
      this.status = "disconnected"
      return;
    }
    this.printer = deviceObj;
    this.status = "connected"
    
    // On écoute l'impression d'une commande
    this.printer.onreceive = (response) => {
      this.isPrinting = false;
      if (response.success) {
        let cmds = [...this.cmdTemp]
        this.cmdTemp = []
        if(cmds.length){
          this.printSubject.next(cmds)
        }
      }
      else {
        this.status = "disconnected"
      }
    };

    // On écoute la déconnexion de l'imprimante
    this.ePosDev.ondisconnect = () => {
      this.isPrinting = false;
      this.status = "disconnected"
    };
    // On écoute la reconnexion de l'imprimante
    this.printer.onreconnecting   = () => {
      
    }
    this.printer.onreconnect   = () => {
      this.status = "connected"
    };
  }

  
  /**
   * Déconnexion avec l'imprimante
   */
  public disconnect = () => {
    this.ePosDev.deleteDevice(this.printer, this._callback_deleteDevice);
  }
  private _callback_deleteDevice = (errorCode) => {
    this.ePosDev.disconnect();
  }



  /**
   * Ticket exemple de test
   */
  public testPrint = ():void => {
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addImage(this.ctx, 0, 0, 246, 122, this.printer.COLOR_1, this.printer.MODE_MONO)
    this.printer.addFeedLine(1);
    this._addDottedLine();
    this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
    this.printer.addText(`IMPRESSION TEST`)
    this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
    this.printer.addFeedLine(2)
    this.printer.addText(`effectué le ${moment().format("DD/MM/YYYY à HH:mm")}`);
    this._addDottedLine();
    this.printer.addFeedLine(3);
    this.printer.addCut();
    
    setTimeout(() => {
      this._send();
    }, 1000);
  }

}