import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StripeService } from 'src/app/utils/stripe/stripe.service';
import { MessageService } from 'src/app/utils/message/message.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'stripe-modal',
  templateUrl: './stripe-modal-tos.component.html',
  styles: []
})
export class StripeModalTosComponent implements OnInit {
  checked = false;
  tos_acceptance = new FormControl();
  formGroup: FormGroup;
  buttonDisabled: boolean = true;
  isRequesting: boolean = false;
  ipAddress: string;
  
  constructor(
    public dialogRef: MatDialogRef<StripeModalTosComponent>,
    public store: Store,
    public router: Router,
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private messageService: MessageService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      tos_acceptance: this.tos_acceptance
    })
  }

  toggleTos(ev: MatCheckboxChange) {
    const {checked} = ev
    this.checked = checked    
    this.buttonDisabled = !this.checked 
  }

  validateTos() {
    this.isRequesting = true; 
    this.stripeService.getIp().subscribe((res:any) =>{  
      this.ipAddress=res;  
      this.stripeService.updateStripeAccountTos(this.data, this.ipAddress).subscribe(_ => {
        this.messageService.openSnackBar('Profil sauvegardé avec succès', 'success')
      }, error => {
        this.messageService.openSnackBar('Echec lors de la sauvegarde du profil', 'error')
      }, () => {
        this.isRequesting = false; 
      })
      this.dialogRef.close();
    });  
  }
}
