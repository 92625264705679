import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OnBoardingRoutingModule } from "./on-boarding-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "src/app/material.module";

// Composant
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { AutoLoginComponent } from "./autologin/autologin.component";

@NgModule({
  declarations: [
    LoginComponent,
    AutoLoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    OnBoardingRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
})
export class OnBoardingModule {}
