<div class="row">
  <div class="column small-12 medium-6">
    <div class="gestion__block">
      <div class="gestion__block--title">
        <span>Délai de préparations</span>
        <button mat-button color="primary" type="button"
          (click)="submitDelay()"
          *ngIf="(checkChange() | async)">
          Valider
        </button>
      </div>
      <div class="shop__input-number">
        <button mat-icon-button color="primary" type="button" 
          (mousedown)="removeInput($event)" 
          (mouseleave)="clearTimer()" 
          (mouseup)="clearTimer()"
        >
          <mat-icon>remove</mat-icon>
        </button>
        <form [formGroup]="delayForm">
          <mat-form-field class="gestion__input">
            <input matInput [formControl]="delay" readonly="readonly">
          </mat-form-field>
        </form>
        <button mat-icon-button color="primary" type="button" 
          (mousedown)="addInput($event)" 
          (mouseleave)="clearTimer()" 
          (mouseup)="clearTimer()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div>
        <div class="gestion__block--title">
          <span>Nombre maximum de commandes par créneau </span>
        </div>
        <form [formGroup]="quotaForm" (submit)="submitFormQuota()">
          <mat-form-field class="gestion__input">
            <input matInput [formControl]="quota" type="number" maxlength="2" id="id-quota">
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <div class="column small-12 medium-6">
  </div>
</div>