import { createReducer, on, Action } from '@ngrx/store';
import * as SettingsAction from './settings.action'
import { SettingsState } from 'src/app/models/settings';

export const initialState: SettingsState = {
  ip: "",
  showModalTicket: true,
  showModalMultiboutiqueFirst: true,
};


const _settingsReducer = createReducer(
  initialState,
  on(SettingsAction.setIp, 
    (state, { ip }) => ({...state, ip})), 
  on(SettingsAction.setShowModalTicket,  
    (state) => {
      return ({...state, showModalTicket: false})
    }), 
  on(SettingsAction.setShowModalMultiboutiqueFirst,  
    (state) => {
      return ({...state, showModalMultiboutiqueFirst: false})
    }), 
);

export function settingsReducer(state: SettingsState | undefined, action: Action) {
  return _settingsReducer(state, action);
}