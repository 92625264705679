<div class="selectProfil__container">
    <img class="logo" src='/assets/images/logo-lagalerie.png' />
    <div *ngIf="shopList.length == 0">
        <div class="selectProfil__top">
            <span>{{shopName$ | async}}</span>
        </div>
        <div class="profile-list">
            <div class="profile-list-container">
                <div *ngFor="let profile of profiles; let index = index;" class="profil__item"
                    [class.active]="choiceIndex == index" (click)="setChoiceIndex(index)">
                    <div class="profil__image" [style.backgroundImage]="'url('+(profile.profil_picture)+')'"></div>
                    <span class="profil__name">{{profile.name}}</span>
                    <img src="/assets/images/profilCheck.png" class="profil__check" />
                </div>
            </div>
        </div>
    </div>
    <button *ngIf="shopList.length == 0" mat-flat-button color="primary" (click)="start()" class="button__start">
        Commencer
    </button>
    <div class="select-profile-list" *ngIf="shopList.length > 0">
        <mat-form-field>
            <mat-label>Choisir un magasin</mat-label>
            <input type="text" matInput [formControl]="shopControl" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onChangeShop($event.option)"> 
              <mat-option *ngFor="let shop of filteredShopList" [value]="shop.id">
                {{shop.label}}
              </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <button *ngIf="shopList.length > 0" mat-flat-button color="primary" (click)="validateAdminChoice()" [disabled]="adminShopChoice < 0" class="button__start">
        Valider
    </button>
    <div class="help">
        <span>Help !</span>
        <span class="fs19">{{shopCallCenter$ | async}}</span>
    </div>
    <button class="selectProfil__disconnect" (click)="_logout()">Se déconnecter</button>
</div>