<mat-list-item>
  <button mat-flat-button class="sidebar__line"
    [routerLink]="[ wide ? '/historique' : '/commande', order.sub_order_id]"
    [queryParams]="{
      date: (queryParams$ | async).date, 
      id: (queryParams$ | async).id,
      page: (queryParams$ | async).page 
    }"
    routerLinkActive="active"
  >
    <strong>{{order.reference}}</strong>
    <div class="multiboutique"><span *ngIf="order.sub_orders_related.length > 0">M</span></div>
    <div class="line--price">
      <strong *ngIf="order.delivery_type != 'colissimo'">{{order | preparationHour | async | hour}}</strong>
    </div>
    <category-price [price]="order.total" [type]="order.delivery_type"></category-price>
    <bulle-avatar *ngIf="wide" [img]="order.user_image"></bulle-avatar>
    <bulle-status [wide]="wide" [status]="order.status"></bulle-status>
    <div *ngIf="!wide" class="timer__container" [style.width.%]="timerWidth$ | async"></div>
  </button>
</mat-list-item>