import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PrintService } from 'src/app/utils/print/print.service';

@Component({
  selector: 'catalog-multi-modal',
  templateUrl: './catalog-multi-modal.component.html',
  styles: []
})
export class CatalogMultiModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CatalogMultiModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
    ) { }

  ngOnInit() {
    
  }

  getString() {
    switch (this.data) {
      case 'activer':
        return 'activer'
      case 'desactiver':
        return 'désactiver'
      case 'delete':
        return 'supprimer'
      case 'instock':
        return 'mettre en stock'
      case 'outstock':
        return 'mettre hors stock'
      default:
        break;
    }
  }

  acceptAndCloseModal() {
    this.dialogRef.close(1);
  }

  closeModal() {
    this.dialogRef.close(0);
  }
}
