<div class="row">
  <div class="column small-12 medium-6">
    <div class="gestion__block">
      <div class="gestion__input-box">
        <label for="product-price">Prix *</label>
        <mat-form-field class="gestion__input">
          <span matSuffix class="input-icon">&euro;</span>
          <input 
            matInput
            id="product-price" 
            type="text"
            [formControl]="price"
            (input)="changeInputText()"
          >
        </mat-form-field>
      </div>
      <div class="gestion__input-box">
        <label for="product-special-price">Prix spécial</label>
        <mat-form-field class="gestion__input">
          <span matSuffix class="input-icon">&euro;</span>
          <input 
            matInput
            id="special_price" 
            type="text"
            [formControl]="special_price"
            (input)="changeInputText()"
          >
        </mat-form-field>
      </div>
      <div class="gestion__input-box datepicker">
        <mat-form-field>
          <mat-label>Prix spécial depuis le</mat-label>
          <input 
            matInput 
            [matDatepicker]="picker"
            [formControl]="special_from_date"
            id="special_from_date" 
            (input)="changeInputText()"
            (dateChange)="onSelectDatePicker($event.value)" 
          >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="gestion__input-box datepicker">
        <mat-form-field>
          <mat-label>A</mat-label>
          <input 
            matInput 
            [matDatepicker]="picker2"
            [formControl]="special_to_date"
            id="special_to_date" 
            (input)="changeInputText()"
            (dateChange)="onSelectDatePicker($event.value)" 
          >
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="column small-12 medium-6">
    <div class="gestion__block">
      <div class="gestion__input-box">
        <label for="product-price">Prix de livraison</label>
        <mat-form-field class="gestion__input">
          <span matSuffix class="input-icon">&euro;</span>
          <input 
            matInput
            id="shipping_price" 
            type="text"
            [formControl]="shipping_price"
            (input)="changeInputText()"
          >
        </mat-form-field>
      </div>
      <div class="gestion__input-box">
        <label for="product-price">Prix spécial de livraison</label>
        <mat-form-field class="gestion__input">
          <span matSuffix class="input-icon">&euro;</span>
          <input 
            matInput
            id="special_shipping_price"  
            type="text"
            [formControl]="special_shipping_price"
            (input)="changeInputText()"
          >
        </mat-form-field>
      </div>
      <div class="gestion__input-box datepicker">
        <mat-form-field>
          <mat-label>Prix spécial de livraison depuis le</mat-label>
          <input 
            matInput 
            [matDatepicker]="picker3"
            [formControl]="special_shipping_price_from"
            id="special_shipping_price_from" 
            (input)="changeInputText()" 
            (dateChange)="onSelectDatePicker($event.value)" 
          >
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="gestion__input-box datepicker">
        <mat-form-field>
          <mat-label>A</mat-label>
          <input 
            matInput 
            [matDatepicker]="picker4"
            [formControl]="special_shipping_price_to"
            id="special_shipping_price_to"
            (input)="changeInputText()"
            (dateChange)="onSelectDatePicker($event.value)" 
          >
          <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
          <mat-datepicker #picker4></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <!-- <div class="delete_product_button" *ngIf="isEdit">
    <button type="button"  mat-flat-button color="secondary" class="delete" (click)=deleteProduct(data?.dataConfig?.sku)>
      Supprimer le produit
    </button> 
  </div> -->
</div>

