import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectProfilComponent } from './select-profil/select-profil.component';
import { MaterialModule } from 'src/app/material.module';
import { CommandeModule } from './commande/commande.module';
import { HistoriqueModule } from './historique/historique.module';
import { InAppRoutingModule } from './in-app-routing.module';
import { AppCommandeModaleModule } from 'src/app/_layout/app-commande-modale/app-commande-modale.module';
import { AppLayoutComponent } from 'src/app/_layout/app-layout/app-layout.component';
import { AppHeaderComponent } from 'src/app/_layout/app-header/app-header.component';
import { AppNavigationComponent } from 'src/app/_layout/app-navigation/app-navigation.component';
import { ReportingComponent,   } from './reporting/reporting.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { CollaborateurComponent } from './collaborateur/collaborateur.component';
import { CollaborateurEditComponent } from './collaborateur/collaborateur-edit/collaborateur-edit.component';
import { CollaborateurListComponent } from './collaborateur/collaborateur-list/collaborateur-list.component';
import { SettingsComponent } from './settings/settings.component';
import { HelpPrintComponent } from './settings/help-print/help-print.component';
import { GestionComponent } from './gestion/gestion.component';
import { ShopComponent } from './shop/shop.component';
import { BoutiqueComponent } from './shop/boutique/boutique.component';
import { HorairesComponent } from './shop/horaires/horaires.component';
import { OnlineComponent } from './shop/online/online.component'
import { CatalogComponent } from './catalog/catalog.component';
import { CollabModalComponent } from 'src/app/_components/collab-modals/collab-modal/collab-modal.component';
import { LoaderModule } from 'src/app/_components/loader/loader.module';
import { CatalogAddComponent } from './catalog/add/add.component';
import { CatalogAddSimpleComponent } from './catalog/add/simple/simple.component';
import { CatalogAddConfigurableComponent } from './catalog/add/configurable/configurable.component';
import { CatalogAddPackComponent } from './catalog/add/pack/pack.component';
import { CatalogEditComponent } from './catalog/edit/edit.component';
import { CatalogConfigComponent } from './catalog/components/config/config.component';
import { CatalogMarketComponent } from './catalog/components/market/market.component';
import { CatalogAttributesComponent } from './catalog/components/attributes/attributes.component';
import { CatalogProductsPackComponent } from './catalog/components/products-pack/products-pack.component';
import { CatalogQuantitiesComponent } from './catalog/components/quantities/quantities.component';
import { CatalogPricesComponent } from './catalog/components/prices/prices.component';
import { CatalogImagesComponent } from './catalog/components/images/images.component';
import { CatalogDecliComponent } from './catalog/components/decli/decli.component';
import { RefundComponent } from './refund/refund.component';
import { VirementComponent } from './virement/virement.component';
import { AppMaintenanceComponent } from 'src/app/_layout/app-maintenance/app-maintenance.component';
import { StripeIdentiteComponent } from './stripe/identite/stripe-identite.component';
import { StripeComponent } from './stripe/stripe.component';
import { StripeEntrepriseComponent } from './stripe/entreprise/stripe-entreprise.component';
import { StripeExterneComponent } from './stripe/externe/stripe-externe.component';
import { StripeAttachmentsComponent } from './stripe/attachments/stripe-attachments.component';
import { StripeModalTosComponent } from './stripe/modal-tos/stripe-modal-tos.component';
import { EditSirenModalComponent } from './stripe/entreprise/edit-siren-modal/edit-siren-modal.component';

@NgModule({
   imports: [
      CommonModule,
      ReactiveFormsModule,
      MaterialModule,
      //routing
      InAppRoutingModule,
      CommandeModule,
      HistoriqueModule,
      AppCommandeModaleModule,
      CustomPipeModule,
      LoaderModule
   ],
   declarations: [
      SelectProfilComponent,
      ReportingComponent, 
      CatalogComponent,
      AppLayoutComponent,
      AppMaintenanceComponent,
      AppHeaderComponent,
      AppNavigationComponent,
      CollaborateurComponent,
      CollaborateurEditComponent,
      CollaborateurListComponent,
      CollabModalComponent,
      SettingsComponent,
      HelpPrintComponent,
      GestionComponent,
      StripeComponent,
      StripeIdentiteComponent,
      StripeEntrepriseComponent,
      StripeExterneComponent,
      StripeAttachmentsComponent,
      StripeModalTosComponent,
      EditSirenModalComponent,
      ShopComponent,
      RefundComponent,
      VirementComponent,
      BoutiqueComponent,
      HorairesComponent,
      OnlineComponent,
      CatalogAddComponent,
      CatalogAddSimpleComponent,
      CatalogAddConfigurableComponent,
      CatalogAddPackComponent,
      CatalogEditComponent,
      CatalogConfigComponent,
      CatalogMarketComponent,
      CatalogAttributesComponent,
      CatalogProductsPackComponent,
      CatalogQuantitiesComponent,
      CatalogPricesComponent,
      CatalogImagesComponent,
      CatalogDecliComponent,
   ]
})
export class InAppModule { }
