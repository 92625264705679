import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CatalogProductService } from 'src/app/utils/catalog/catalog-product.service';
import { CatalogAvailableMarketing, CatalogProductDetails } from 'src/app/models/catalog';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/utils/message/message.service';
import { Router } from '@angular/router';

interface DataMarketing  {
  marketing?: string[],
}

@Component({
  selector: 'app-catalog-market',
  templateUrl: './market.component.html',
  styles: [
  ]
})

export class CatalogMarketComponent implements OnInit {
  loader: boolean = true;

  
  availableMarketing: CatalogAvailableMarketing[] = []

  formGroup: FormGroup;
  marketing = new FormControl();

  
  @Input() isEdit: boolean
  @Input() data: CatalogProductDetails
  @Output() dataChange = new EventEmitter<CatalogProductDetails>();

  constructor(
    private formBuilder: FormBuilder, 
    private catalogProductService: CatalogProductService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // Valeur par défault
    this.data = {...this.data}
    this.dataChange.emit(this.data);
    
    setTimeout(() => {
      this.marketing.setValue(this.data.dataConfig.marketing)
    }, 2000);

    this.formGroup = this.formBuilder.group({
      marketing: this.marketing,
    })
  }

  changeSelect(e) {
    this.data = {...this.data, dataConfig: {...this.data.dataConfig, ...this.formGroup.value}}
    this.dataChange.emit(this.data)
  }

  deleteProduct(sku: string) {
    this.catalogProductService.removeProduct(sku).pipe(first()).subscribe(
      data => {
        this.messageService.openSnackBar('Produit supprimé avec succès', 'success')
        this.router.navigate(['/catalog']);
      },
      error => {
        this.messageService.openSnackBar(error, 'error')
      }
    )
  }

}
