import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SimpleSlideComponent } from 'src/app/_components/simple-slide/simple-slide.component';
import { Observable, Subscription, timer } from 'rxjs';
import { Commande } from 'src/app/models/commande';
import { Store } from '@ngrx/store';
import { selectCommandePending, selectCommandeSound } from 'src/app/reducers/commande/commande.selector';
import { setPendingCommandToModal, toggleNotificationSound } from 'src/app/reducers/commande/commande.action';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';


export const STORAGE_KEY = "ocito_app_notifications"

@Component({
  selector: 'commande-new',
  templateUrl: './commande-new.component.html',
  styles: [
  ],
  host: {
    class: 'commande__new'
  }
})
export class CommandeNewComponent {
  interval: number = 90000
  commandes$: Observable<Commande[]> = this.store.select(selectCommandePending)
  soundActive$: Observable<boolean> = this.store.select(selectCommandeSound);
  subscriptionTimer: Subscription;
  @ViewChild('slider') slider: SimpleSlideComponent;

  constructor(
    private store: Store,
    private commandesService: CommandesService
  ) { }


  /**
   * Activation/Desactivation des sons de notification
   */
  toggleNotificationSound() {
    this.store.dispatch(toggleNotificationSound())
  }

  /**
   * Affiche la modale avec les commandes
   */
  showNotifCommande(id:string) { 
    // ne pas faire l'action des boutons si on drag
    if(!this.slider.isDragging){
      this.store.dispatch(setPendingCommandToModal({id}))
    }
  }
}
