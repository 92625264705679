import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService } from '../http/http.service';


@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  order = null;

  constructor(
    private httpService: HttpService,
  ) {
    
  }

  getDashboard(date_begin: number, date_end: number): Observable<any> {
    return this.httpService.apiRequest('get', `dashboard?date_begin=${date_begin}&date_end=${date_end}`, {}, {})
  }

  getTopFive(): Observable<any> {
    return this.httpService.apiRequest('get', `dashboard/topfive`, {}, {})
  }
}