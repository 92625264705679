import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MCC } from 'src/app/exports/mcc';
import { MessageService } from 'src/app/utils/message/message.service';
import { StripeService } from 'src/app/utils/stripe/stripe.service';
import { TicketModalComponent } from 'src/app/_components/ticket-modal/ticket-modal.component';
import { EditSirenModalComponent } from './edit-siren-modal/edit-siren-modal.component';

@Component({
  selector: 'stripe-entreprise',
  templateUrl: './stripe-entreprise.component.html',  
  host: {
    'class': 'stripe-home__wrapper'
  }
})
export class StripeEntrepriseComponent implements OnInit {
  loader: boolean = false;
  formGroup: FormGroup;
  isRequesting: boolean = false;
  name = new FormControl('', Validators.required);
  mcc = new FormControl('', Validators.required);
  url = new FormControl('', Validators.required);
  tax_id = new FormControl('', Validators.required);
  mccArray = MCC;
  
  @Input() data: any;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private stripeService: StripeService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // Construction du formulaire
    this.mcc.setValue(this.data?.mcc)
    this.url.setValue(this.data?.url)
    this.name.setValue(this.data?.name)
    if(this.data.tax_id === true)
      this.tax_id.setValue(true)
    else this.tax_id.setValue("")
      
    this.formGroup = this.formBuilder.group({
      mcc: this.mcc,
      url: this.url,
      name: this.name,
      tax_id: this.tax_id
    })
  }

  changeInputText() {
    this.data = {...this.data, ...this.formGroup.value}
  }

  openModalChangeSiren(){
    console.log(this.data.idAccount)
    const dialogRef = this.dialog.open(EditSirenModalComponent, {
      panelClass: "modal__ticket",
      disableClose: false,
      data: this.data.idAccount
    });
  }

  onSearch(value) {
    let filter = value.toLowerCase();
    this.mccArray = MCC.filter(option => option.value.toLowerCase().includes(filter));
  }

  saveForm() {
    let payload = this.data;
    const {idAccount} = payload
    //On vérifie si tous les champs sont remplis, sont corrects et si l'on n'est pas déjà en requete pending
    if(this.formGroup.valid && !this.isRequesting){
      this.isRequesting = true;
      
      this.stripeService.updateStripeAccount(idAccount, payload).subscribe(dataAccount => {
        this.messageService.openSnackBar('Profil sauvegardé avec succès', 'success')
        this.data.tax_id = dataAccount.company.tax_id_provided
        this.stripeService.updateStripeBusiness(idAccount, payload).subscribe(data => {
          if(data.error){
            this.messageService.openSnackBar(data.error.message, 'error')
          }else{
            this.messageService.openSnackBar('Profil sauvegardé avec succès', 'success')
          }
        }, _ => {
          this.messageService.openSnackBar('Echec lors de la sauvegarde du profil', 'error')
        })
      }, _ => {
        this.messageService.openSnackBar('Echec lors de la sauvegarde du profil', 'error')
      }, () => {
        this.isRequesting = false; 
      });
    }else{
      this.messageService.openSnackBar('Veuillez renseigner tous les champs.', 'error')
    }
  }
}
