import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/utils/message/message.service';
import { StripeService } from 'src/app/utils/stripe/stripe.service';

@Component({
  selector: 'stripe-identite',
  templateUrl: './stripe-identite.component.html',  
  host: {
    'class': 'stripe-home__wrapper'
  }
})
export class StripeIdentiteComponent implements OnInit {
  loader: boolean = false;
  isRequesting: boolean = false;
  formGroup: FormGroup;
  first_name = new FormControl('', Validators.required);
  last_name = new FormControl('', Validators.required);
  email = new FormControl('', Validators.required);
  day = new FormControl('', Validators.required);
  month = new FormControl('', Validators.required);
  year = new FormControl('', Validators.required);
  postal_code = new FormControl('', Validators.required);
  city = new FormControl('', Validators.required);
  country = new FormControl('', Validators.required);
  line1 = new FormControl('', Validators.required);
  phone = new FormControl('', Validators.required);
  indicatif = new FormControl('', Validators.required);
  title = new FormControl('', Validators.required);
  
  @Input() data;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private stripeService: StripeService,
  ) { }

  ngOnInit(): void {
    let phone = this.data?.phone;
    let phoneAfterIndicatif: string;
    let indicatif: string;
    //Gère l'affichage en front du numéro de tel splité en deux (Indicatif + reste du numéro)
    if(this.data?.phone){
      if(phone.includes('+33')){
        indicatif = '+33'
        phoneAfterIndicatif = phone.split(indicatif)[1]
      }else{
        indicatif = '+262'
        phoneAfterIndicatif = phone.split(indicatif)[1]
      }
    }

    // Construction du formulaire
    this.first_name.setValue(this.data?.first_name)
    this.last_name.setValue(this.data?.last_name)
    this.email.setValue(this.data?.email)
    this.day.setValue(this.data?.day)
    this.month.setValue(this.data?.month)
    this.year.setValue(this.data?.year)
    this.postal_code.setValue(this.data?.postal_code)
    this.city.setValue(this.data?.city)
    this.country.setValue(this.data?.country)
    this.line1.setValue(this.data?.line1)
    this.phone.setValue(phoneAfterIndicatif)
    this.indicatif.setValue("+33")
    this.title.setValue(this.data?.title)

    this.formGroup = this.formBuilder.group({
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      day: this.day,
      month: this.month,
      year: this.year,
      postal_code: this.postal_code,
      city: this.city,
      country: this.country,
      line1: this.line1,
      phone: this.phone,
      title: this.title,
      indicatif: this.indicatif
    })
  }

  changeInputText() {
    this.data = {...this.data, ...this.formGroup.value}
  }

  changeSelect(e) {
    this.data = {...this.data, ...this.formGroup.value}
  }

  saveForm() {
    let payload = this.data;
    const {idAccount, idPerson} = payload
    //On vérifie si tous les champs sont remplis, sont corrects et si l'on n'est pas déjà en requete pending
    if(this.formGroup.valid && !this.isRequesting){
      this.isRequesting = true;
      //Si la personne existe déjà on l'update sinon on la crée.
      if(idPerson){
        this.stripeService.updateStripePerson(idAccount, idPerson, payload).subscribe(_ => {
          this.messageService.openSnackBar('Profil sauvegardé avec succès', 'success')
        }, error => {
          this.messageService.openSnackBar('Echec lors de la sauvegarde du profil', 'error')
        }, () => {
          this.isRequesting = false; 
        })
      }else{
        this.stripeService.createStripePerson(idAccount, payload).subscribe(_ => {
          this.messageService.openSnackBar('Profil crée avec succès', 'success')
        }, error => {
          this.messageService.openSnackBar('Echec lors de la création du profil', 'error')
        }, () => {
          this.isRequesting = false; 
        })
      }
    }else{
      this.messageService.openSnackBar('Veuillez renseigner tous les champs.', 'error')
    }
  }
}
