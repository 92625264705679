<div class="gestion__container">
  <div class="gestion__header">
    <div class="gestion__row">
      <div class="titles">
        <h2>Gestion de la boutique</h2>
        <span>{{shopName$ | async}}</span>
      </div>
    </div> 
  </div>
  <div class="gestion-home gestion__body">
    <div class="gestion-home__container">
      <a [routerLink]="['/shop']" class="gestion-home__vignette shop">
        <div class="gestion-home__text">
          Configuration<br/>
          boutique
        </div>
      </a>
      <a [routerLink]="['/admin']" class="gestion-home__vignette user">
        <div class="gestion-home__text">
          Gestion des<br/>
          collaborateurs
        </div>
      </a>
      <a [routerLink]="['/refund']" class="gestion-home__vignette user">
        <div class="gestion-home__text small">  
          Mes demandes<br/>
          de remboursement
        </div>  
      </a>
      <a [routerLink]="['/virements']" class="gestion-home__vignette user">
        <div class="gestion-home__text">  
          Récapitulatif
        </div>
      </a>
    </div>
    <!-- <a [routerLink]="['/']" mat-flat-button color="primary">Quitter le mode gestion</a> -->
  </div>

</div>
