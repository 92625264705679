<div class="ticket-modale--item">
    <section class="text">
        <div class="image_container">
            <img src="assets/images/deco/attention_blue.png" alt="">
        </div>
        <p>
            Votre compte Stripe est désactivé.
        </p>    
    </section>
    <div class="button_container">
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="goToStripeConfig()">  
            Configurer mon profil Stripe
        </button> 
    </div>  
</div>