import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectProfilGetAll, selectProfilPhoto, selectProfilShopName } from 'src/app/reducers/profil/profil.selector';
import { Profil } from 'src/app/models/profil';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ProfileService } from 'src/app/utils/profile/profile.service';
import { setAllProfil } from 'src/app/reducers/profil/profil.action';
import { MatDialog } from '@angular/material/dialog';
import { CollabModalComponent } from 'src/app/_components/collab-modals/collab-modal/collab-modal.component';

@Component({
  selector: 'app-collaborateur-list',
  templateUrl: './collaborateur-list.component.html',
  styles: [
  ]
})
export class CollaborateurListComponent implements OnInit {
  shopName$: Observable<string> = this.store.select(selectProfilShopName);
  profiles$: Observable<Profil[]> = this.store.select(selectProfilGetAll)
  // profilePhoto$: Observable<string> = this.store.select(selectProfilPhoto)  
  // date: any = moment()
  numberCollab: Number
  choiceIndex: number = 0

  constructor(
    private store: Store,
    private router: Router,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.numberCollab = 2
    // Appel API pour récupérer les profils
    this.profileService.fetchProfiles().subscribe(
      profils => {
        // Pour compter le nombre de profils
        this.numberCollab = profils.length
        // Mettre à jour les profils dans le store
        this.store.dispatch(setAllProfil({profils}))
      }
    );
  }

  goToEdit(id:string){
    const dialogRef = this.dialog.open(CollabModalComponent, {
      panelClass: "modal__collab",
      data: id,
    });
  }

  deleteProfile(id:string){
    this.router.navigate(['edit',id], { relativeTo: this.route })
  }

  goToAdd() {
    const dialogRef = this.dialog.open(CollabModalComponent, {
      panelClass: "modal__collab"
    });
  }


}
