import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class VirementsService {
  constructor(
    private httpService: HttpService,
  ) {}

  getVirements(start?: Date, end?: Date): Observable<any> {    
    return this.httpService.apiRequest('get', `shopkeeper/payout?start=${start}&end=${end}`,)
  }
}