import { AppState } from '..';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ProfilState } from 'src/app/models/profil';


const _selectProfil = createFeatureSelector<AppState, ProfilState>('profil');

/**
 * Nom du profil selectionné
 * @return string
 */
export const selectProfilName = createSelector(
  _selectProfil,
  (state: ProfilState) => state.selectedProfil.name
);

/**
 * Avatar du profil selectionné
 * @return string
 */
export const selectProfilPhoto = createSelector(
  _selectProfil,
  (state: ProfilState) => state.selectedProfil.profil_picture
);

/**
 * Id du profil selectionné
 * @return string
 */
export const selectProfilId = createSelector(
  _selectProfil,
  (state: ProfilState) => state.selectedProfil.entity_id
);

/**
 * Il y a un profil selectionné ?
 * @return boolean
 */
export const selectCanLogIn = createSelector(
  _selectProfil,
  (state: ProfilState) => state.selectedProfil.entity_id !== "0" || state.shop.id_shop !== ""
);

/**
 * Tout les profil
 * @return Profil[]
 */
export const selectProfilGetAll = createSelector(
  _selectProfil,
  (state: ProfilState) => state.profils
);

/**
 * Profil de l'id selectionné
 * @return Profil
 */
export const selectProfilById = createSelector(
  _selectProfil,
  (state: ProfilState, id: string) => {
    const profil = state.profils.find(elem => elem.entity_id === id);
    if (profil)
      return profil
    throw "Aucun profil trouvé"
  }
)



/**
 * Nom du magasin
 * @return string
 */
export const selectProfilShopName = createSelector(
  _selectProfil,
  (state: ProfilState) => state.shop.name
);

/**
 * Call center du magasin
 * @return string
 */
export const selectProfilCallCenter = createSelector(
  _selectProfil,
  (state: ProfilState) => state.shop.call_center
);

/**
 * Id du magasin
 * @return string
 */
export const selectProfilShopId = createSelector(
  _selectProfil,
  (state: ProfilState) => state.shop.manager_id
);

/**
 * Le magasin
 * @return Shop
 */
export const selectProfilShop = createSelector(
  _selectProfil,
  (state: ProfilState) => state.shop
);