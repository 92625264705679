<div class="marketing_wording">
    Afin de mettre en avant votre produit, sélectionnez la catégorie marketing qui correspond le mieux à votre produit.
</div>

<div class="gestion__block large-6">
    <div class="gestion__input-box">
        <label for="product-tax">Catégorie</label>
        <mat-form-field class="gestion__input">
          <mat-select 
            id="product-marketing" 
            multiple
            [formControl]="marketing"
            (selectionChange)="changeSelect($event)"
          >
            <mat-option *ngFor="let opt of data?.dataForm?.marketing" [value]="opt.id">{{opt.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
</div>


<!-- <div class="delete_product_button" *ngIf="isEdit">
  <button type="button"  mat-flat-button color="secondary" class="delete" (click)=deleteProduct(data?.dataConfig?.sku)>
    Supprimer le produit
  </button> 
</div> -->
