import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { MessageService } from 'src/app/utils/message/message.service';
import { passwordMinLength, passwordMaxLength, passwordValidator, areEqual } from 'src/app/utils/validator/validators.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/utils/auth/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {

  hide = true;
  hideConfirm = true;

  resetPassword: FormControl;
  resetPasswordConfirm: FormControl;
  resetPasswordForm: FormGroup;

  token: string
  user_id: string

  constructor(
    private builder: FormBuilder,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.token
    this.user_id = this.route.snapshot.queryParams.id

    this.resetPassword = new FormControl(
      '',
      [
        Validators.required,
        Validators.minLength(passwordMinLength),
        Validators.maxLength(passwordMaxLength),
        passwordValidator()

      ]
    );

    this.resetPasswordConfirm = new FormControl(
      '',
      [
        Validators.required,
        Validators.minLength(passwordMinLength),
        Validators.maxLength(passwordMaxLength),
        passwordValidator()
      ]
    );

    this.resetPasswordForm = this.builder.group({
      passwords: this.builder.group({
        password: this.resetPassword,
        passwordConfirm: this.resetPasswordConfirm,
      }, { validator: areEqual }),
    });

  }

  onSubmit(): void {
    if(!this.user_id || !this.token){
      this.messageService.openSnackBar('Une erreur est survenue, veuillez réessayer', 'error')
    } else{
      this.authService.resetPassword(this.user_id, this.token, this.resetPassword.value)
      .subscribe(
        success => { this.router.navigate(['/auth']) }
      )
    }
  }

  getErrorMessage(control: FormControl): string {
    return this.messageService.getErrorMessage(control);
  }

  confirmErrorMatcher = {
    isErrorState: (): boolean => {
      const areEqual: boolean = 
        this.resetPasswordConfirm.touched 
        && this.resetPassword.touched
        && this.resetPasswordForm.controls.passwords.errors?.areEqual
      return areEqual
    }
  }
}
