<form [formGroup]="formGroup">
  <ng-container *ngIf="!loader; else loadBlock">
    <div class="row">
      <div class="column small-12 medium-6">  
        <div class="gestion__block">
          <div class="stripe_gestionnaire">
            Sélectionnez le document que vous souhaitez charger pour vérifier l'identité
          </div>
            <mat-radio-group 
            aria-label="Type de document" 
            formControlName="attachmentType"    
            [selected]="attachmentType"
            (change)="attachmentTypeChange()"
          >
            <mat-radio-button value="identite">Pièce d'identité</mat-radio-button> 
            <mat-radio-button value="passeport">Passeport</mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- Piece identité -->
        <ng-container *ngIf="formGroup.value.attachmentType === 'identite'">
          <div class="gestion__block">
            <div class="gestion__block--title">
              <span>Pièce identité (recto)</span>
              <ng-container>
                <button mat-button color="primary" type="button">
                    <label for="uploadImageRecto">
                      <ng-container *ngIf="photo_identite_recto || data.document_back">Modifier</ng-container>
                      <ng-container *ngIf="!photo_identite_recto && !data.document_back">Ajouter</ng-container>
                    </label>
                </button>
              </ng-container>
            </div>
            <input
              hidden
              id="uploadImageRecto"
              type="file"
              (change)="fileChangeEvent($event, 'recto')"
              class="uploadImageRectoInput"
              placeholder="Yes"
            >
            <div class="imageError" *ngIf="imageError">{{imageError}}</div>
            <img [src]="photo_identite_recto" class="shop__photo-boutique">
          </div>
          <div class="gestion__block">
            <div class="gestion__block--title">
              <span>Pièce identité (verso)</span>
              <ng-container>
                <button mat-button color="primary" type="button">
                    <label for="uploadImageVerso">
                      <ng-container *ngIf="photo_identite_verso || data.document_front">Modifier</ng-container>
                      <ng-container *ngIf="!photo_identite_verso && !data.document_front">Ajouter</ng-container>
                    </label>
                </button>
              </ng-container>
            </div>
            <input
              hidden
              id="uploadImageVerso"
              type="file"
              (change)="fileChangeEvent($event, 'verso')"
              class="uploadImageVersoInput"
              placeholder="Yes"
            >
            <div class="imageError" *ngIf="imageError">{{imageError}}</div>
            <img [src]="photo_identite_verso" class="shop__photo-boutique">
          </div>
        </ng-container>

        <!-- Passeport -->
        <ng-container *ngIf="formGroup.value.attachmentType === 'passeport'">
          <div class="gestion__block">
            <div class="gestion__block--title">
              <span>Passeport</span>
              <ng-container>
                <button mat-button color="primary" type="button">
                    <label for="uploadImageRecto">
                      <ng-container *ngIf="photo_identite_recto || data.document_back">Modifier</ng-container>
                      <ng-container *ngIf="!photo_identite_recto && !data.document_back">Ajouter</ng-container>
                    </label>
                </button>
              </ng-container>
            </div>
            <input
              hidden
              id="uploadImageRecto"
              type="file"
              (change)="fileChangeEvent($event, 'recto')"
              class="uploadImageRectoInput"
              placeholder="Yes"
            >
            <div class="imageError" *ngIf="imageError">{{imageError}}</div>
            <img [src]="photo_identite_recto" class="shop__photo-boutique">
          </div>
        </ng-container>
      </div>
    </div>
    <div class="save_stripe_form">
      <a mat-flat-button color="primary" class="commande__ready catalog-create-product" (click)="saveForm()" [disabled]="isRequesting">
        <mat-icon aria-hidden="true" class="ok">done</mat-icon>Sauvegarder
      </a>
    </div>
  </ng-container>
  <ng-template #loadBlock>
    <div class="loader_container">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </ng-template>
</form>
