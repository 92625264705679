import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { selectCommandeLastWeek } from 'src/app/reducers/commande/commande.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-historique-blank',
  templateUrl: './historique-blank.component.html',
  styles: [],
  host: {
    class: 'historique__blank'
  }
})
export class HistoriqueBlankComponent implements OnInit {
  
  lastWeek$: Observable<number> = this.store.select(selectCommandeLastWeek)

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void { }

}
