<div class="row">
  <div class="column small-12 medium-6">
    <div class="gestion__block" *ngIf="horaires">
      <div class="shop__horaires--days">
        <button 
          mat-button 
          disableRipple="true"
          color="primary" 
          type="button" 
          *ngFor="let day of days; let i = index"
          [ngClass]="{'active': i === activeDay}"
          (click)="changeActiveDay(i)"
        >
          {{day}}
        </button>
      </div>
      <div class="shop__horaires">
        <div class="row">
          <div class="column small-6">
            <div class="gestion__block--title">
              <span>Heure d'ouverture</span>
            </div>
            <select 
              class="shop__horaires--select" 
              name="hourOpen1" 
              [formControl]="hourOpen1"
              (change)="changeHoursOpen1($event)"
            >
              <option value="" value="">-</option>
              <option *ngFor="let item of getHoursOpen1()" 
                [value]="item"
              >
                {{item}}
              </option>
            </select>
            <select 
              class="shop__horaires--select" 
              name="hourOpen2" 
              [formControl]="hourOpen2"
              (change)="changeHoursOpen2($event)"
              [attr.disabled]="disabledSecondLine()"
            >
              <option value="">-</option>
              <option *ngFor="let item of getHoursOpen2()" 
                [value]="item"
              >
                {{item}}
              </option>
            </select>
          </div>
          <div class="column small-6">
            <div class="gestion__block--title">
              <span>Heure de fermeture</span>
            </div>
            <select 
              class="shop__horaires--select" 
              name="hourClose1" 
              [formControl]="hourClose1"
              (change)="changeHoursClose1($event)"
            >
              <option value="">-</option>
              <option *ngFor="let item of getHoursClose1()" 
                [value]="item"
              >
                {{item}}
              </option>
            </select>
            <select 
              class="shop__horaires--select" 
              name="hourClose2" 
              [formControl]="hourClose2"
              (change)="changeHoursClose2($event)"
              [attr.disabled]="disabledSecondLine()"
            >
              <option value="">-</option>
              <option *ngFor="let item of getHoursClose2()" 
                [value]="item"
              >
                {{item}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <mat-slide-toggle
        [checked]="closeThisDay"
        (change)="submitCloseDay($event)"
      >
        Fermé tous les {{displayIsoWeekdayPlurial()}}
      </mat-slide-toggle>
    </div>
  </div>
  <div class="column small-12 medium-6">
    <div class="gestion__block">
      <div class="gestion__block--title">
        <span>Fermetures exceptionnelles</span>
        <button mat-button color="primary" type="button" (click)="picker.open()">
          Ajouter
        </button>
        <mat-form-field class="invisible-datepicker-field">
          <input 
            matInput 
            [formControl]="pickerInput"
            [matDatepicker]="picker" 
            [min]="minDate"
            (dateChange)="onSelectDatePicker($event.value)" 
          />
          <mat-datepicker #picker></mat-datepicker> 
        </mat-form-field>
      </div>
      <div>
        <div 
          *ngFor="let item of exceptionalClosings$ | async as list"
          class="ins-chips" 
          (click)="removeDate(item)"
        >
          <span>{{item | moment:"DD MMM YYYY"}}</span>
        </div>
      </div>
    </div>
    <div class="gestion__block">
      <div class="gestion__block--title">
      </div>
      <mat-slide-toggle 
        [checked]="availableToday == '1'"
        (change)="submitAvailableToday($event)"
      >
        Actuellement indisponible ce jour
      </mat-slide-toggle>
    </div>
  </div>
</div>