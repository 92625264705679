<form [formGroup]="formGroup">
  <ng-container *ngIf="!loader; else loadBlock">
    <div class="row">
      <div class="column small-12 medium-6">
        <div class="gestion__block">
          <div class="gestion__input-box">
            <label for="stripe-first_name">Prénom</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-first_name" 
                type="text"
                [formControl]="first_name"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="stripe-last_name">Nom</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-last_name" 
                type="text"
                [formControl]="last_name"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="stripe-email">Email</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-email" 
                type="text"
                [formControl]="email"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          
          <div class="gestion__input-box">
            <label for="stripe-title">Profession</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-title" 
                type="text"
                [formControl]="title"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          
          <div class="gestion__input-box">
            <label for="stripe-phone">Numéro de téléphone</label>
            <mat-form-field class="gestion__input indicatif">
              <mat-select 
                id="stripe-indicatif" 
                [formControl]="indicatif"
                (selectionChange)="changeSelect($event)"
              >
                <mat-option value="+33">+33</mat-option>
                <mat-option value="+262">+262</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-phone" 
                type="phone"
                [formControl]="phone"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          
          <div class="gestion__input-box">
            <label for="stripe-day">Date de naissance<br/>(Ex: 02/04/1995)</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-day" 
                type="text"
                [formControl]="day"
                (input)="changeInputText()"
              >
            </mat-form-field>
            <span style="margin: 0.3rem;">/</span>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-month" 
                type="text"
                [formControl]="month"
                (input)="changeInputText()"
              >
            </mat-form-field>
            <span style="margin: 0.3rem;">/</span>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-year" 
                type="text"
                [formControl]="year"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="column small-12 medium-6 column_photo">
        <div class="gestion__block">
          <div class="gestion__input-box">
            <label for="stripe-country">Pays</label>
            <mat-form-field class="gestion__input">
              <mat-select 
                id="stripe-country" 
                [formControl]="country"
                (selectionChange)="changeSelect($event)"
              >
                <mat-option value="FR">France</mat-option>
                <mat-option value="RE">La Réunion</mat-option>
              </mat-select>
            </mat-form-field>
          </div>  
          <div class="gestion__input-box">
            <label for="stripe-line1">Adresse</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-line1" 
                type="text"
                [formControl]="line1"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="stripe-postal_code">Code postal</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-postal_code" 
                type="text"
                [formControl]="postal_code"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="stripe-city">Ville</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-city" 
                type="text"
                [formControl]="city"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="save_stripe_form">
      <a mat-flat-button color="primary" class="commande__ready catalog-create-product" (click)="saveForm()" [disabled]="isRequesting">
        <mat-icon aria-hidden="true" class="ok">done</mat-icon>Sauvegarder
      </a>
    </div>
  </ng-container>
  <ng-template #loadBlock>
    <div class="loader_container">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </ng-template>
</form>
