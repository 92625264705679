<div class="confirmation_container">
    <p *ngIf="!hasDiscount">
        ATTENTION ! Il ne reste aucun produit dans la commande. <br/>
        Êtes vous sûr(e) de vouloir l'annuler ?<br/>
    </p>
    <div class="button_container">
        <button mat-flat-button color="warn" class="commande__ready delete" (click)="closeModal()">  
            Retour
        </button>   
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="acceptAndCloseModal()">  
            <mat-icon aria-hidden="true" class="ok">done</mat-icon>
            Confirmer   
        </button> 
    </div>  
</div>