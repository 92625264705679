import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { FormControl, Validators } from '@angular/forms';
import { ReportingService } from 'src/app/utils/reporting/reporting.service';
import { Observable } from 'rxjs';
import { selectProfilShopName } from 'src/app/reducers/profil/profil.selector';
import { Store } from '@ngrx/store';

interface Sort {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html'
})
export class ReportingComponent implements OnInit {
  shopName$: Observable<string> = this.store.select(selectProfilShopName)
  bestOrders$: Observable<any[]>
  minDate: any = new Date(2020, 0, 1);
  maxDate: any =  new Date
  sortControl = new FormControl('daily', Validators.required);
  datepickerControl = new FormControl('', Validators.required);
  currentMonth: any = moment().format()
  date: any = moment()
  ca_finish: number
  ca_pending: number
  ca_global: string
  numberOrders: number
  sorts: Sort[] = [
    {value: 'monthly', viewValue: 'Mois'},
    {value: 'weekly', viewValue: 'Semaine'},
    {value: 'daily', viewValue: 'Jour'},  
  ];
  constructor(  
    private store: Store,
    private reportingService: ReportingService,
  ) { }

  ngOnInit(): void {
    //Datepicker en français
    moment.locale('fr');
    //On récupère le dashboard/top5 du jour
    const startDay = moment().utc().startOf('day')
    const endDay = moment().utc().endOf('day')
    this.apiDashboard(startDay.unix(), endDay.unix())
    this.bestOrders$ = this.reportingService.getTopFive()
  }

  /**
   * 
   * @param startDate: timestamp
   * @param endDate: timestamp 
   */
  apiDashboard(startDate, endDate){
    this.reportingService.getDashboard(startDate, endDate).subscribe(
      dashboard => {
        this.ca_pending = dashboard.ca_pending
        this.ca_finish = dashboard.ca_finish
        this.ca_global = (parseFloat(dashboard.ca_finish.replace(/\s/g, '')) + parseFloat(dashboard.ca_pending.replace(/\s/g, ''))).toFixed(2)
        this.numberOrders = dashboard.number  
      } 
    );
  }

  /**
   * Au changement de tri
   * @param _value 
   */
  onChangeSort(_value) {
    this.date = moment()
    if(this.sortControl.value == 'monthly'){
      this.apiDashboard(moment().utc().startOf('month').unix(), moment().utc().endOf('month').unix())
    }else if(this.sortControl.value == 'weekly'){
      this.apiDashboard(moment().utc().startOf('week').unix(), moment().utc().endOf('week').unix())
    }else{
      this.apiDashboard(moment().utc().startOf('day').unix(), moment().utc().endOf('day').unix())
    }
  }

  /**
   * Au choix d'un jour du datepicker
   */
  onSelectDatePicker(value) {
    this.date = moment(value)
    const startDay = moment(this.date).add(1, 'd').utc().startOf('day')    
    const endDay = moment(this.date).add(1, 'd').utc().endOf('day')
    this.apiDashboard(startDay.unix(), endDay.unix()) 
  } 

  /**
   * Au clique du bouton précédent
   */
  onClickPrev(){
    if(this.date > this.minDate){
      if(this.sortControl.value == 'monthly'){
        this.date = this.date.subtract(1, 'month');
        let startMonth = moment(this.date).utc().startOf('month').unix()
        let endMonth = moment(this.date).utc().endOf('month').unix()
        this.apiDashboard(startMonth, endMonth)
      }else if(this.sortControl.value == 'weekly'){
        this.date = this.date.subtract(7, 'days');  
        let startWeek = moment(this.date).utc().startOf('week').unix()
        let endWeek = moment(this.date).utc().endOf('week').unix()
        this.apiDashboard(startWeek, endWeek)
      }else{
        this.date = this.date.subtract(1, 'days');  
        const startDay = moment(this.date).utc().startOf('day')
        const endDay = moment(this.date).utc().endOf('day')
        this.apiDashboard(startDay.unix(), endDay.unix())
      }
    }
  }

  /**
   * Au clique du bouton suivant
   */
  onClickNext(){
    if(this.date < this.maxDate){
      if(this.sortControl.value == 'monthly'){
        this.date = this.date.add(1, 'month');
        let startMonth = moment(this.date).utc().startOf('month').unix()
        let endMonth = moment(this.date).utc().endOf('month').unix()
        this.apiDashboard(startMonth, endMonth)
      }else if(this.sortControl.value == 'weekly'){
        this.date = this.date.add(7, 'days');  
        let startWeek = moment(this.date).utc().startOf('week').unix()
        let endWeek = moment(this.date).utc().endOf('week').unix()
        this.apiDashboard(startWeek, endWeek)
      }else{
        this.date = this.date.add(1, 'days');  
        this.apiDashboard(moment(this.date).utc().startOf('day').unix(), moment(this.date).endOf('day').unix())
      }
    }
  }
}
