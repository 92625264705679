<div class="ticket-modale--item">
    <section class="text">
        <div class="image_container">
            <img src="assets/images/deco/attention_blue.png" alt="">
        </div>
        <p>
            Pensez à mettre le ticket de caisse dans le colis pour permettre l'échange/remboursement selon vos conditions habituelles
        </p>
        <div class="show_again">
            <mat-checkbox 
                (change)="toggleModalTicket($event)" 
                class="example-margin"
            >Ne plus afficher</mat-checkbox>
        </div>
    </section>
    <div class="button_container">
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="closeModal()">  
            Valider
        </button> 
    </div>  
</div>