import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { CatalogProductService } from 'src/app/utils/catalog/catalog-product.service';
import { forkJoin } from 'rxjs';
import { CatalogProductDetails, CatalogDataConfig } from 'src/app/models/catalog';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/utils/message/message.service';
import { Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-catalog-config',
  templateUrl: './config.component.html',
  styles: [
  ]
})
export class CatalogConfigComponent implements OnInit {
  loader: boolean = false;
  defaultData: CatalogDataConfig


  imageError: string = null;

  formGroup: FormGroup;
  name = new FormControl();
  ref = new FormControl();
  majority_validation = new FormControl();
  tax = new FormControl();
  categories = new FormControl();
  weight = new FormControl();
  qty = new FormControl();
  categoryMerchant = new FormControl();
  description = new FormControl();

  @Input() data: CatalogProductDetails
  @Input() isEdit: boolean
  @Output() dataChange = new EventEmitter<CatalogProductDetails>();

  constructor(
    private formBuilder: FormBuilder,
    private catalogProductService: CatalogProductService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // Valeur par défault
    this.data = { ...this.data, dataConfig: { ...this.data.dataConfig, ...this.defaultData } }
    this.dataChange.emit(this.data);




    this.data = { ...this.data, dataConfig: { ...this.data.dataConfig, ...this.defaultData } }


    // Construction du formulaire
    this.name.setValue(this.data.dataConfig.name)
    this.ref.setValue(this.data.dataConfig.ref)
    this.majority_validation.setValue(this.data.dataConfig.majority_validation)
    this.tax.setValue(this.data.dataConfig.tax)
    this.categories.setValue(this.data.dataConfig.categories)
    this.weight.setValue(this.data.dataConfig.weight)
    this.qty.setValue(this.data.dataConfig.qty)
    this.categoryMerchant.setValue(this.data.dataConfig.categoryMerchant)
    this.description.setValue(this.data.dataConfig.description)

    this.formGroup = this.formBuilder.group({
      name: this.name,
      ref: this.ref,
      majority_validation: this.majority_validation,
      tax: this.tax,
      categories: this.categories,
      weight: this.weight,
      qty: this.qty,
      categoryMerchant: this.categoryMerchant,
      description: this.description
    })

    // Disable change Qty if product is configurable
    if (this.data.dataConfig.type == "configurable") {
      this.qty.disable()
    }
  }

  changeInputText() {
    this.data = { ...this.data, dataConfig: { ...this.data.dataConfig, ...this.formGroup.value } }
    this.dataChange.emit(this.data)
  }

  changeSelect(e) {
    this.data = { ...this.data, dataConfig: { ...this.data.dataConfig, ...this.formGroup.value } }
    this.dataChange.emit(this.data)
  }

  toggleProductMajor(ev: MatCheckboxChange) {
    const { checked } = ev
    this.data = { ...this.data, dataConfig: { ...this.data.dataConfig, ...this.formGroup.value } }
    this.dataChange.emit(this.data)
  }

  deleteProduct(sku: string) {
    this.catalogProductService.removeProduct(sku).pipe(first()).subscribe(
      data => {
        this.messageService.openSnackBar('Produit supprimé avec succès', 'success')
        this.router.navigate(['/catalog']);
      },
      error => {
        this.messageService.openSnackBar(error, 'error')
      }
    )
  }

  deleteImageProduct(index: number) {
    this.data.dataConfig.images.splice(index, 1)
  }

  /**
   * Upload en base64
   * @param fileInput 
   */
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
      const max_height = 15200;
      const max_width = 25600;

      Array.from(fileInput.target.files).forEach((image, index) => {
        if (fileInput.target.files[index].size > max_size) {
          this.imageError = 'Taille maximum autorisée ' + max_size / 1000 + 'Mb';
          return false;
        }
        if (!allowed_types.includes(fileInput.target.files[index].type)) {
          this.imageError = 'Extensions autorisées : ( JPG | PNG )';
          return false;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = rs => {
            const img_height = rs.currentTarget['height'];
            const img_width = rs.currentTarget['width'];
            if (img_height > max_height && img_width > max_width) {
              return false;
            } else {
              const imgBase64Path = e.target.result;

              // On met à jour les datas
              this.data.dataConfig.images = [
                ...this.data.dataConfig.images,
                {
                  url: imgBase64Path
                }
              ];
              this.dataChange.emit(this.data)
            }
          };
        };
        reader.readAsDataURL(fileInput.target.files[index]);
      })
    }
  }
}
