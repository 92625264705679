import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';

@Component({
  selector: 'app-commande-test',
  templateUrl: './commande-test.component.html',
  styles: [
  ]
})
export class CommandeTestComponent implements OnInit {
  allStatus: string[] = ["accepted", "new", "pending", "ready", "refused", "canceled"];
  commandeId: FormControl = new FormControl('')
  status: FormControl = new FormControl('')

  constructor(
    private commandeService: CommandesService
  ) { }

  ngOnInit(): void {
  }

  send(): void {
    this.commandeService.changeStatus(this.commandeId.value , this.status.value ).subscribe()
  }

}
