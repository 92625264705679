<div class="gestion__container">
    <div class="gestion__header">
        <div class="gestion__row">
            <a mat-icon-button class="back_button" routerLink="/"></a>
            <div class="titles">
                <h2>Récapitulatif</h2>
                <span>{{shopName$ | async}}</span>
            </div>
        </div> 
    </div>
    
    <div class="gestion__row">
        <div class="reporting__date virements_date">
            <mat-form-field>
                <mat-label>Date de début</mat-label>
                <input 
                    matInput 
                    [matDatepicker]="pickerStart"
                    [formControl]="datepickerControlStart" 
                    (dateChange)="onSelectDatePickerStart($event.value)" 
                >
                <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker disabled="false" #pickerStart></mat-datepicker>
            </mat-form-field> 
            <mat-form-field>
                <mat-label>Date de fin</mat-label>
                <input 
                    matInput 
                    [matDatepicker]="pickerEnd"
                    [formControl]="datepickerControlEnd" 
                    (dateChange)="onSelectDatePickerEnd($event.value)" 
                >
                <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker disabled="false" #pickerEnd></mat-datepicker>
            </mat-form-field>
            <button mat-flat-button (click)="downloadCSV()">Exporter en CSV</button>
        </div>
        
        <div class="loader_container" *ngIf="loader">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
            
        <ng-container *ngIf="virements.length > 0 && !loader">
            <table mat-table [dataSource]="virements" class="mat-elevation-z8 table_virement">
            
                <ng-container matColumnDef="reference">
                  <th mat-header-cell *matHeaderCellDef>N° sous commande</th>
                  <td mat-cell *matCellDef="let element" data-label="N° sous commande"> {{element.reference}} </td>
                </ng-container>
            
            
                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef> Montant sous commande </th>
                  <td mat-cell *matCellDef="let element" data-label="Montant sous commande"> {{element.amount}} € </td>
                </ng-container>
            
            
                <ng-container matColumnDef="transfert_date">
                  <th mat-header-cell *matHeaderCellDef> Transfert </th>
                  <td mat-cell *matCellDef="let element" data-label="Transfert"> {{moment(element.transfert_date).format("DD MMMM YYYY à HH:mm")}} </td>
                </ng-container>
            
            
                <ng-container matColumnDef="transfert_amount">
                  <th mat-header-cell *matHeaderCellDef> Montant du transfert </th>
                  <td mat-cell *matCellDef="let element" data-label="Montant du transfert"> {{element.transfert_amount}} € </td>
                </ng-container>
            
                <ng-container matColumnDef="transfert_status">
                  <th mat-header-cell *matHeaderCellDef> Status transfert </th>
                  <td mat-cell *matCellDef="let element" data-label="Status transfert"> {{element.transfert_status}} </td>
                </ng-container>
            
            
                <ng-container matColumnDef="virement_status">
                  <th mat-header-cell *matHeaderCellDef> Status virement </th>
                  <td mat-cell *matCellDef="let element" data-label="Status virement"> {{element.virement_status}} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </ng-container>

        <ng-container *ngIf="virements.length == 0 && !loader">
            <span class="no_recap">Aucun récapitulatif pour les dates choisies</span>
        </ng-container>
    </div>
</div>