import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { HistoriqueComponent } from './historique.component';
import { HistoriqueDetailsComponent } from './historique-details/historique-details.component';
import { AppSidebarModule } from 'src/app/_layout/app-sidebar/app-sidebar.module';
import { RouterModule } from '@angular/router';
import { HistoriqueBlankComponent } from './historique-blank/historique-blank.component';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { CategoryPriceModule } from 'src/app/_components/category-price/category-price.module';


@NgModule({
   imports: [
      CommonModule,
      MaterialModule,
      AppSidebarModule,
      RouterModule,
      CustomPipeModule,
      CategoryPriceModule
   ],
   declarations: [
      HistoriqueComponent,
      HistoriqueDetailsComponent,
      HistoriqueBlankComponent
   ]
})
export class HistoriqueModule { }