import { Component, OnInit, OnDestroy, HostBinding, Inject } from '@angular/core';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { Store, select } from '@ngrx/store';
import { toggleDarkMode } from 'src/app/reducers/colors/colors.action';
import { selectDarkMode } from 'src/app/reducers/colors/colors.selector';
import { Observable, Subscription, timer } from 'rxjs';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { first } from 'rxjs/operators';

const STORAGE_KEY = "ocito_app_profil"

@Component({
  selector: 'app-maintenance',
  templateUrl: './app-maintenance.component.html',
})
export class AppMaintenanceComponent implements OnInit, OnDestroy {
  subscriptionNew: Subscription;
  textMaintenance: string = '';
  

  constructor(
    private authService: AuthService,
    private store: Store,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) { }

  ngOnDestroy(): void {
    this.subscriptionNew.unsubscribe();
  }

  closeBandeau(): void {
    this.authService.hideBandeauMaintenance().pipe(first()).subscribe(
      data => {
        this.getMessageBandeau();
        window.location.reload()
      }
    )
  }

  getMessageBandeau(){
    this.authService.getMessageMaintenance().pipe(first()).subscribe(
      data => {
        this.textMaintenance = data
      }
    )
  }

  ngOnInit() {
    this.subscriptionNew = timer(0, 60000).subscribe(
      () => this.getMessageBandeau()
    )
  }
}
