<div class="gestion__container">
    <div class="gestion__header">
        <div class="gestion__row">
            <a mat-icon-button class="back_button" routerLink="/"></a>
            <div class="titles">
                <h2>Demandes de remboursement</h2>
                <span>{{shopName$ | async}}</span>
            </div>
        </div> 
        <div class="gestion__row filters">
            <div class="sort">
                <a href="#!" [ngClass]="{'active': currentFilter === 'all'}" (click)="changeFiltre($event, 'all')">
                    Tout afficher ({{getTotal(filteredRefunds.all)}})
                </a>
                <a href="#!" [ngClass]="{'active': currentFilter === 'pending'}" (click)="changeFiltre($event, 'pending')">
                    En attente ({{getTotal(filteredRefunds.pending)}})
                </a>
                <a href="#!" [ngClass]="{'active': currentFilter === 'finished'}" (click)="changeFiltre($event, 'finished')">
                    Terminé ({{getTotal(filteredRefunds.finished)}})
                </a>
                <a href="#!" [ngClass]="{'active': currentFilter === 'refused'}" (click)="changeFiltre($event, 'refused')">
                    Refusé ({{getTotal(filteredRefunds.refused)}})
                </a>
                <a href="#!" [ngClass]="{'active': currentFilter === 'canceled'}" (click)="changeFiltre($event, 'canceled')">
                    Annulé ({{getTotal(filteredRefunds.canceled)}})
                </a>
            </div>
            <!-- <div class="btn-wrap">
                <button mat-mini-fab aria-label="Rechercher une demande de remboursement" (click)="openSearchRefund()" class="search-btn" >
                    <mat-icon aria-hidden="true">search</mat-icon>
                </button>
            </div> -->
        </div>
    </div>  
    <div class="gestion__body refund_body">
        <div class="gestion__row">
            <div class="catalog__search--wrapper" *ngIf="search.length">
                <div class="ins-chips" (click)="removeSearch()"><span>{{search}}</span></div>
            </div>
            <!-- Loader -->
            <ng-container class="loader" *ngIf="loader">
                <div class="loader_container">
                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
            </ng-container>
            <!-- Pas de produits -->
            <ng-container *ngIf="!loader && !filteredRefunds[currentFilter].length">
                <div class="catalog__no_product">{{noRefundTranslate()}}</div>
            </ng-container>
            <ng-container *ngIf="filteredRefunds[currentFilter].length && !loader">
                <ng-container *ngFor="let refund of filteredRefunds[currentFilter]">
    
                    <div class="catalog__item refund_item">
                        <div class="refund_status"><span class="refund_label">Status : </span>{{refund.status}}</div> 
                        <div><span class="refund_label">N° de commande : </span>{{refund.suborder_reference}}</div>  
                        <div><span class="refund_label">Prix : </span>{{refund.refund_amount}}€</div>
                            <div><span class="refund_label">Date de création : </span>Le {{moment(refund.created_at).format("DD MMMM YYYY à HH:mm")}}</div>    
                            <div class="refund_products_container">
                            <span class="refund_label">Produits : </span>
                            <div class="refund_product_list">
                                <div class="refund_product" *ngFor="let product of refund.products">
                                    x{{product.qty}} {{product.name}}
                                    <div class="refund_comment">
                                        <span class="refund_label">Commentaire : </span>{{product.comment}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button mat-icon-button [matMenuTriggerFor]="menuRefund" class="menu__product--button">
                            <mat-icon class="done">more_vert</mat-icon>
                        </button>
                        <mat-menu #menuRefund="matMenu" class="cancel-menu">
                            <a mat-menu-item class="menu__product--delete" (click)="cancelRefund(refund.refund_id)">
                              <span>Annuler cette demande</span>
                            </a>
                        </mat-menu>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>