import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { tap, map, switchMap, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { HttpService } from "../http/http.service";
import { Store, select } from "@ngrx/store";
import { resetAllProfil } from "src/app/reducers/profil/profil.action";
import { HttpHeaders } from "@angular/common/http";
import { selectProfilShopId } from "src/app/reducers/profil/profil.selector";

const STORAGE_KEY = "ocito_app_tokens";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  isLoggedIn: boolean = false;
  redirectUrl: string = "/profiles";

  constructor(
    private router: Router,
    private httpService: HttpService,
    private store: Store,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    this.isLoggedIn = this.storage.get(STORAGE_KEY)?.accessToken !== undefined;
  }

  getAccessToken(): string {
    return this.storage.get(STORAGE_KEY)?.accessToken;
  }
  getRefreshToken(): string {
    return this.storage.get(STORAGE_KEY)?.refreshToken;
  }
  setTokens(tokens: { accessToken: string; refreshToken: string }): void {
    const { accessToken, refreshToken } = tokens;
    this.storage.set(STORAGE_KEY, { accessToken, refreshToken });
  }

  login(username: string, password: string): Observable<string> {
    let obj = { username, password };
    return this.httpService
      .apiRequest("post", "integration/admin/token", obj)
      .pipe(
        // Une fois récupéré l'accessToken, on va chercher le refreshToken
        switchMap((accessToken) =>
          this.httpService
            .apiRequest(
              "get",
              "shopkeeper/user/refreshToken",
              {},
              {
                headers: new HttpHeaders({
                  Authorization: "Bearer " + accessToken,
                }),
              }
            )
            .pipe(
              tap((refreshToken) => {
                // On récupère ici le success de l'observable pour mettre à jour les données lié à l'authentification
                // La gestion de la redirection ou de l'affichage de message d'erreur ne se fait pas ici
                this.isLoggedIn = true;
                this.setTokens({ accessToken, refreshToken });
              }),
              map(() => {
                // On retourne l'url de redirection au lieu de l'access token
                return this.redirectUrl;
              })
            )
        )
      );
  }

  autologin(token: string): Observable<string> {
    let obj = { token };
    return this.httpService
      .apiRequest("post", "shopkeeper/shop/sso/token/burn", obj, {}, "rest/V2/")
      .pipe(
        switchMap((accessToken) =>
          this.httpService
            .apiRequest(
              "get",
              "shopkeeper/user/refreshToken",
              {},
              {
                headers: new HttpHeaders({
                  Authorization: "Bearer " + accessToken.token,
                }),
              }
            )
            .pipe(
              tap((refreshToken) => {
                // On récupère ici le success de l'observable pour mettre à jour les données lié à l'authentification
                // La gestion de la redirection ou de l'affichage de message d'erreur ne se fait pas ici
                this.isLoggedIn = true;
                this.setTokens({
                  accessToken: accessToken.token,
                  refreshToken,
                });
              }),
              map(() => {
                // On retourne l'url de redirection au lieu de l'access token
                return this.redirectUrl;
              })
            )
        )
      );
  }

  getSSOToken(): Observable<string> {
    return this.httpService.apiRequest(
      "get",
      "shopkeeper/shop/sso/token",
      {},
      {},
      "rest/V2/"
    );
  }

  getNewRefreshtoken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    return this.store.pipe(
      select(selectProfilShopId),
      switchMap((managerId) =>
        this.httpService.apiRequest("post", "shopkeeper/user/refreshToken", {
          managerId,
          refreshToken,
        })
      )
    );
  }

  getNewRefreshtokenUrl(): string {
    return `${this.httpService.urlEnv}${this.httpService.urlBase1}shopkeeper/user/refreshToken`;
  }

  logout(force = false): void {
    this.isLoggedIn = false;
    // Suppression du local store
    this.storage.remove(STORAGE_KEY);
    this.storage.remove("ocito_app_profil");
    this.storage.remove("ocito_app_commande");

    if (force) {
      window.location.href = "/auth";
    } else {
      this.router.navigate(["/auth"]);
    }
  }

  requestResetPassword(username: string): Observable<any> {
    return this.httpService.apiRequest(
      "post",
      "shopkeeper/user/requestResetPassword",
      { username }
    );
  }

  resetPassword(
    user_id: string,
    token: string,
    password: string
  ): Observable<any> {
    return this.httpService.apiRequest(
      "post",
      "shopkeeper/user/resetPassword",
      { user_id, token, password }
    );
  }

  getMessageMaintenance(): Observable<any> {
    return this.httpService.apiRequest("get", "shopkeeper/maintenance/message");
  }

  hideBandeauMaintenance() {
    return this.httpService.apiRequest(
      "post",
      "shopkeeper/maintenance/message/view"
    );
  }
}
