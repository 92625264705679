<form [formGroup]="formGroup">
  <ng-container *ngIf="!loader; else loadBlock">
    <div class="row">
      <div class="column small-12 medium-6">
        <div class="gestion__block">
          <div class="stripe_gestionnaire">Gestionnaire du compte bancaire</div>
          <div class="gestion__input-box">
            <label for="stripe-account_holder_type">Type</label>
            <mat-form-field class="gestion__input">
              <mat-select 
                  id="stripe-account_holder_type" 
                  [formControl]="account_holder_type"
                  (selectionChange)="changeSelect($event)"
                >
                  <mat-option value="individual">Individuel</mat-option>
                  <mat-option value="company">Entreprise</mat-option>
                </mat-select>
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="stripe-account_holder_name">Nom</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-account_holder_name" 
                type="text"
                [formControl]="account_holder_name"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="stripe-country">Pays</label>
            <mat-form-field class="gestion__input">
              <mat-select 
                id="stripe-country" 
                [formControl]="country"
                (selectionChange)="changeSelect($event)"
              >
                <mat-option value="FR">France</mat-option>
                <mat-option value="RE">La Réunion</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="stripe-account_number">Numéro de RIB</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-account_number" 
                type="text"
                [formControl]="account_number"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="save_stripe_form">
      <a mat-flat-button color="primary" class="commande__ready catalog-create-product" (click)="saveForm()" [disabled]="isRequesting">
        <mat-icon aria-hidden="true" class="ok">done</mat-icon>Sauvegarder
      </a>
    </div>
  </ng-container>
  <ng-template #loadBlock>
    <div class="loader_container">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </ng-template>
</form>
