<div class="gestion__container">
    <div class="gestion__header">
        <div class="gestion__row filters">
            <a mat-icon-button class="back_button" routerLink="/"></a>
            <div class="titles">
                <h2>Catalogue produit</h2>
                <span>{{shopName$ | async}}</span>
            </div>
            <a mat-flat-button color="second" class="catalog__add-btn" routerLink="./add">
                <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="colissimo"></mat-icon>Ajouter un produit
            </a>
        </div> 
        <div class="gestion__row filters">
            <div class="sort">
                <a href="#!" [ngClass]="{'active': currentFilter === 'all'}" (click)="changeFiltre($event, 'all')">
                    Tout afficher ({{getTotal(filteredCatalog.all)}})
                </a>
                <a href="#!" [ngClass]="{'active': currentFilter === 'active'}" (click)="changeFiltre($event, 'active')">
                    Activé ({{getTotal(filteredCatalog.active)}})
                </a>
                <a href="#!" [ngClass]="{'active': currentFilter === 'inactive'}" (click)="changeFiltre($event, 'inactive')">
                    Désactivé ({{getTotal(filteredCatalog.inactive)}})
                </a>
                <a href="#!" [ngClass]="{'active': currentFilter === 'nostock'}" (click)="changeFiltre($event, 'nostock')">
                    Hors-stock ({{getTotal(filteredCatalog.nostock)}})
                </a>
            </div>
            <div class="btn-wrap">
                <button mat-mini-fab aria-label="Recherche de produit" (click)="openSearchProduct()" class="search-btn" >
                    <mat-icon aria-hidden="true">search</mat-icon>  
                </button>
            </div>
        </div>
        <div class="gestion__row filters">
            <div class="sort">
                <ng-container *ngIf="!loader">
                    <ng-container *ngFor="let catalog of filteredCatalog[currentFilter]">
                        <a href="#!" [ngClass]="{'active': currentCategory === catalog.type}" (click)="changeCategory($event, catalog.type)">
                            {{catalog.type}}
                        </a>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="gestion__row select_all" *ngIf="!loader">
            <mat-checkbox
                (change)="toggleAll($event)"
                class="example-margin"
                [checked]="checkAllSelected()"
            ></mat-checkbox>
            <div class="check">
                Tout désélectionner
            </div>
            <div class="uncheck">
                Tout sélectionner
            </div>
        </div>
    </div>  
    <div *ngIf="selectedProducts.length" class="sticky-header multiple_action_wrapper">
        <div class="multiple_action_container">
            <span>{{selectedProducts.length}} produit{{selectedProducts.length | plural}} séléctionné{{selectedProducts.length | plural}}</span>
            <div class="multiple_action_item_flex">
                <div (click)="activeAll()" class="multiple_action_item">
                    <span>Activer</span>
                    <mat-slide-toggle [checked]="true"></mat-slide-toggle>
                </div>
                <div (click)="disabledAll()" class="multiple_action_item">
                    <span>Désactiver</span>
                    <mat-slide-toggle [checked]="false" class="multiple_action_disable"></mat-slide-toggle>
                </div>
                <div (click)="inStockAll()" class="multiple_action_item">
                    <span>En Stock</span>
                    <mat-slide-toggle [checked]="true"></mat-slide-toggle>
                </div>
                <div (click)="outStockAll()" class="multiple_action_item">
                    <span>Hors Stock</span>
                    <mat-slide-toggle [checked]="false" class="multiple_action_disable"></mat-slide-toggle>
                </div>
                <!-- <div (click)="deleteAll()" class="multiple_action_item">
                    <span>Supprimer</span>
                    <div class="multiple_action_delete"></div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="gestion__body">
        <div class="gestion__row">
            <div class="catalog__search--wrapper" *ngIf="search.length">
                <div class="ins-chips" (click)="removeSearch()"><span>{{search}}</span></div>
            </div>
            <!-- Loader -->
            <ng-container class="loader" *ngIf="loader">
                <div class="loader_container">
                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
            </ng-container>
            <!-- Pas de produits -->
            <ng-container *ngIf="!loader && !filteredCatalog[currentFilter].length">
                <div class="catalog__no_product">{{noProductTranslate()}}</div>
            </ng-container>
            <!-- Liste Produits -->
            <ng-container *ngIf="filteredCatalog[currentFilter].length && !loader">
                <ng-container *ngFor="let catalog of filteredCatalog[currentFilter]">
                    <ng-container *ngIf="(!currentCategory || currentCategory == catalog.type) && !loader">
                        <div class="catalog__type">{{catalog.type}} ({{catalog.products.length}})</div>
                        <div 
                            class="catalog__item" 
                            *ngFor="let item of catalog.products" 
                            [ngClass]="{'selected': selectedProducts.includes(item.id)}"
                        >
                            <div class="catalog__item__container" >
                                <mat-checkbox
                                (change)="toggleProductCatalog($event, item)"
                                class="example-margin"
                                [checked]="selectedProducts.includes(item.sku)"
                                ></mat-checkbox>
                                <div class="catalog__item--img">
                                    <div class="img"[style.backgroundImage]="'url('+item.image+')'"></div>
                                </div>
                                <div class="catalog__item--info" [ngClass]="{'inactive': ((item.stock == 0 || item.activated == 2) && item.type != 'bundle') }">
                                    <div class="catalog__item--name">{{item.name}}</div>
                                    <div class="catalog__item--sku">Ref : {{item.ref}}</div>
                                    <div class="catalog__item--price">
                                        Prix: <span [ngClass]="{'barred': item.special_price.length > 0}">{{item.price}}€ </span>
                                        <span *ngIf="item.special_price.length > 0"><b>{{item.special_price}}€</b></span>
                                    </div>
                                </div>
                                <div class="catalog__item--toggle">
                                    <div class="outofstock" *ngIf="item.stock == 0 && item.type != 'bundle'">
                                        <mat-icon aria-hidden="true">error_outline</mat-icon>
                                        <span>Produit hors-stock</span>
                                    </div>
                                    <span *ngIf="item.stock != 0" [ngClass]="{'disable': item.activated == 2}" class=" catalog__item--toggle--label">
                                        {{renderEnableOrDisable(item.activated)}}
                                    </span>
                                    <mat-slide-toggle
                                        [checked]="(((item.type == 'simple' || item.type == 'configurable' || item.type == 'virtual') && item.stock > 0) || item.type == 'bundle') && item.activated == 1"
                                        [disabled]="item.stock == 0 && item.type != 'bundle'"
                                        (change)="changeActive($event, item.sku, item.activated)"
                                    ></mat-slide-toggle>
                                </div>
                                <button mat-icon-button [matMenuTriggerFor]="menuProduct" class="menu__product--button">
                                    <mat-icon class="done">more_vert</mat-icon>
                                </button>
                                <mat-menu #menuProduct="matMenu">
                                    <a mat-menu-item [routerLink]="['/catalog/edit/', item.id]">
                                    <span>Editer ce produit</span>
                                    </a>
                                    <!-- <a mat-menu-item class="menu__product--delete" (click)="deleteProduct(item.sku)">
                                    <span>Supprimer ce produit</span>
                                    </a> -->
                                </mat-menu> 
                            </div>
                            <!-- PRODUITS CONFIGURABLES -->
                            <mat-expansion-panel *ngIf="item.type == 'configurable'" [expanded]="item.isExpanded">
                                <mat-expansion-panel-header (click)="toggleConfigurationsProduct(catalog.type, item)">
                                    Configurations du produit :
                                </mat-expansion-panel-header>
                                <ng-container *ngIf="loaderConfigurationsProducts">
                                    <div class="loader_container">
                                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                    </div>
                                </ng-container>
                                <ng-template matExpansionPanelContent>
                                    <div 
                                        class="catalog__item" 
                                        *ngFor="let itemConfigurable of item.configurations" 
                                        [ngClass]="{'selected': selectedProducts.includes(itemConfigurable.id)}"
                                    >
                                        <div class="catalog__item__container">
                                            <mat-checkbox
                                            (change)="toggleProductCatalog($event, itemConfigurable)"
                                            class="example-margin"
                                            [checked]="selectedProducts.includes(itemConfigurable.sku)"
                                            ></mat-checkbox>
                                            <div class="catalog__item--img">    
                                                <div class="img"[style.backgroundImage]="'url('+itemConfigurable.image+')'"></div>
                                            </div>
                                            <div class="catalog__item--info" [ngClass]="{'inactive': ((itemConfigurable.stock == 0 || itemConfigurable.activated == 2) && itemConfigurable.type != 'bundle') }">
                                                <div class="catalog__item--name">{{itemConfigurable.name}}</div>
                                                <div class="catalog__item--sku">Ref : {{itemConfigurable.ref}}</div>
                                                <div class="catalog__item--price">
                                                    Prix: <span [ngClass]="{'barred': itemConfigurable.special_price.length > 0}">{{itemConfigurable.price}}€ </span>
                                                    <span *ngIf="itemConfigurable.special_price.length > 0"><b>{{itemConfigurable.special_price}}€</b></span>
                                                </div>
                                            </div>
                                            <div class="catalog__item--toggle">
                                                <div class="outofstock" *ngIf="itemConfigurable.stock == 0 && itemConfigurable.type != 'bundle'">
                                                    <mat-icon aria-hidden="true">error_outline</mat-icon>
                                                    <span>Produit hors-stock</span>
                                                </div>
                                                <span *ngIf="itemConfigurable.stock != 0" [ngClass]="{'disable': itemConfigurable.activated == 2}" class=" catalog__item--toggle--label">
                                                    {{renderEnableOrDisable(itemConfigurable.activated)}}
                                                </span>
                                                <mat-slide-toggle
                                                    [checked]="(((itemConfigurable.type == 'simple' || itemConfigurable.type == 'configurable' || itemConfigurable.type == 'virtual') && itemConfigurable.stock > 0) || itemConfigurable.type == 'bundle') && itemConfigurable.activated == 1"
                                                    [disabled]="itemConfigurable.stock == 0 && itemConfigurable.type != 'bundle'"
                                                    (change)="changeActive($event, itemConfigurable.sku, itemConfigurable.activated)"
                                                ></mat-slide-toggle>
                                            </div>
                                            <button mat-icon-button [matMenuTriggerFor]="menuProduct" class="menu__product--button">
                                                <mat-icon class="done">more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menuProduct="matMenu">
                                                <a mat-menu-item [routerLink]="['/catalog/edit/', itemConfigurable.id]">
                                                <span>Editer ce produit</span>
                                                </a>
                                                <!-- <a mat-menu-item class="menu__product--delete" (click)="deleteProduct(itemConfigurable.sku)">
                                                <span>Supprimer ce produit</span>
                                                </a> -->
                                            </mat-menu> 
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-expansion-panel>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>