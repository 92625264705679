import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectProfilShopName } from 'src/app/reducers/profil/profil.selector';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CatalogService } from 'src/app/utils/catalog/catalog.service';
import { CatalogType, CatalogProduct } from 'src/app/models/catalog';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from 'src/app/_components/search-modal/search-modal.component';
import { first } from 'rxjs/operators';
import { CatalogModalComponent } from 'src/app/_components/catalog-modal/catalog-modal.component';
import { CatalogProductService } from 'src/app/utils/catalog/catalog-product.service';
import { MessageService } from 'src/app/utils/message/message.service';
import { ProfileService } from 'src/app/utils/profile/profile.service';
import { setManagerId } from 'src/app/reducers/profil/profil.action';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CatalogMultiModalComponent } from 'src/app/_components/catalog-multi-modal/catalog-multi-modal.component';

interface CatalogDisplay {
  all: CatalogType[],
  active: CatalogType[],
  inactive: CatalogType[],
  nostock: CatalogType[]
}

const emptyCatalogDisplay = { all: [], active: [], inactive: [], nostock: [] }

@Component({
  selector: 'app-reporting',
  templateUrl: './catalog.component.html'
})
export class CatalogComponent implements OnInit {
  shopName$: Observable<string> = this.store.select(selectProfilShopName)
  currentFilter: "all" | "active" | "inactive" | "nostock" = "all"
  filteredCatalog: CatalogDisplay = { ...emptyCatalogDisplay }
  currentCategory: string = null;
  originalCatalog: CatalogType[]
  search: string = ""
  loader: boolean = true;
  selectedProducts = [];
  loaderConfigurationsProducts = true;


  constructor(
    private store: Store,
    public dialog: MatDialog,
    private catalogService: CatalogService,
    private catalogProductService: CatalogProductService,
    private messageService: MessageService,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.catalogService.getCatalog().pipe(
      first()
    ).subscribe(data => {
      this.originalCatalog = data;
      // on regroupe en fonction de l'état du produit
      this.filteredCatalog = this._filterData(data)
      this.loader = false;

      //On recherche si un des produits est désactivé pour afficher la modale
      let showModalDisable = 0;
      data.forEach(element => {
        const { type, products } = element;
        products.forEach(product => {
          if (product.activated == 2 && product.stock > 0) {
            showModalDisable++;
          }
        })
      })
      if (showModalDisable > 2) {
        const dialogRef = this.dialog.open(CatalogModalComponent, {
          panelClass: "modal__catalog"
        });
      }
    })

    this.profileService.getShopInfos().subscribe(
      shop => {
        // Mettre à jour les informations du magasin dans le store
        this.store.dispatch(setManagerId({ shop }))
      }
    );
  }

  /**
   * Filtre les datas par catégories 
   */
  private _filterData(data: CatalogType[]): CatalogDisplay {
    let retour = { ...emptyCatalogDisplay }
    data.forEach(element => {
      const { type, products } = element;
      products.forEach(product => {
        // le produit n'est pas en stock
        if (product.stock == 0 && product.type != "bundle") {
          retour.nostock = this._addProduct(retour.nostock, product, type)
        }
        // le produit est désactivé (Activé = 1, désactivé = 2)
        else if (product.activated == 2) {
          retour.inactive = this._addProduct(retour.inactive, product, type)
        }
        // le produit est activé
        else {
          retour.active = this._addProduct(retour.active, product, type)
        }
      })
    })
    retour.all = data
    return retour
  }

  /**
   * Ajoute un produit d'un type ("entrée, plats, dessert, ...") fixée dans le tableau de donnée en entrée
   * Retourne une copie du tableau donnée avec la modification
   */
  private _addProduct(data: CatalogType[], product: CatalogProduct, type: string): CatalogType[] {
    let retour = [...data]
    // on regarde si le type existe déjà
    const index = data.findIndex(el => el.type === type)
    // si oui on ajoute le produit à la suite des autres pour ce type
    if (index >= 0) {
      retour[index] = { ...retour[index], products: [...retour[index].products, product] }
    }
    // si non on ajoute une entrée au tableau avec le type et le produit
    else {
      retour = [...retour, { type, products: [product] }]
    }
    return retour
  }

  getTotal(filter: CatalogType[]): number {
    return filter.reduce((acc, val) => (acc + val.products.length), 0)
  }

  noProductTranslate(): string {
    switch (this.currentFilter) {
      case "active":
        return "Aucun produit activé"
      case "inactive":
        return "Aucun produit désactivé"
      case "active":
        return "Aucun produit hors-stock"
      default:
        return "Aucun produit"
    }
  }


  changeFiltre(ev: Event, filter): void {
    ev.preventDefault();
    this.currentFilter = filter;
  }

  changeCategory(ev: Event, category): void {
    ev.preventDefault();
    if (this.currentCategory == category)
      this.currentCategory = null;
    else this.currentCategory = category
  }

  changeActive(event: MatSlideToggleChange, sku: string, activated: number): void {
    this.loader = true;
    let isActivate = event.checked ? 1 : 2;
    let scrollPosition = window.scrollY;
    this.catalogService.changeStatusProductCatalog(sku, isActivate).pipe(
      first()
    ).subscribe(data => {
      this.catalogService.getCatalog().pipe(
        first()
      ).subscribe(data => {
        this.originalCatalog = data;
        this.filteredCatalog = this._filterData(data)
        this.loader = false;
        setTimeout(() => { window.scroll(0, scrollPosition) }, 0);
      })
    })
  }

  openSearchProduct(): void {
    const dialogRef = this.dialog.open(SearchModalComponent, {
      panelClass: "modal__search",
      data: {
        onSubmit: (data) => this.onSubmit(data),
        label: 'Entrez le nom du produit à rechercher :'
      }
    });
  }

  getIndex(type: string, sku: string) {
    let array = this.originalCatalog;
    // chercher index du type correspondant
    let indexType = array.findIndex(el => {
      return el.type === type
    })

    let indexProduct = array[indexType].products.findIndex(el => {
      return el.sku === sku
    })

    return { indexType, indexProduct }
  }

  toggleConfigurationsProduct(type: string, product: any): void {
    const { sku, configurations } = product
    if (configurations) {
      const { indexType, indexProduct } = this.getIndex(type, sku)
      this.originalCatalog[indexType].products[indexProduct] = { ...this.originalCatalog[indexType].products[indexProduct], isExpanded: !this.originalCatalog[indexType].products[indexProduct].isExpanded }
    } else {
      this.loaderConfigurationsProducts = true;
      this.catalogProductService.getConfigurationsProduct(sku).pipe(
        first()
      ).subscribe(data => {
        const { indexType, indexProduct } = this.getIndex(type, sku)

        this.originalCatalog[indexType].products[indexProduct] = { ...this.originalCatalog[indexType].products[indexProduct], configurations: data.products, isExpanded: true }

        this.filteredCatalog = this._filterData(this.originalCatalog)
        this.loaderConfigurationsProducts = false;
      })
    }
  }

  deleteProduct(sku: string): void {
    this.catalogProductService.removeProduct(sku).pipe(
      first()
    ).subscribe(data => {
      this.loader = true;
      const scrollPosition = window.scrollY;
      this.messageService.openSnackBar('Produit supprimé avec succès', 'success')
      this.catalogService.getCatalog().pipe(
        first()
      ).subscribe(data => {
        this.originalCatalog = data;
        // on regroupe en fonction de l'état du produit
        this.filteredCatalog = this._filterData(data)
        this.loader = false;
        setTimeout(() => { window.scroll(0, scrollPosition) }, 0);
      })
    }, error => {
      this.messageService.openSnackBar('Echec lors de la suppression du produit', 'error')
    })
  }

  toggleProductCatalog(ev: MatCheckboxChange, product: CatalogProduct) {
    const { checked } = ev
    if (checked) {
      this.selectedProducts = [...this.selectedProducts, product.sku]
    } else {
      let arrayProduct = this.selectedProducts.filter(el => {
        return el !== product.sku
      })
      this.selectedProducts = arrayProduct
    }
  }

  toggleAll(ev: MatCheckboxChange) {
    const { checked } = ev
    if(checked) {
      this.selectedProducts = this.getVisibleProduct()
    } else {
      this.selectedProducts = []
    }
  }

  private getVisibleProduct() {
    return this.filteredCatalog[this.currentFilter].reduce((acc, value) => {
      return (!this.currentCategory || this.currentCategory == value.type) ? [...acc, ...value.products] : acc
    }, []).map(el => el.sku);
  }

  checkAllSelected() {
    const products = this.getVisibleProduct();
    return (
      this.selectedProducts.length >= products.length &&
      this.arraysEqual(this.selectedProducts, products)
    );
  }

  private arraysEqual(a, b) {
    if (a.length < b.length) return false;
  
    for (var i = 0; i < b.length; ++i) {
      if (!a.includes(b[i])) return false;
    }
    return true;
  }

  renderEnableOrDisable(enable: string) {
    return enable == '1' ? 'Activé' : 'Désactivé'
  }

  private onSubmit(search: string): void {
    this.search = search;
    // on trie les products par rapport à leurs noms
    const newData = this._filterSearch()
    // on refiltre les data avec les données modifiées
    this.filteredCatalog = this._filterData(newData)
  }

  private _filterSearch(): CatalogType[] {
    return (
      this.originalCatalog.reduce((filtered: CatalogType[], element) => {
        const { type, products } = element
        const filteredProducts = products.filter(product => {
          const regex = RegExp(this.search, 'gi')
          const test = regex.test(product.name)
          return test
        })
        if (filteredProducts.length) {
          filtered.push({ type, products: filteredProducts })
        }
        return filtered
      }, [])
    )
  }

  removeSearch(): void {
    this.search = "";
    this.filteredCatalog = this._filterData(this.originalCatalog)
  }

  /**
   * Active tous les produits séléctionnés
   */
  activeAll() {
    const dialogRef = this.dialog.open(CatalogMultiModalComponent, {
      panelClass: "modal_frais_comission",
      disableClose: false,
      data: 'activer'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        let payload = {
          "arrayOfSku": this.selectedProducts,
          "status": 1
        }
        this.loader = true;
        const scrollPosition = window.scrollY;
        this.catalogProductService.activeProducts(payload).pipe(
          first()
        ).subscribe(data => {
          this.messageService.openSnackBar('Produits activés avec succès', 'success')
          this.catalogService.getCatalog().pipe(
            first()
          ).subscribe(data => {
            this.originalCatalog = data;
            this.filteredCatalog = this._filterData(data)
            this.loader = false;
            setTimeout(() => { window.scroll(0, scrollPosition) }, 0);
          })
        }, error => {
          this.messageService.openSnackBar('Echec lors de la suppression des produits', 'error')
          this.loader = false;
        })
        this.selectedProducts = []
      }
    })
  }

  /**
   * Désactive tous les produits séléctionnés
   */
  disabledAll() {
    const dialogRef = this.dialog.open(CatalogMultiModalComponent, {
      panelClass: "modal_frais_comission",
      disableClose: false,
      data: 'desactiver'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        let payload = {
          "arrayOfSku": this.selectedProducts,
          "status": 2
        }
        this.loader = true;
        const scrollPosition = window.scrollY;
        this.catalogProductService.disableProducts(payload).pipe(
          first()
        ).subscribe(data => {
          this.messageService.openSnackBar('Produits désactivés avec succès', 'success')
          this.catalogService.getCatalog().pipe(
            first()
          ).subscribe(data => {
            this.originalCatalog = data;
            this.filteredCatalog = this._filterData(data)
            this.loader = false;
            setTimeout(() => { window.scroll(0, scrollPosition) }, 0);
          })
        }, error => {
          this.messageService.openSnackBar('Echec lors de la suppression des produits', 'error')
          this.loader = false;
        })
        this.selectedProducts = []
      }
    })
  }

  /**
   * Mets le stock à 999 sur les produits sélectionnés
   */
  inStockAll() {
    const dialogRef = this.dialog.open(CatalogMultiModalComponent, {
      panelClass: "modal_frais_comission",
      disableClose: false,
      data: 'instock'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        let payload = {
          "arrayOfSku": this.selectedProducts,
        }
        this.loader = true;
        const scrollPosition = window.scrollY;
        this.catalogProductService.inStockProducts(payload).pipe(
          first()
        ).subscribe(data => {
          this.messageService.openSnackBar('Produits mis en stock avec succès', 'success')
          this.catalogService.getCatalog().pipe(
            first()
          ).subscribe(data => {
            this.originalCatalog = data;
            this.filteredCatalog = this._filterData(data)
            this.loader = false;
            setTimeout(() => { window.scroll(0, scrollPosition) }, 0);
          })
        }, error => {
          this.messageService.openSnackBar('Echec lors de la mise en stock des produits', 'error')
          this.loader = false;
        })
        this.selectedProducts = []
      }
    })
  }

  /**
   * Mets les produits sélectionnés hors-stock
   */
  outStockAll() {
    const dialogRef = this.dialog.open(CatalogMultiModalComponent, {
      panelClass: "modal_frais_comission",
      disableClose: false,
      data: 'outstock'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        let payload = {
          "arrayOfSku": this.selectedProducts,
        }
        this.loader = true;
        const scrollPosition = window.scrollY;
        this.catalogProductService.outStockProducts(payload).pipe(
          first()
        ).subscribe(data => {
          this.messageService.openSnackBar('Produits mis hors stock avec succès', 'success')
          this.catalogService.getCatalog().pipe(
            first()
          ).subscribe(data => {
            this.originalCatalog = data;
            this.filteredCatalog = this._filterData(data)
            this.loader = false;
            setTimeout(() => { window.scroll(0, scrollPosition) }, 0);
          })
        }, error => {
          this.messageService.openSnackBar('Echec lors de la mise hors stock des produits', 'error')
          this.loader = false;
        })
        this.selectedProducts = []
      }
    })
  }

  /**
   * Supprime tous les produits séléctionnés
   */
  deleteAll() {
    const dialogRef = this.dialog.open(CatalogMultiModalComponent, {
      panelClass: "modal_frais_comission",
      disableClose: false,
      data: 'delete'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        let payload = {
          "sku": this.selectedProducts,
        }
        this.loader = true;
        const scrollPosition = window.scrollY;
        this.catalogProductService.removeProducts(payload).pipe(
          first()
        ).subscribe(data => {
          this.messageService.openSnackBar('Produits supprimés avec succès', 'success')
          this.catalogService.getCatalog().pipe(
            first()
          ).subscribe(data => {
            this.originalCatalog = data;
            this.filteredCatalog = this._filterData(data)
            this.loader = false;
            setTimeout(() => { window.scroll(0, scrollPosition) }, 0);
          })
        }, error => {
          this.messageService.openSnackBar('Echec lors de la suppression des produits', 'error')
          this.loader = false;
          setTimeout(() => { window.scroll(0, scrollPosition) }, 0);
        })
        this.selectedProducts = []
      }
    })
  }
}
