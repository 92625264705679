import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectShopDisableToday, selectShopExceptionalClosings, selectShopHoraires } from 'src/app/reducers/shop/shop.selector';
import { ShopService } from 'src/app/utils/shop/shop.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { FormControl, Validators } from '@angular/forms';
import { ShopHoraire, ShopSlot } from 'src/app/models/shop';

@Component({
  selector: 'app-horaires',
  templateUrl: './horaires.component.html',
  styles: [
  ]
})
export class HorairesComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = []

  availableToday$: Observable<string> = this.store.select(selectShopDisableToday)
  availableToday = "1";

  exceptionalClosings$: Observable<string[]> = this.store.select(selectShopExceptionalClosings)
  pickerInput = new FormControl('', [])
  minDate: any = new Date();

  days: string[] = ["LUN."," MAR.", "MER.", "JEU.", "VEN.", "SAM.", "DIM."]
  activeDay: number = moment().isoWeekday();
  horaires$: Observable<ShopHoraire[]> = this.store.select(selectShopHoraires)
  horaires: ShopHoraire[]
  defaultOpeningHour = "0h45"; // -15 min
  defaultClosingHour = "23h15"; // +15 min
  interval = 15; // minutes entre chaque option
  hourOpen1 = new FormControl(null, [Validators.required])
  hourClose1 = new FormControl(null, [Validators.required])
  hourOpen2 = new FormControl(null)
  hourClose2 = new FormControl(null)
  closeThisDay  = false

  constructor(
    private store: Store,
    private shopService: ShopService
  ) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.availableToday$.subscribe(available => available == "0" ? this.availableToday = "0" : this.availableToday = "1"),
      this.horaires$.subscribe(horaires => {
        this.horaires = horaires;
        this.refreshForm()
      }),
    ]
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  /**
   * Gestion indisponibilité du jour
   */
  submitAvailableToday(ev: MatSlideToggleChange): void {
    const {checked} = ev
    let isChecked = checked ? "1" : "0";
    this.availableToday = isChecked 
    this.shopService.setDisableToday(isChecked);
  }


  /**
   * Gestion date picker / ajout et suppressions fermetures
   */
  onSelectDatePicker(value:string): void {
    this.pickerInput.setValue('')
    const date = moment(value).format('YYYY-MM-DD')
    this.shopService.addExceptionalClosure(date)
  }

  removeDate(date: string): void {
    this.shopService.removeExceptionalClosure(date)
  }


  /**
   * Change la date courante à modifier
   */
  changeActiveDay(day: number): void {
    this.activeDay = day;
    this.refreshForm(); 
  }


  /**
   * Met à jour les formControl pour les horaires
   */
  refreshForm():void {
    const slots: ShopSlot[] = this.horaires[this.activeDay]?.slots || [{start: "", end:  ""}, {start: "", end: ""}];
    this.hourOpen1.setValue(slots[0]?.start ? slots[0].start : "")
    this.hourClose1.setValue(slots[0]?.end ? slots[0].end : "") 
    this.hourOpen2.setValue(slots[1]?.start ? slots[1].start : "")
    this.hourClose2.setValue(slots[1]?.end ? slots[1].end : "")
    this.closeThisDay = !!this.horaires[this.activeDay]?.closed
  }

  /**
   * Désactive la seconde rangée d'horaire d'ouverture / fermeture
   */
  disabledSecondLine(): true | null {
    const r = (this.hourOpen1.invalid || this.hourClose1.invalid);
    return r ? true : null
  }


  /**
   * Génération des options des selects
   */
  getHoursOpen1(): string[] {
    const {hourOpen1: {value: open1}, hourClose1: {value: close1}, hourOpen2: {value: open2}, hourClose2: {value: close2}} = this
    // Le minimum sera toujours le minimum par défault
    const min = this.defaultOpeningHour
    // Le maximum sera en fonction de 'hourClose1' / 'hourOpen2' / 'hourClose2' / maximum par défault
    const max = close1 || this.addOffset(open2, -1) || this.addOffset(close2, -2) || this.addOffset(this.defaultClosingHour, -3)
    return this.getIntervals(min, max)
  }
  getHoursClose1(): string[] {
    const {hourOpen1: {value: open1}, hourClose1: {value: close1}, hourOpen2: {value: open2}, hourClose2: {value: close2}} = this
    // Le minimum sera en fonction de 'hourOpen1' / minimum par défault + 1*interval
    const min = open1 || this.addOffset(this.defaultOpeningHour, 1)
    // Le maximum sera en fonction de 'hourOpen2' / 'hourClose2' / maximum par défault
    const max = open2 || this.addOffset(close2, -1) || this.addOffset(this.defaultClosingHour, -2)
    return this.getIntervals(min, max)
  }
  getHoursOpen2(): string[] {
    const {hourOpen1: {value: open1}, hourClose1: {value: close1}, hourOpen2: {value: open2}, hourClose2: {value: close2}} = this
    // Le minimum sera en fonction de 'hourClose1' / 'hourOpen1' / minimum par défault
    const min = close1|| this.addOffset(open1, 1) ||this.addOffset(this.defaultOpeningHour, 2)
    // Le maximum sera en fonction de 'hourClose2' / maximum par défault
    const max = close2 || this.addOffset(this.defaultClosingHour, -1)
    return this.getIntervals(min, max)
  }
  getHoursClose2(): string[] {
    const {hourOpen1: {value: open1}, hourClose1: {value: close1}, hourOpen2: {value: open2}, hourClose2: {value: close2}} = this
    // Le minimum sera en fonction de 'hourOpen2' / 'houClose1' / 'hourOpen1' / minimum par défault
    const min = open2 || this.addOffset(close1, 1) || this.addOffset(open1, 2) || this.addOffset(this.defaultOpeningHour, 3)
    // Le maximum sera toujours maximum par défault
    const max = this.defaultClosingHour
    return this.getIntervals(min, max)
  }
  getIntervals(min:string, max:string): string[] {
    // On créé une boucle en partant du minimum jusqu'à atteindre le maximum avec un intervalle de x minutes
    // On pousse chaque valeur dans un tableau
    const momentMin = moment(min,"HH[h]mm");
    const momentMax = moment(max,"HH[h]mm");
    let array = [];
    // Le moment min est-il inférieur au max ?
    const isMinLowerThanMax = () => (momentMax.diff(momentMin) > 0)

    if(!isMinLowerThanMax()){
      return array
    }
    while (isMinLowerThanMax()) {
      // On ajoute 15 min au moment min
      momentMin.add(this.interval, 'minutes')
      // On ajoute moment Min dans le tableau si toujours inférieur à max
      if(isMinLowerThanMax()){
        let formattedDate = momentMin.format("HH[h]mm");
        array.push(formattedDate)
      }
    }

    return array
  }

  //Rajoute des décallages pour toujours avoir une plage horaire disponible
  addOffset(hour:string, offset: number): string {
    if(!hour || hour === ""){
      return null
    }
    return moment(hour,"HH[h]mm").add(offset*this.interval,'minutes').format("HH[h]mm")
  }


  /**
   * Change select
   */
  changeHoursOpen1(ev: Event){
    const {value} = this.hourOpen1
    // si pas de valeur, on remet tout à zéro, on enregistre rien
    // sinon appel api pour enregistrer les nouvelles valeurs
    if(value === "") {
      this.hourOpen1.setValue("")
      this.hourClose1.setValue("")
      this.hourOpen2.setValue("")
      this.hourClose2.setValue("")
      this.closeThisDay = true;
      this.saveHoraires()
    } else {
      this.saveHoraires()
    }
  }
  changeHoursClose1(ev: Event){
    const {value} = this.hourClose1
    if(value === "") {
      this.hourOpen1.setValue("")
      this.hourClose1.setValue("")
      this.hourOpen2.setValue("")
      this.hourClose2.setValue("")
    } else {
      this.saveHoraires()
    }
  }
  changeHoursOpen2(ev: Event){
    const {value} = this.hourOpen2
    // si pas de valeur, on remet tout à zéro les valeurs de la deuxième ligne, on enregistre rien
    // sinon appel api pour enregistrer les nouvelles valeurs
    if(value === "") {
      this.hourOpen2.setValue("")
      this.hourClose2.setValue("")
    } else {
      this.saveHoraires(false)
    }
  }
  changeHoursClose2(ev: Event){
    const {value} = this.hourClose2
    if(value === "") {
      this.hourOpen2.setValue("")
      this.hourClose2.setValue("")
    } else {
      this.saveHoraires()
    }
  }


  /**
   * Sauvegarder les horaires
   */
  saveHoraires(callApi = true) {
    const h: ShopHoraire[] = this.horaires.map((hor, i) => {
      //On envoie que la première partie des horaires si les deux autres champs ne sont pas remplis (sinon error API)
      if(this.hourClose2.value !== "" || this.hourOpen2.value !== ""){
        return ( (i === this.activeDay) ?
          {...hor, slots: [
            {
              start: this.hourOpen1.value !== "" ? this.hourOpen1.value : null,
              end: this.hourClose1.value !== "" ? this.hourClose1.value : null
            },
            {
              start: this.hourOpen2.value !== "" ? this.hourOpen2.value : null,
              end: this.hourClose2.value !== "" ? this.hourClose2.value : null
            }
          ]}
        : hor
        );
      }else{
        return ( (i === this.activeDay) ?
          {...hor, slots: [
            {
              start: this.hourOpen1.value !== "" ? this.hourOpen1.value : null,
              end: this.hourClose1.value !== "" ? this.hourClose1.value : null
            }
          ]}
        : hor
        );
      }
    })
    
    const day = this.activeDay;
    callApi && this.shopService.setHoraires(h)
    // this.shopService.setHoraires(h).pipe(
    //   take(1),
    // ).subscribe({
    //   error: () => {
    //     // on remet à zéro les select
    //     this.changeActiveDay(day)
    //   }
    // })
  }


  /**
   * Gestion "fermé les ..."
   */
  displayIsoWeekdayPlurial(): string {
    return (moment().isoWeekday(this.activeDay + 1).format('dddd') + 's').toLowerCase()
  }
  
  submitCloseDay(ev: MatSlideToggleChange): void {
    const {checked: closed} = ev
    this.closeThisDay = closed

    const h: ShopHoraire[] = this.horaires.map((hor, i) => {
      // On reset le tableau d'horaires si l'on ferme la boutique pour le jour en question
      let emptyHoraires = closed ? ( (i === this.activeDay) ? {slots: [], closed}  : hor ) : ( (i === this.activeDay) ? {...hor, closed}  : hor )
      return emptyHoraires; 
    });
  
    const day = this.activeDay; 
    this.shopService.setHoraires(h)
  }

}
