import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-collaborateur',
  templateUrl: './collaborateur.component.html',
  styles: [
  ]
})
export class CollaborateurComponent implements OnInit {
  constructor(
    private store: Store,
  ) { } 

  ngOnInit(): void {
  }
}
