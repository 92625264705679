<div class="gestion__container">
  <div class="gestion__header">
      <div class="gestion__row">                
          <a mat-icon-button class="back_button" routerLink="/gestion" routerLinkActive="active"></a>
          <div class="titles">            
              <h2>Gestion des collaborateurs</h2>
              <span>{{shopName$ | async}}</span>
          </div>
      </div>
      <div class="gestion__row filters">
        <span class="number_collab">Collaborateurs ({{numberCollab}})</span>
    </div>
  </div>
  <div class="collab-list gestion__row">
    <div class="profile-list-container">
      <div class="profil__item" (click)="goToAdd()">
        <div class="add_profile"></div>
        <span class="profil__name">Ajouter</span>
        <img src="/assets/images/profilCheck.png" class="profil__check" />
      </div>
      <div *ngFor="let profile of profiles$ | async; let index = index;" class="profil__item" (click)="goToEdit(profile.entity_id)">
        <div class="profil__image" [style.backgroundImage]="'url('+(profile.profil_picture)+')'"></div>
        <span class="profil__name">{{profile.name}}</span>
        <img src="/assets/images/profilCheck.png" class="profil__check" />
      </div>
    </div>
  </div>
</div>