import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { setShowModalTicket } from 'src/app/reducers/settings/settings.action';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StripeService } from 'src/app/utils/stripe/stripe.service';
import { MessageService } from 'src/app/utils/message/message.service';

@Component({
  selector: 'edit-siren-modal',
  templateUrl: './edit-siren-modal.component.html',
  styles: []
})
export class EditSirenModalComponent implements OnInit {
  formGroup: FormGroup;
  isRequesting: boolean = false;
  tax_id = new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]);

  
  constructor(
    public dialogRef: MatDialogRef<EditSirenModalComponent>,
    private stripeService: StripeService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    public store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    this.tax_id.setValue("")
      
    this.formGroup = this.formBuilder.group({
      tax_id: this.tax_id
    })
  }

  closeModal() {
    if(this.formGroup.valid && !this.isRequesting){
      this.isRequesting = true;
      this.stripeService.updateStripeAccount(this.data, this.formGroup.value.tax_id).subscribe(_ => {
        this.messageService.openSnackBar('Profil sauvegardé avec succès', 'success')
      }, _ => {
        this.messageService.openSnackBar('Echec lors de la sauvegarde du SIREN', 'error')
      }, () => {
        this.isRequesting = false; 
        this.dialogRef.close();
      })
    }
  }
}
