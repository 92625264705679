import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { setShowModalTicket } from 'src/app/reducers/settings/settings.action';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styles: []
})
export class TicketModalComponent implements OnInit {
  checked = false;
  
  constructor(
    public dialogRef2: MatDialogRef<TicketModalComponent>,
    public store: Store
    ) { }

  ngOnInit() {
    
  }

  toggleModalTicket(ev: MatCheckboxChange) {
    const {checked} = ev
    this.checked = checked    
  }

  closeModal() {
    this.dialogRef2.close();
    //Si l'on a choché ne plus afficher alors on stock dans le redux le fait de ne plus afficher la modale
    if(this.checked)
      this.store.dispatch(setShowModalTicket()) 
  }
}
