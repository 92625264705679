import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, first, switchMap, map, take, share } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { Store, select } from '@ngrx/store';
import { setInfosCommande, addCommandsToModal, addInPending, setPending, deleteInModal, deleteInPending, addInAccepted, setAccepted, addInArchived, setArchived, deleteInAccepted, setNewLeader, setPendingCommandToModal, refreshPendingCommandInModal } from 'src/app/reducers/commande/commande.action';
import { Commande, getCommandeInterface, dataPagination, CommandeMultiboutique } from 'src/app/models/commande';
import { makeAnExemple2 } from 'src/app/reducers/commande/exemple';
import * as moment from 'moment';
import { selectCommandeDeliveryTime, selectCommandePreparationTime, selectCommandeSound } from 'src/app/reducers/commande/commande.selector';
import { Params } from '@angular/router';
import { selectProfilId } from 'src/app/reducers/profil/profil.selector';
import { PrintService } from '../print/print.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MatDialog } from '@angular/material/dialog';
import { MultiboutiqueCancelModalComponent } from 'src/app/_components/multiboutique-cancel/multiboutique-cancel-modal.component';
  
const STORAGE_KEY = "ocito_app_profil"

const getCommandeDefaultOptions: getCommandeInterface = {
  status: ["accepted", "pending", "ready", "refused", "canceled", "new", "shipped"], 
  order:"created_at", 
  direction: "DESC",
  page: false,
  item_per_page: false,
  id: false,
  from: false,
  to: false
}

@Injectable({
  providedIn: 'root',
})
export class CommandesService {
  order = null;
  readonly item_per_page = 2

  constructor(
    private httpService: HttpService,
    private dialog: MatDialog,
    private store: Store,
    private printService: PrintService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    
  }



  getChecklist() {
    return this.httpService.apiRequest('get', `checklist`)
  }

  getMotifRefusSelect() {
    return this.httpService.apiRequest('get', `allRefuseReasons`)
  }

  getCommandes(options: getCommandeInterface, urlVersion:string = "V1"): Observable<any> {
    const data = {...getCommandeDefaultOptions ,...options}
    return this.httpService.apiRequest(
      'post', 
      'trader/orders/list',
      {data}, 
      {}, 
      `rest/all/${urlVersion}/`,
    ).pipe(
      take(1),
      share(),
      tap(
        success => {
          const { delivery_time, nb_orders, nb_received_orders_last_week, nb_received_orders_today, preparation_time, total_orders, } = success;
          const info = { delivery_time, nb_orders, nb_received_orders_last_week, nb_received_orders_today, preparation_time, total_orders }
          this.store.dispatch(setInfosCommande({info}))
        }
      )
    )
  }

  changeStatus(subOrderId: string, status: string, refuseReason?: string):Observable<any> {
    return this.store.pipe(
      select(selectProfilId),
      switchMap(
        profil_id => this.httpService.apiRequest(
          'post', 
          'trader/order/status', 
          {subOrderId, status, profil_id, refuseReason}, 
          {}, 
          "rest/all/V1/"
        )
      )
    )
  }


  /**
   * Met à jour le store avec les commandes 'new'
   */
  refreshNew(): void {
    const paramcommandes: getCommandeInterface = {status: ['new']}
    this.getCommandes(paramcommandes).pipe(
    // this.fakeCommande(['new']).pipe(
      first(),
    ).subscribe(
      data => {
        const commandes: Commande[] = data.orders
        // Notification sonore
        // Si nouvelle(s) commande(s) en 'new'
        // Si notif sonore activé
        if(commandes.length){
          this.store.pipe(
            take(1),
            select(selectCommandeSound),
          ).subscribe((active => {
            if(active){
              new Audio('/assets/sounds/notif.mp3').play();
            }
          }))
        }
        // On place les nouvelles commandes dans la modale de notifications  de commande
        this.store.dispatch(addCommandsToModal({commandes}))
        // On les place tout de suite en status pending
        // Appel API + changement dans le store
        commandes.forEach(commande => {
          this.changeStatus(commande.sub_order_id, "pending").subscribe(
            _ => {this.store.dispatch(addInPending({commandes:[commande]}))}
          )
        });
      }
    )
  }

  /**
   * Met à jour le store avec les commandes 'pending'
   */
  refreshPending(): void {
    const paramcommandes: getCommandeInterface = {status: ['pending']}
    this.getCommandes(paramcommandes).pipe(
    // this.fakeCommande(['pending']).pipe(
      first(),
    ).subscribe(
      data => {
        const commandes: Commande[] = data.orders
        this.store.dispatch(setPending({commandes}))
      }
    )
  }

  refreshModalsPending(id: string): void {
    const paramcommandes: getCommandeInterface = {status: ['pending']}
    this.getCommandes(paramcommandes).pipe(
      first(),
    ).subscribe(
      data => {
        const commandes: Commande[] = data.orders
        this.store.dispatch(refreshPendingCommandInModal({commandes, id}))
      }
    )
  }

  
  /**
   * Met à jour le store avec les commandes 'accepted'
   */
  refreshAccepted(): void {
    const paramcommandes: getCommandeInterface = {status: ['accepted', 'pending_ready_colissimo', 'canceled']}
    this.getCommandes(paramcommandes).pipe(
    // this.fakeCommande(['accepted']).pipe(
      first(),
    ).subscribe(
      data => {
        const shopid = this.storage.get(STORAGE_KEY)?.shop.id_shop
        const commandes: Commande[] = data.orders.filter(order => {
          
          let findIndexShop = order.sub_orders_related.findIndex(el => {
            return el.id_shop === shopid
          })  
          if(findIndexShop !== -1){
            if(order.sub_orders_related[findIndexShop].app_status === 'accepted'){
              return order
            }else{
              return order.status !== 'canceled'
            }
          }
          else return order.status !== 'canceled';
          
        })
        this.store.dispatch(setAccepted({commandes}))
      }
    )
  }

  
  /**
   * Met à jour le store avec les commandes 'archived'
   */
  refreshArchived(queryParams: Params): Observable<dataPagination> {
    const paramcommandes: getCommandeInterface = {
      status: ['ready', 'canceled', 'refused', 'shipped'],
      order: "delivery_date",
      item_per_page: this.item_per_page,
      id: queryParams.id || false,
      from: queryParams.date ? this.formatDateMySQL(queryParams.date, true) : false,
      to: queryParams.date ? this.formatDateMySQL(queryParams.date, false) : false,
      page: queryParams.page || 1
    }
    return this.getCommandes(paramcommandes).pipe(
    // return this.fakeCommande(['ready', 'canceled', 'refused']).pipe(
      first(),
      map(data => {
        let commandes: Commande[] = data.orders
        this.store.dispatch(setArchived({commandes}))
        return {nb_orders: data.nb_orders, current_page: data.current_page, max_page: data.max_page }
      })
    )
  }

  refreshArchivedV2(queryParams: Params): Observable<dataPagination> {
    const paramcommandes: getCommandeInterface = {
      status: ['ready', 'canceled', 'refused', 'shipped'],
      order: "delivery_date",
      item_per_page: this.item_per_page,
      id: queryParams.id || false,
      from: queryParams.date ? this.formatDateMySQL(queryParams.date, true) : false,
      to: queryParams.date ? this.formatDateMySQL(queryParams.date, false) : false,
      page: queryParams.page || 1
    }
    return this.getCommandes(paramcommandes, "V2").pipe(
    // return this.fakeCommande(['ready', 'canceled', 'refused']).pipe(
      first(),
      map(data => {
        let commandes: Commande[] = data.orders
        this.store.dispatch(setArchived({commandes}))
        return {nb_orders: data.nb_orders, current_page: data.current_page, max_page: data.max_page }
      })
    )
  }
  
  /**
   * Récupère une commande pour l'affichage du détail
   */
  getOneCommande(id:number): Observable<Commande> {
    const paramcommandes: getCommandeInterface = {id}
    return this.getCommandes(paramcommandes).pipe(
      first(),
      map(data => {
        let commandes: Commande[] = data.orders
        return commandes[0]
      })
    )
  }

  /**
   * On accepte une commande
   */
  acceptCommande(commande: Commande):void {
    this.changeStatus(commande.sub_order_id, 'accepted').subscribe(
    // this.changeStatus("3", 'accepted').subscribe(
      _ => {
        const id = commande.sub_order_id
        const shopid = this.storage.get(STORAGE_KEY)?.shop.id_shop

        let order = commande;
        let arraySubOrdersRelated = order.sub_orders_related;

        //Si c'est une commande multiboutique, on change le status dans le sub_orders_related
        if(commande.sub_orders_related.length > 0){
          //On rafraichit la liste pour avoir les bons status des sous commandes
          this.refreshAccepted()

          let findIndexShop = order.sub_orders_related.findIndex(el => {
            return el.id_shop === shopid
          }) 
          if(findIndexShop !== -1){
            arraySubOrdersRelated = [
              ...arraySubOrdersRelated.slice(0,findIndexShop),
              {
                ...arraySubOrdersRelated[findIndexShop], 
                status: 'accepted'
              },
              ...arraySubOrdersRelated.slice(findIndexShop+1),
            ]
            order = {...order, sub_orders_related: arraySubOrdersRelated}
          }
        }
        // on supprime de 'ShowInModal'
        this.store.dispatch(deleteInModal({id}))
        // on supprime de 'pending'
        this.store.dispatch(deleteInPending({id}))
        // on ajoute dans 'accepted'
        this.store.dispatch(addInAccepted({commandes: [order]}))

        //on imprime 3 fois la commande
        this.printService.print(order, 3)
      }
    )
  }

  /**
   * On refuse une commande
   */
  refuseCommande(commande: Commande, motifCancel: string):void {
    this.changeStatus(commande.sub_order_id, 'refused', motifCancel).subscribe(
      _ => {
        const id = commande.sub_order_id
        const shopName = this.storage.get(STORAGE_KEY)?.shop.name

        let order = commande;
        let arraySubOrdersRelated = order.sub_orders_related;

        //Si c'est une commande multiboutique, on change le status dans le sub_orders_related pour ne pas attendre l'api
        if(commande.sub_orders_related.length > 0){
          let findIndexShop = order.sub_orders_related.findIndex(el => {
            return el.id_shop === shopName
          }) 
          if(findIndexShop !== -1){
            arraySubOrdersRelated = [
              ...arraySubOrdersRelated.slice(0,findIndexShop),
              {
                ...arraySubOrdersRelated[findIndexShop], 
                status: 'refused'
              },
              ...arraySubOrdersRelated.slice(findIndexShop+1),
            ]
            order = {...order, sub_orders_related: arraySubOrdersRelated}
          }
        }

        // on supprime de 'ShowInModal'
        this.store.dispatch(deleteInModal({id}))
        // on supprime de 'pending'
        this.store.dispatch(deleteInPending({id}))
        // on ajoute dans 'archived'
        this.store.dispatch(addInArchived({commandes: [order]}))
      }
    )
  }

  /**
   * On annule la commande
   */
  cancelOrder(commande: Commande, motifCancel: string): void {
    this.changeStatus(commande.sub_order_id, 'canceled', motifCancel).subscribe(
      _ => {
        const id = commande.sub_order_id
        // on supprime de 'accepted'
        this.store.dispatch(deleteInAccepted({id}))
        // on ajoute dans 'archived'
        this.store.dispatch(addInArchived({commandes: [commande]}))
      }
    )
  }

  /**
   * Met à jour le status app_status pour pallier à l'affichage de la modale d'annulation de commande 
   */
  changeAppStatusShop(subOrderId: string, status: string): Observable<any> {
    return this.httpService.apiRequest('post', 
      'trader/order/appstatus', 
      {subOrderId, status}, 
      {}, 
      "rest/all/V1/"
    )
  }

  /**
   * On termine la commande
   */
  readyOrder(commande: Commande): void {
    this.changeStatus(commande.sub_order_id, 'ready').subscribe(
      _ => {
        const id = commande.sub_order_id
        // on supprime de 'accepted'
        this.store.dispatch(deleteInAccepted({id}))
        // on ajoute dans 'archived'
        this.store.dispatch(addInArchived({commandes: [commande]}))
      }
    )
  }

  readyOrderColissimoMulti(commande: Commande): void {
    this.changeStatus(commande.sub_order_id, 'pending_ready_colissimo').subscribe(
      _ => {
        //Si c'est une commande multiboutique, on change le status dans le sub_orders_related pour ne pas attendre l'api
        if(commande.sub_orders_related.length > 0){
          //On passe la boutique en leader de la commande
          this.store.dispatch(setNewLeader({commande}))
        }
      }
    )
  }

  

  //Construction  du payload pour l'api de changement de quantité.
  setPayloadModificationQuantity(order: Commande){
    let payload = {data: []}
    order.items.categories.forEach(el => {
      el.choices.forEach(element => {
        payload = {data: [
          ...payload.data,
          {
            qty: element.qty,
            item_id: element.item_id
          }
        ]}
      })
    })
    order.items.menus.forEach(menu => {
      payload = {data: [
        ...payload.data,
        {
          qty: menu.qty,
          item_id: menu.item_id
        }
      ]}
    })
    return payload
  }

  editQuantityOrder(subOrderId: string, items){
    return this.httpService.apiRequest('post', `shopkeeper/update/suborder/${subOrderId}`, items)
  }

  /**
   * On expédie la commande
   */
  shippedOrder(commande: Commande): void {
    this.changeStatus(commande.sub_order_id, 'ready').subscribe(
      _ => {
        this.changeStatus(commande.sub_order_id, 'shipped').subscribe(
          _ => {
            const id = commande.sub_order_id  
            // on supprime de 'accepted'
            this.store.dispatch(deleteInAccepted({id}))
            // on ajoute dans 'archived'
            this.store.dispatch(addInArchived({commandes: [commande]}))
          }
        )
      }
    )
  }
  
  /**
   * Récupération de l'heure selon le type de livraison de la commande
   * @param commande 
   */
  getDeliveryHour(commande: Commande):Observable<any> {
    if(!commande){
      return of(null)
    }
    else{
      return this.store.select(selectCommandeDeliveryTime).pipe(
        map(deliveryTime => {
          return commande.delivery_type == "click_and_collect" 
          ? moment(commande.delivery_date)
          : moment(commande.delivery_date).subtract(deliveryTime, "minutes")
        })   
      )
    }
  }

  /**
   * Calcul du timer
   * @param commande 
   * @return Pourcentage de temps restant
   */
  timer(commande: Commande):Observable<number> {
    return this.store.select(selectCommandePreparationTime).pipe(
      switchMap(preparationTime => {
        return this.getDeliveryHour(commande).pipe(
          map(deliveryTime => { 
            let dateNow = moment().unix()
            return this.interpolateTimer(dateNow, moment(deliveryTime).unix(), moment(parseInt(preparationTime)*60*1000).unix())
          })
        )
      }) 
    )
  }

  /**
   * 
   * @param dateNow 
   * @param deliveryTime 
   * @param preparationTime 
   */
  interpolateTimer(dateNow: number, deliveryTime: number, preparationTime: number){
    let x = dateNow,
        minX = deliveryTime - preparationTime,
        maxX = deliveryTime,
        minY = 0,
        maxY = 100
    x = Math.max( minX, Math.min( maxX, x ) )
    return (x-minX) * (maxY-minY)/(maxX-minX) + minY;
  }

  /**
   * Formate les dates au format DATETIME MySQL pour envoyer à l'API
   */
  formatDateMySQL(date: string, start: boolean): string{
    return (
      moment(date)
      .hour(start ? 0 : 23)
      .minute(start ? 0 : 59)
      .second(start ? 0 : 59)
      .format("YYYY-MM-DD HH:mm:ss"))
  }


  /**
   * FakeCommande
   */
  fakeCommande(availableStatus): Observable<any> {
    return this.getCommandes({status: ['accepted']}).pipe(
      map(
        _ => {
          const nbCommandes = 8;
          const days = 3;
          const availableHours = ['16:00', '18:10', '20:15']
          // const availableMethod = ['home_delivery', 'click_and_collect', 'colissimo']
          const availableMethod = ['colissimo']
          const availableMultiboutique = [
            [
              {'id_shop': '4', 'name': 'TRAN', 'is_primary': '1', 'status': 'accepted', 'app_status': 'accepted'}, 
              {'id_shop': '42', 'name': 'toto2', 'is_primary': '0', 'status': 'refused', 'app_status': 'pending'},
              {'id_shop': '44', 'name': 'toto3', 'is_primary': '0', 'status': 'pending', 'app_status': 'pending'},
              {'id_shop': '45', 'name': 'toto4', 'is_primary': '0', 'status': 'pending', 'app_status': 'pending'},
            ],
          ] 

          let orders = []
          for (let index = 0; index < nbCommandes; index++) {
            const date = moment(availableHours[this.randomNumber(availableHours.length)],'HH:mm').add(this.randomNumber(days) - 0,'days').format('YYYY-MM-DD HH:mm:ss')

            orders.push(
              makeAnExemple2(
                (10000 + index).toString(),
                // (10000 + this.randomNumber(9999)).toString(),
                date,
                availableStatus[this.randomNumber(availableStatus.length)],
                availableMethod[this.randomNumber(availableMethod.length)],
                availableMultiboutique[this.randomNumber(availableMultiboutique.length)]
              )
            )     
          }
          return {orders}
          return({
            orders: [
              makeAnExemple2("1001","2020-05-15 11:05:00","ready", "home_delivery", []),
            ]
          })}
      )
    )
  }
  randomNumber(nb:number):number {
    return Math.floor(Math.random() * nb)
  }
}