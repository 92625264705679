import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { Store } from '@ngrx/store';
import { PrintService } from '../print/print.service';
import { CatalogType } from 'src/app/models/catalog';

@Injectable({
  providedIn: 'root',
})
export class ColissimoService {
  constructor(
    private httpService: HttpService,
  ) {}

  getPdfColissimo(sub_order_id: string): Observable<any> {
    return this.httpService.apiRequest('get', `colissimo/pdf/order/${parseInt(sub_order_id)}`)
  }

  sendPdfMail(sub_order_id: string): Observable<any> {
    return this.httpService.apiRequest('post', `colissimo/pdf/mail`, {sub_order_id}) 
  }
}