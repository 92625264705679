import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectProfilShopName } from "src/app/reducers/profil/profil.selector";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { VirementsService } from "src/app/utils/virements/virements.service";
import { TableVirement } from "src/app/models/virement";
import { FormControl, Validators } from "@angular/forms";
import { saveAs } from "file-saver";
import * as Papa from "papaparse";

@Component({
  selector: "app-virement",
  templateUrl: "./virement.component.html",
})
export class VirementComponent implements OnInit {
  shopName$: Observable<string> = this.store.select(selectProfilShopName);
  virements: TableVirement[] = [];
  search: string = "";
  loader: boolean = true;
  moment: any = moment;
  datepickerControlStart = new FormControl({
    value: new Date(),
    disabled: true,
  });
  datepickerControlEnd = new FormControl({ value: new Date(), disabled: true });
  startDate: any = moment();
  endDate: any = moment();
  displayedColumns: string[] = [
    "reference",
    "amount",
    "transfert_date",
    "transfert_status",
    "transfert_amount",
    "virement_status",
  ];

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private virementsService: VirementsService
  ) {}

  ngOnInit(): void {
    //Datepicker en français
    moment.locale("fr");

    // API VIREMENTS
    this.apiVirements();
  }

  /**
   *
   * @param startDate: timestamp
   * @param endDate: timestamp
   */
  apiVirements(startDate = null, endDate = null) {
    this.loader = true;
    this.virementsService
      .getVirements(startDate, endDate)
      .subscribe((virements) => {
        this.virements = virements;
        this.loader = false;
      });
  }

  onSelectDatePickerStart(value: Date) {
    this.startDate = moment(value);
    if (this.startDate && this.endDate) {
      this.apiVirements(
        moment(this.startDate).format("YYYY-MM-DD"),
        moment(this.endDate).format("YYYY-MM-DD")
      );
    }
  }

  onSelectDatePickerEnd(value: Date) {
    this.endDate = moment(value);
    if (this.startDate && this.endDate) {
      this.apiVirements(
        moment(this.startDate).format("YYYY-MM-DD"),
        moment(this.endDate).format("YYYY-MM-DD")
      );
    }
  }

  transformData(data: TableVirement[]) {
    const entetes = [
      "N° sous commande",
      "Montant sous commande",
      "Commission ocito",
      "Statut transfert",
      "Montant du transfert",
      "Statut virement",
    ];

    const newArray = data.map(obj => {
      const commission = (parseFloat(obj.amount) - parseFloat(obj.transfert_amount)).toFixed(2);
      return [
        obj.reference,
        obj.amount,
        commission,
        obj.transfert_status,
        obj.transfert_amount,
        obj.virement_status
      ];
    });

    return [entetes, ...newArray];
  }

  downloadCSV(): void {
    const transformedData = this.transformData(this.virements);
    const csv = Papa.unparse(transformedData);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, "recapitulatif.csv");
  }
}