import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'search-modal',
  templateUrl: './search-modal.component.html',
  styles: []
})
export class SearchModalComponent implements OnInit {
  search: FormControl = new FormControl('');
  form: FormGroup
  constructor(
    public dialogRef: MatDialogRef<SearchModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    this.form = new FormGroup({
      search: this.search
    })
  }

  onSubmit() {
    this.data.onSubmit(this.search.value)
    this.dialogRef.close();
  }
}
