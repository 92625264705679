import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { ProfileService } from 'src/app/utils/profile/profile.service';
import { selectCanLogIn } from 'src/app/reducers/profil/profil.selector';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InAppGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService, 
    private profileService: ProfileService, 
    private router: Router,
    private store: Store
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this._canActivate(url)
    // return this.checkLoggedIn(url) && this.checkHasSelectedProfile(url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this._canActivate(url)
    // return this.checkLoggedIn(url) && this.checkHasSelectedProfile(url);
  }



  _canActivate(url: string): Observable<boolean | UrlTree> {
    const notLoggedInUrl: UrlTree = this.router.parseUrl('/auth')
    const noProfilUrl: UrlTree = this.router.parseUrl('/profiles')
    const isLoggedIn: boolean = this.authService.isLoggedIn

    // Si pas loggé on stock l'url de redirection après connexion
    // On retoure l'url non loggé
    if(!isLoggedIn){
      this.authService.redirectUrl = url
      return of(notLoggedInUrl)
    }
    // Page profil: on passe car on a déjà vérifié si on était loggé
    if(url === '/profiles') {
      return of(true)
    }
    // Autre page: il faut vérifier si on a un profil
    else {
      return this.store.select(selectCanLogIn).pipe(
        map(id => id ? true : noProfilUrl),
      )
    }
  }

  checkLoggedIn(url: string): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }

    this.authService.redirectUrl = url;
    this.router.navigate(['/auth/']);
    return false;
  }

  checkHasSelectedProfile(url: string): boolean {
    if (/*this.profileService.profile ||*/ url === '/profiles') {
      return true;
    }

    this.profileService.redirectUrl = url;
    this.router.navigate(['/profiles']);
    return false;
  }
}
