import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CatalogProductService } from 'src/app/utils/catalog/catalog-product.service';
import { forkJoin } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { CatalogProductDetails } from 'src/app/models/catalog';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/utils/message/message.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-catalog-attributes',
  templateUrl: './attributes.component.html',
  styles: [
  ]
})
export class CatalogAttributesComponent implements OnInit {
  loader: boolean = true;
  showAttributesArray: string[] = [];

  formGroup: FormGroup;
  attributes = new FormControl();
  attribute_set_id = new FormControl();
  
  @Input() isEdit: boolean
  @Input() data: CatalogProductDetails
  @Output() dataChange = new EventEmitter<CatalogProductDetails>();
  @Output() onChangeSetAttribute = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private catalogProductService: CatalogProductService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.data = {...this.data}
    this.dataChange.emit(this.data);

    this.attributes.setValue(this.data.dataConfig.attributes.map(attribute => (
      attribute.code
    )))

    if(this.data?.dataConfig?.attribute_set_id.length < 1){
      this.data.dataForm.attribute_set.find(elem => {
        if(elem.default === true)
          this.data.dataConfig.attribute_set_id = elem.value;
      })
    }

    this.attribute_set_id.setValue(this.data.dataConfig.attribute_set_id) 

    let groupAttributes: { [key: string]: AbstractControl; } = {}

    this.data.dataConfig.attributes.forEach(attribute => {
      groupAttributes[attribute.code] =  new FormControl(attribute.value)  
    })
    this.formGroup = this.formBuilder.group(
      groupAttributes
    )
  }

  //Pour trier l'affichage des attributs
  showAttributes(id: string): boolean {
    return this?.showAttributesArray.includes(id)
  }

  //Pour obtenir le label de l'attribut etc
  getDataFromCode(code: string, returnType: string): string {
    return this.data?.dataForm?.attributes.find(element => {
      return element.code === code
    })[returnType]
  }

  //Changement des attributs à afficher selon le jeu d'attribut
  changeChosenSelect(e: string[]) {
    this.showAttributesArray = e
    let currentControls = Object.keys(this.formGroup.controls);
    currentControls.forEach(control => {
      if(!this.showAttributesArray.includes(control)){
        this.formGroup.removeControl(control)
        this.changeSelectAttributes(e)
      }
    })
    this.showAttributesArray.forEach(code => {
      this.formGroup.addControl(code, new FormControl([]))
    })
  }

  //Suppression du produit
  deleteProduct(sku: string) {
    this.catalogProductService.removeProduct(sku).pipe(first()).subscribe(
      data => {
        this.messageService.openSnackBar('Produit supprimé avec succès', 'success')
        this.router.navigate(['/catalog']);
      },
      error => {
        this.messageService.openSnackBar(error, 'error')
      }
    )
  }

  //Changement du jeu d'attribut
  changeSetAttributeSelect(e) {
    this.data.dataConfig.attributes = []
    this.onChangeSetAttribute.emit(e.value);
    this.data = {...this.data, dataConfig: {...this.data.dataConfig, attribute_set_id: e.value}}
    this.dataChange.emit(this.data)
  }

  changeInputText() {
    const entries: [string, string][] = Object.entries(this.formGroup.value)
    let dataProductConfigAttribute = []
    for (const [code, value] of entries) {
      if(value?.length > 0)
        dataProductConfigAttribute.push({code, value})
    }

    this.data = {
      ...this.data, 
      dataConfig: {
        ...this.data.dataConfig,
        attributes: dataProductConfigAttribute
      }
    }

    this.dataChange.emit(this.data) 
  }

  //Changement d'un attribut
  changeSelectAttributes(e: string[]) {
    const entries: [string, string][] = Object.entries(this.formGroup.value)
    let dataProductConfigAttribute = []
    for (const [code, value] of entries) {
      if(value?.length > 0)
        dataProductConfigAttribute.push({code, value})
    }

    this.data = {
      ...this.data, 
      dataConfig: {
        ...this.data.dataConfig,
        attributes: dataProductConfigAttribute
      }
    }

    this.dataChange.emit(this.data) 
  }
}
