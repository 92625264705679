import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';

@Component({
  selector: 'stripe-modal',
  templateUrl: './stripe-modal.component.html',
  styles: []
})
export class StripeModalComponent implements OnInit {
  checked = false;
  
  constructor(
    public dialogRef: MatDialogRef<StripeModalComponent>,
    public store: Store,
    public router: Router,
    ) { }

  ngOnInit() {}

  toggleModalTicket(ev: MatCheckboxChange) {
    const {checked} = ev
    this.checked = checked    
  }

  goToStripeConfig() {
    this.dialogRef.close();
    this.router.navigate(['/stripe'])

  }
}
