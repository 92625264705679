import { AppState } from '..';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StripeState } from 'src/app/models/stripe';


const _selectStripeAccountId = createFeatureSelector<AppState, StripeState>('stripe');

/**
 * Stripe ID Account
 * @return string
 */
export const selectStripeAccountId = createSelector(
  _selectStripeAccountId,
  (state) => state.accountId
);