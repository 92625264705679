import { Component, AfterViewInit, ViewChild, ElementRef, OnInit, HostBinding } from '@angular/core';
import * as MobileDetect from 'mobile-detect'

@Component({
  selector: 'simple-slide',
  templateUrl: './simple-slide.component.html',
  host: {
    class: 'simple-slide-wrapper'

  }
})
export class SimpleSlideComponent implements AfterViewInit, OnInit {
  @HostBinding('class.is--mobile') isMobile: boolean = false;
  @ViewChild('simpleSlide') slider: ElementRef;
  sliderElement: any
  isDown: boolean = false;
  isDragging: boolean = false;
  startX: number;
  scrollLeft: number;


  constructor() { }

  ngOnInit() {
    const mobileDetector = new MobileDetect(window.navigator.userAgent)
    this.isMobile = !!mobileDetector.mobile()
  }
  ngAfterViewInit(): void {
    if(!this.isMobile)
    this.initSimpleSlide()
  }

  private initSimpleSlide(): void {
    this.sliderElement = this.slider.nativeElement
    this.sliderElement.addEventListener('mousedown', this.mouseDown.bind(this));
    this.sliderElement.addEventListener('mouseleave', this.mouseUp.bind(this));
    this.sliderElement.addEventListener('mouseup', this.mouseUp.bind(this));
    this.sliderElement.addEventListener('mousemove', this.mouseMove.bind(this));
  }

  private mouseDown(e: MouseEvent | TouchEvent ): void {
    const pos: MouseEvent | Touch = e instanceof TouchEvent ? e.targetTouches[0] : e;
    this.isDown = true;
    this.sliderElement.classList.add('active');
    this.startX = pos.pageX - this.sliderElement.offsetLeft;
    this.scrollLeft = this.sliderElement.scrollLeft;
  }
  private mouseUp(e: MouseEvent | TouchEvent): void {
    this.sliderElement.classList.remove('active');
    this.isDown = false;

    // Un setTimeout pour que les statuts ne se mettent pas à jour trop tôt par rapport à d'autres événement simultanés
    setTimeout(() => {
      this.isDragging = false;
    }, 0);
    this.scrollToSnap()
  }
  private mouseMove(e: MouseEvent | TouchEvent): void {
    if(!this.isDown) return;

    this.isDragging = true;
    const pos: MouseEvent | Touch = e instanceof TouchEvent ? e.targetTouches[0] : e;
    const x = pos.pageX - this.sliderElement.offsetLeft;
    const walk = (x - this.startX) * 1.2;
    this.sliderElement.scrollLeft = this.scrollLeft - walk;
  }

  scrollToSnap():void{
    const slider: HTMLElement = this.slider.nativeElement
    // Variables utiles:
    const {scrollWidth, clientWidth, scrollLeft: actualScroll} = slider
    // nombre de slide
    const items = slider.children.length || 1
    // taille d'une slide (en supposant qu'elles ont toutes la même taille)
    const slideWidth = scrollWidth / items
    // Le scroll maximal qui peut-être effectué
    const maxScroll = Math.max(scrollWidth - clientWidth, 0)
    // Quelle slide est censé être snappé à gauche ?
    const slideToShow = Math.floor((actualScroll + (slideWidth / 2)) / slideWidth)
    // Somme nous arrivés à la fin du slide ? 
    const isEnd = 
      (actualScroll > maxScroll - slideWidth / 4 ) // Les trois quart du dernier item sont visible ?
      && (Math.abs(actualScroll) > Math.abs(maxScroll - actualScroll)) // Plus proche de la fin ou du début ? (utile pour les cas ou le premier et le dernier sont visible en même temps)
    // Calcul du nombre de pixel dont on doit scroller pour faire le snap (peut être négatif)
    const howMuchToScroll: number =  isEnd ? maxScroll - actualScroll : (slideToShow * slideWidth) - actualScroll
    
    this.slideScroll(howMuchToScroll)
  }

  
  slideScroll(scroll:number) {
    const slider: HTMLElement = this.slider.nativeElement
    var sequenceObj: any = {};
    var seconds = 0.25;
    var startingScrollPosition = slider.scrollLeft;

    sequenceObj.progress = (percentage: number) => {
      slider.scrollLeft = startingScrollPosition + percentage*scroll;
    }

    sequenceObj.done = () => {
      slider.scrollLeft = startingScrollPosition + scroll
    }

    this.animate(sequenceObj, seconds);
  }


  /**
  * @param callbackObj Object An object with callbacks in .start, .progress, and .done
  * @param duration Integer Total duration in seconds
  */
  animate(callbackObj: any, duration: number) {
    const requestAnimationFrame = window.requestAnimationFrame
    let startTime = 0, percentage = 0, animationTime = 0;

    duration = duration*1000 || 1000;

    const animation: FrameRequestCallback = function(timestamp) {

      if (startTime === 0) {
          startTime = timestamp;
        } else {
          animationTime = timestamp - startTime;
        }

      if (typeof callbackObj.start === 'function' && startTime === timestamp) {
        callbackObj.start();

        requestAnimationFrame(animation);
      } else if (animationTime < duration) {
        if (typeof callbackObj.progress === 'function') {
          percentage = animationTime / duration;
          callbackObj.progress(percentage);
        }

        requestAnimationFrame(animation);
      } else if (typeof callbackObj.done === 'function'){
        callbackObj.done();
      }
    };

    return requestAnimationFrame(animation);
  }


}
