
<button mat-icon-button class="close__modale" (click)="closeModal()">
  <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="close"></mat-icon>
</button>
<span class="warning_refus">Avant de passer à côté de cette vente, tentez de trouver une solution alternative avec le client. <br/>Son numéro : <b>{{data?.address?.customer_telephone}}</b></span>
<span class="label_refus">Pas de solution possible ?</span>
<mat-form-field class="gestion__input">
    <mat-select 
        id="motif-select" 
        (selectionChange)="changeMotifSelect($event.value)"
        >
        <mat-option *ngFor="let opt of listMotif" [value]="opt">{{opt}}</mat-option>
    </mat-select>       
</mat-form-field>  
<button mat-button class="confirm refuse_order_cancel" (click)="confirmCancel()" [disabled]="disabledButton">Annuler la commande</button>
