<div class="catalog-modale--item">
    <section class="item--head">
        <button mat-icon-button class="close__modale" (click)="closeModal()"></button>
    </section>
    <section class="text">
        <div class="image_container">
            <img src="assets/images/deco/attention_blue.png" alt="">
        </div>
        <p>
            Attention, un certain nombre
            de vos produits sont actuellement
            désactivés à la vente !
        </p>
    </section>
    <section class="bottom">
        <div class="gradient">
            <img src="assets/images/deco/right_arrow.png" alt="">
            <mat-slide-toggle [checked]="false"></mat-slide-toggle>
        </div>
    </section>
</div>