import { Component, OnInit } from '@angular/core';
import { CatalogProductService } from 'src/app/utils/catalog/catalog-product.service';
import { first, map } from 'rxjs/operators';
import { CatalogDataConfig, CatalogProductDetails } from 'src/app/models/catalog';
import { Observable } from 'rxjs';
import { MessageService } from 'src/app/utils/message/message.service';
import { Router } from '@angular/router';
import * as moment from 'moment';


type Tab = "config" | "price" | "attr" | "market";

@Component({
  selector: 'app-simple',
  templateUrl: './simple.component.html',
  styles: [
  ]
})
export class CatalogAddSimpleComponent implements OnInit {
  currentTab: Tab = "config";
  loader: boolean = true;
  dataConfig: CatalogProductDetails = {
    dataConfig: {
      name: "",
      ref: "",
      majority_validation: false,
      price: "",
      sku: "",
      weight: "",
      images: [],
      qty: "",  
      description: "",
      categoryMerchant: "",
      tax: "",
      attribute_set_id: "",
      shipping_price: "",
      special_from_date: "",
      special_price: "",
      special_shipping_price: "",
      special_shipping_price_from: "",
      special_shipping_price_to: "",
      special_to_date: "",
      attributes: [],
      categories: [],
      marketing: [],
    },
    dataForm: {
      tax: [],
      categories: [],
      attributes: [],
      marketing: [],
      attribute_set: [],
    },
  }

  constructor(
    private catalogProductService: CatalogProductService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.catalogProductService.getAddProductDetails().pipe(first()).subscribe(
      data => {
        this.dataConfig.dataForm = data.dataForm
        //Fix pour les attributs de type boolean => select single
        this.dataConfig.dataForm.attributes.forEach(elem => {
          if(elem.type == 'boolean'){
            elem.values.forEach(value => {
              value.value = value.value.toString()
            })
          }
        })
        this.loader = false;
      },
      error => {
        this.loader = false;
      }
    )
  }

  onChangeSetAttribute(attribute_set_id: string): void {
    this.loader = true;
    this.catalogProductService.getAddProductEditAttributeSet(attribute_set_id).pipe(first()).subscribe(
      data => {
        this.dataConfig.dataForm.attributes = data.dataForm.attributes
        this.loader = false;  
      },
      error => {
        this.loader = false;
      }
    )
  }

  createProduct() {
    let copyDataConfig: CatalogDataConfig;
    copyDataConfig = {
      ...this.dataConfig.dataConfig,
      special_from_date: (this.dataConfig.dataConfig.special_from_date) ? moment(this.dataConfig.dataConfig.special_from_date).format('YYYY-MM-DD') : null,
      special_to_date: (this.dataConfig.dataConfig.special_to_date) ? moment(this.dataConfig.dataConfig.special_to_date).format('YYYY-MM-DD') : null,
      special_shipping_price_from: (this.dataConfig.dataConfig.special_shipping_price_from) ?  moment(this.dataConfig.dataConfig.special_shipping_price_from).format('YYYY-MM-DD') : null,
      special_shipping_price_to: (this.dataConfig.dataConfig.special_shipping_price_to) ?  moment(this.dataConfig.dataConfig.special_shipping_price_to).format('YYYY-MM-DD') : null,
    }
    
    //On vérifie avant que certains champs sont remplis
    if(copyDataConfig.name.length == 0){
      this.messageService.openSnackBar('Veuillez renseigner le nom du produit', 'error')
      return false;
    }
    if(copyDataConfig.ref.length == 0){
      this.messageService.openSnackBar('Veuillez renseigner la référence du produit', 'error')
      return false;
    }
    if(copyDataConfig.categories.length == 0){
      this.messageService.openSnackBar('Veuillez renseigner la catégorie du produit', 'error')
      return false;
    }
    if(copyDataConfig.categoryMerchant.length == 0){
      this.messageService.openSnackBar('Veuillez renseigner la catégorie marchande du produit', 'error')
      return false;
    }
    if(copyDataConfig.weight.length == 0){
      this.messageService.openSnackBar('Veuillez renseigner le poids du produit', 'error')
      return false;
    }
    if(copyDataConfig.price.length == 0){
      this.messageService.openSnackBar('Veuillez renseigner le prix du produit', 'error')
      return false;
    }
    
    this.loader = true;
    this.catalogProductService.postAddNewProduct(copyDataConfig).subscribe(
      success => {  
        this.router.navigate(['/catalog']) 
        this.messageService.openSnackBar('Produit ajouté avec succès', 'success')
        this.loader = false;
      },
      error => {
        this.messageService.openSnackBar('Echec lors de l\'ajout du produit', 'error')
        this.loader = false;
      }
    )
  }

  changeFiltre(ev: Event, tab: Tab):void {
    ev.preventDefault();
    this.currentTab = tab
  }

  onClickPrev(): void {
    switch (this.currentTab) {
      case 'price':
        this.currentTab = 'config'
        break;
      case 'attr':
        this.currentTab = 'price'
        break;
      case 'market':
        this.currentTab = 'attr'
        break;
    }
  }

  onClickNext(): void {
    switch (this.currentTab) {
      case 'config':
        this.currentTab = 'price'
        break;
      case 'price':
        this.currentTab = 'attr'
        break;
      case 'attr':
        this.currentTab = 'market'
        break;
    }
  }

}