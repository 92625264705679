import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { FraisCommissionModalComponent } from './frais-commission-modal.component';
import { AppSidebarModule } from 'src/app/_layout/app-sidebar/app-sidebar.module';
import { MaterialModule } from 'src/app/material.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    CustomPipeModule,
    AppSidebarModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  declarations: [
    FraisCommissionModalComponent
  ],
  exports: [
    FraisCommissionModalComponent
  ]
})
export class FraisCommissionModalModule { }
