import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bulle-avatar',
  templateUrl: './bulle-avatar.component.html',
  styles: []
})
export class BulleAvatarComponent implements OnInit {
  @Input() img: string

  constructor() { }

  ngOnInit() {
  }

}
