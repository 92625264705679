<!-- Visualisation d'une commande -->
<div *ngIf="order$ | async;else noOrder;">
  <div class="commande__details__timer">
    <div class="commande_details_infos">
      <div class="commande__details--id-container">
        <span class="commande__details--id">{{ editableOrderForQuantities.reference }}</span>
        <span class="commande__details--id-label">N° de commande</span>
        <span class="commande__details--idsuborder">{{ editableOrderForQuantities.order_increment_id }}</span>
        <ng-container *ngIf="(editableOrderForQuantities.shop_discount?.amount | stringToNumber) > 0">
          <div class="commande__details--discount">
            {{ editableOrderForQuantities.shop_discount?.rules_name }} : {{ editableOrderForQuantities.shop_discount?.amount | number:'1.2-2' }}€
          </div>
        </ng-container>
      </div>
      <div class="commande__details--multiboutique leader" >
        <a class="commande__details--multiboutique--button" (click)="openModaleMultiboutique()" *ngIf="editableOrderForQuantities.sub_orders_related.length > 0">
          <span>{{getStringAcceptedOrder() | async}}</span>
          <img src="assets/images/king.png" alt="" *ngIf="getOrderLeader() | async">
        </a>
      </div>
      <div class="time" *ngIf="editableOrderForQuantities.delivery_type != 'colissimo'">
        <span >Pour le {{ moment(editableOrderForQuantities.delivery_date).format("DD/MM/YY")}} à {{ editableOrderForQuantities | preparationHour | async | hour }}</span>
      </div>
      <category-price [price]="editableOrderForQuantities.total" [type]="editableOrderForQuantities.delivery_type"></category-price>
      <a mat-flat-button color="primary" [ngClass]="{ big: addClassButtonBig() | async }" class="commande__ready" [ngClass]="{isMajor: editableOrderForQuantities.majority_product == true}" (click)="ready()" [disabled]="(getDisableButton() | async) || isRequesting">
        <mat-icon aria-hidden="true" class="ok">done</mat-icon>
        <ng-container>{{getStringButtonOrder() | async}}</ng-container>
        <img src="/assets/images/-18.png" class="major_product_image" *ngIf="editableOrderForQuantities.majority_product == true" />
      </a>
    </div>
    <div class="timer__container" [style.width.%]="timerWidth$ | async"></div>
  </div>
  <div class="commande__phone" *ngIf="editableOrderForQuantities.address.customer_telephone.length > 0">
    {{getStringPhoneName() | async}}
  </div>
  <div class="historique__details__list">
    <div class="historique__details--menus" *ngIf="editableOrderForQuantities.items.menus.length > 0">
      <span class="category">Menu</span>
      <mat-list-item *ngFor="let menu of editableOrderForQuantities.items.menus; let last = last">
        <div class="line" [ngClass]="{ last: last }">
          <button (click)="changeQtyMenu(menu)" *ngIf="editMode && menu.qty > 0" class="line_less_button">-</button>
          <div class="line--qty menu--text">x{{menu.qty}}</div>
          <div class="line--name menu--text">{{menu.name}}</div>
          <div class="line--total menu--text">{{menu.total}} €</div>
        </div>
        <div class="menu__details">
            <ng-container *ngFor="let info of menu.bundle_info">
                <div class="menu__details--type">{{info.type}}</div>
                <ng-container *ngFor="let choice of info.choices">
                    <div class="menu__details--name">{{choice.name}}</div>
                </ng-container>
            </ng-container>
        </div>
      </mat-list-item>
    </div>
    <ng-container *ngIf="editableOrderForQuantities.items.categories.length > 0">
      <div class="historique__details--cat"  *ngFor="let cat of editableOrderForQuantities.items.categories">
        <span class="category">{{cat.type}}</span>
        <mat-list-item *ngFor="let choice of cat.choices; let last = last">
          <div class="line" [ngClass]="{ last: last }">
            <button (click)="changeQtyProduct(cat, choice)" *ngIf="editMode && choice.qty > 0" class="line_less_button">-</button>
            <div class="line--qty">x{{choice.qty}}</div>
            <div class="line--name">
              {{choice.name}}
              <span *ngIf="choice.config.length > 0">
                (
                  <span *ngFor="let conf of choice.config; let lastConfig = last">
                    {{conf}} <span *ngIf="!lastConfig">/</span> 
                  </span>
                )
              </span>
            </div>
            <div class="line--total">{{choice.total}} €</div>
          </div>
        </mat-list-item>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="editableOrderForQuantities.merchant_information">
    <div class="complementary__information">
      <span class="message">Message: {{ editableOrderForQuantities.merchant_information }}</span>
    </div>
  </ng-container>

  <div class="commande__actions">
    <div class="commande_actions_container">
      <a mat-flat-button color="none" class="commande__canceled" (click)="openModaleCancel()" [disabled]="isRequesting">
        <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="trash"></mat-icon> Annuler
      </a>
      <a *ngIf="!editMode" mat-flat-button color="secondary" class="edit_button" (click)="toggleEdit()" [disabled]="isRequesting">
        <mat-icon aria-hidden="true" class="ok">edit</mat-icon><span class="edit_button_label"> Modifier</span> 
      </a>
      <a *ngIf="editMode" mat-flat-button color="primary" class="commande__ready" (click)="acceptModifications()" [disabled]="isRequesting">
        <mat-icon aria-hidden="true" class="ok">done</mat-icon><span> Valider</span> 
      </a>
    </div>
    <a mat-flat-button color="none" class="commande__print" (click)="print()" [disabled]="getDisableButtonPrint() | async" [disabled]="isRequesting">
      <mat-icon aria-hidden="true" fontSet="fontello" fontIcon="print"></mat-icon> <span class="commande__print__text">Imprimer</span>
    </a>
  </div>
</div>

<ng-template #noOrder>
  <div class="commande__empty">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
