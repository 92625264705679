import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PrintService } from 'src/app/utils/print/print.service';

@Component({
  selector: 'edit-order-modal',
  templateUrl: './edit-order-modal.component.html',
  styles: []
})
export class EditOrderModalComponent implements OnInit {
  hasDiscount: boolean;
  constructor(
    public dialogRef: MatDialogRef<EditOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){}

  ngOnInit() {
    this.hasDiscount = this.data.shop_discount.rules_name.length > 0 || this.data.ocito_discount.rules_name.length > 0
  }

  acceptAndCloseModal() {
    this.dialogRef.close(true);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
