import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { Store } from '@ngrx/store';
import { PrintService } from '../print/print.service';
import { CatalogType } from 'src/app/models/catalog';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  constructor(
    private httpService: HttpService,
    private store: Store,
    private printService: PrintService,
  ) {}

  getCatalog(): Observable<CatalogType[]> {
    return this.httpService.apiRequest('get', 'shopkeeper/products', {}, {}, "rest/all/V1/")    
  }

  changeStatusProductCatalog(sku: string, status: number): Observable<CatalogType[]> {
    return this.httpService.apiRequest('post', 'shopkeeper/product/status', {sku, status}, {})    
  }
}