import { NgModule } from '@angular/core';
import { MomentPipe, MomentHourPipe, MomentDayPipe, MomentWeekPipe, MomentMonthPipe, daySidebarPipe, totalPipe, pluralPipe, restPipe, preparationHourPipe, preparationHourPipeImpure, stringToNumberPipe } from '.';



@NgModule({
  declarations: [
    MomentPipe,
    MomentHourPipe,
    MomentDayPipe,
    MomentWeekPipe,
    MomentMonthPipe,
    daySidebarPipe,
    pluralPipe,
    totalPipe,
    restPipe,
    preparationHourPipe,
    stringToNumberPipe,
    preparationHourPipeImpure
  ],
  imports: [],
  exports: [
    MomentPipe,
    MomentHourPipe,
    MomentDayPipe,
    MomentWeekPipe,
    MomentMonthPipe,
    daySidebarPipe,
    pluralPipe,
    totalPipe,
    stringToNumberPipe,
    restPipe,
    preparationHourPipe,
    preparationHourPipeImpure
  ]
})
export class CustomPipeModule { }
