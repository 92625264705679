import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { selectProfilShopName } from 'src/app/reducers/profil/profil.selector';
import { Store } from '@ngrx/store';
import { ShopService } from 'src/app/utils/shop/shop.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styles: [
  ]
})

export class ShopComponent implements OnInit {
  shopName$: Observable<string> = this.store.select(selectProfilShopName)
  currentTab: "boutique" | "horaires" | "online" = "boutique"

  constructor(
    private store: Store,
    private shopService: ShopService
  ) { }

  ngOnInit(): void {
    this.shopService.getShop();
  }

  changeTab(ev: Event, tab):void {
    ev.preventDefault();
    this.currentTab = tab
  }

}
