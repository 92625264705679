import { Component, OnInit } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { Profil } from 'src/app/models/profil';
import { Observable, throwError, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectProfilById, selectProfilId } from 'src/app/reducers/profil/profil.selector';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { ProfileService } from 'src/app/utils/profile/profile.service';
import { MessageService } from 'src/app/utils/message/message.service';

@Component({
  selector: 'app-collaborateur-edit',
  templateUrl: './collaborateur-edit.component.html',
  styles: [
  ]
})
export class CollaborateurEditComponent implements OnInit {
  route$: Observable<ParamMap>
  profil$: Observable<Profil>
  currentProfil$: Observable<string> = this.store.select(selectProfilId)
  editForm: FormGroup;
  name = new FormControl('', [Validators.required])
  civility = new FormControl(null, [Validators.required])
  image: string = ""
  id: string
  edit: boolean
  isImageSaved: boolean;
  imageError: string = null;
  redirectUrl: string = "/admin";

  constructor(
    private profileService: ProfileService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.route$ = this.route.paramMap
    
    this.route$.subscribe(
      (params: ParamMap) => {
        this.edit = params.has('id')
        if(this.edit){
          // On va chercher dans le store le profil
          this.profil$ = this.store.pipe(
            select(selectProfilById, params.get('id')),
            catchError(error => throwError(error))
          )
        }
        else {
          const newProfil: Profil = {
            civility: 1,
            entity_id: "",
            manager_id: "",
            name: "",
            profil_picture: "/assets/images/avatar.png"
          }
          this.profil$ = of(newProfil)
        }
      }
    )
    
    //Initialisation du form
    this.editForm = this.fb.group({
      name: this.name,
      civility: this.civility,
      image: this.image
    })

    this.profil$.subscribe(
      profil => {
        this.name.setValue(profil.name)
        this.civility.setValue(profil.civility)
        this.image = profil.profil_picture
        this.id = profil.entity_id
      },
      error => {
        // pas de profil associe dans le store
        // On redirige vers /admin  
        this.router.navigate(['/admin'])
      }
    )
  }

  /**
   * Upload en base64
   * @param fileInput 
   */
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
        const max_size = 20971520;
        const allowed_types = ['image/png', 'image/jpeg'];
        const max_height = 15200;
        const max_width = 25600;

        if (fileInput.target.files[0].size > max_size) { 
          this.imageError = 'Taille maximum autorisée ' + max_size / 1000 + 'Mb';
          return false;
        }
        if (!allowed_types.includes(fileInput.target.files[0].type)) {
          this.imageError = 'Extensions autorisées : ( JPG | PNG )';
          return false;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = rs => {
                const img_height = rs.currentTarget['height'];
                const img_width = rs.currentTarget['width'];
                if (img_height > max_height && img_width > max_width) {
                    return false;
                } else {
                    const imgBase64Path = e.target.result;
                    this.image = imgBase64Path;
                    this.isImageSaved = true; 
                }
            };
        };
        reader.readAsDataURL(fileInput.target.files[0]);
        
    }
  }

  /**
   * Permet de savoir si l'on modifie/supprime notre profil
   */
  myProfil():Observable<boolean>{
    return this.currentProfil$.pipe(
      map((currentProfil) => {
        return this.route.snapshot.paramMap.get("id") == currentProfil
      })
    ) 
  }
  
  

  /**
   * Suppression d'un profil
   */
  delete(){
    this.profileService.deleteProfile(this.id).subscribe(    
      profile => {
        this.router.navigate(["/admin"])
        this.messageService.openSnackBar('Profil supprimé avec succès', 'success')
      }, error => {
        this.messageService.openSnackBar('Echec lors de la suppression du profil', 'error')
      }
    );
  }

  

  /**
   * A la sauvegarde édition/ajout
   */
  onSubmit(){
    const {name, civility} = this.editForm.value
    let imageUpload = this.isImageSaved ? this.image.split(',')[1] : null;
    if(this.edit){
      this.profil$.pipe(
        first()
      ).subscribe(
        profile => {
          this.profileService.editProfile(profile.entity_id, name, civility, imageUpload).subscribe(
            profile => {
              this.router.navigate(["/admin"])
              this.messageService.openSnackBar('Profil mis à jour avec succès', 'success')
            }
          );
        }, error => {
          this.messageService.openSnackBar('Echec lors de la mise à jour du profil', 'error')
        }
      )
    }else{
      this.profileService.addProfile(name, civility, imageUpload).subscribe(    
        profile => {  
          this.router.navigate(["/admin"])
          this.messageService.openSnackBar('Profil ajouté avec succès', 'success')
        },
        error => {
          this.router.navigate(["/admin"])
          this.messageService.openSnackBar('Echec lors de l\'ajout du profil', 'error')
        }
      );
    }
  }
}
