import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CatalogProductService } from 'src/app/utils/catalog/catalog-product.service';
import { forkJoin } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CatalogProductDetails } from 'src/app/models/catalog';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { MessageService } from 'src/app/utils/message/message.service';
import { Router } from '@angular/router';

interface DataAttributes  {
  attributes?: string[]
}

@Component({
  selector: 'app-catalog-prices',
  templateUrl: './prices.component.html',
  styles: [
  ]
})
export class CatalogPricesComponent implements OnInit {
  loader: boolean = true;
  formGroupPrices: FormGroup;

  price = new FormControl();
  special_price  = new FormControl();
  special_from_date  = new FormControl();
  special_to_date = new FormControl();
  shipping_price  = new FormControl();
  special_shipping_price = new FormControl();
  special_shipping_price_from  = new FormControl();
  special_shipping_price_to = new FormControl();


  @Input() data: CatalogProductDetails
  @Input() isEdit: boolean
  @Output() dataChange = new EventEmitter<CatalogProductDetails>();

  constructor(
    private formBuilder: FormBuilder,
    private catalogProductService: CatalogProductService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // Valeur par défault
    this.data = {...this.data}
    this.dataChange.emit(this.data);

    
    this.price.setValue(this.data.dataConfig.price)
    this.special_price.setValue(this.data.dataConfig.special_price)
    this.special_from_date.setValue(moment(this.data.dataConfig.special_from_date).toISOString())
    this.special_to_date.setValue(moment(this.data.dataConfig.special_to_date).toISOString())
    this.shipping_price.setValue(this.data.dataConfig.shipping_price)
    this.special_shipping_price.setValue(this.data.dataConfig.special_shipping_price)
    this.special_shipping_price_from.setValue(moment(this.data.dataConfig.special_shipping_price_from).toISOString())
    this.special_shipping_price_to.setValue(moment(this.data.dataConfig.special_shipping_price_to).toISOString())

    this.formGroupPrices = this.formBuilder.group({
      price: this.price,
      special_price : this.special_price,
      special_from_date : this.special_from_date,
      special_to_date  : this.special_to_date,
      shipping_price: this.shipping_price,
      special_shipping_price : this.special_shipping_price,
      special_shipping_price_from  : this.special_shipping_price_from,
      special_shipping_price_to  : this.special_shipping_price_to
    })
  }

  changeInputText() {
    this.data = {...this.data, dataConfig: {...this.data.dataConfig, ...this.formGroupPrices.value}}
    this.dataChange.emit(this.data)
  }

  onSelectDatePicker(value) {
    this.data = {...this.data, dataConfig: {...this.data.dataConfig, ...this.formGroupPrices.value}}
    this.dataChange.emit(this.data)
  } 

  deleteProduct(sku: string) {
    this.catalogProductService.removeProduct(sku).pipe(first()).subscribe(
      data => {
        this.messageService.openSnackBar('Produit supprimé avec succès', 'success')
        this.router.navigate(['/catalog']);
      },
      error => {
        this.messageService.openSnackBar(error, 'error')
      }
    )
  }
}
