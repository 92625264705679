<div class="login-container forgot-password-container">
  <form (ngSubmit)="onSubmit()" [formGroup]="forgotPasswordForm">
    <img class="logo" src='/assets/images/logo-lagalerie.png' />
    <div class="form_container">
      <mat-form-field>
        <mat-label>Utilisateur</mat-label>
        <input matInput type="text" formControlName="resetLogin">
        <mat-error *ngIf="resetLogin.invalid">{{getErrorMessage(resetLogin)}}</mat-error>
      </mat-form-field>
      <a class="forgot__password" routerLink="/auth">Retour à l'écran de connexion</a>
    </div>
    <button mat-flat-button color="primary" type="submit" [disabled]="forgotPasswordForm.invalid"
      class="button__connect">
      Envoyer l'email de réinitialisation
    </button>
  </form>
</div>