import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppNavigationComponent } from '../app-navigation/app-navigation.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
})
export class AppHeaderComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    
  }

  /**
   * Pour cacher les liens commandes et historiques sur le dashboard
   */
  showLinksMenu(): boolean{
    let route = ['/commande', '/historique', '/reporting']
    const isInArray = (elem: string) => this.router.url.includes(elem) 
    return route.some(isInArray)
  }

  openMenu(): void {
    let dialogRef = this.dialog.open(AppNavigationComponent, {
      panelClass: 'header__dialog',
    });
  }

}
