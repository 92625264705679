import { Commande, CommandeMultiboutique } from 'src/app/models/commande';


const ExempleCommande4: Commande = {
    "history_date" : "2020-04-24 12:00:00",
    "sub_order_id": "4",
    "reference": "RE_11111",
    "order_id": "2",
    "order_increment_id": "2",
    "total": "10,00",
    "status": "accepted",
    "is_primary": "1",
    "shop_discount": {
        "amount": "",
        "rules_name": ""
    },
    "fees_rate": "30",
    "majority_product": false,
    "refund_request": 1,
    "refuse_reason": {},
    "remaining_refund_items": true,
    "global_status": "pending_validation",
    "delivery_type": "home_delivery",
    "delivery_date": "2020-04-24 12:00:00",
    "is_partial": "0",
    "sub_orders_related": [
        {'id_shop': '4', 'name': 'TRAN', 'is_primary': '1', 'status': 'accepted', 'app_status': 'accepted'}, 
        {'id_shop': '42', 'name': 'toto2', 'is_primary': '0', 'status': 'refused', 'app_status': 'pending'},
        {'id_shop': '44', 'name': 'toto3', 'is_primary': '0', 'status': 'pending', 'app_status': 'pending'},
        {'id_shop': '45', 'name': 'toto4', 'is_primary': '0', 'status': 'pending', 'app_status': 'pending'},
    ],
    "user_image": "",
    "user_name": "test",
    "shipping_amount": "2,00",
    "coupon_code": [
        {
            "description": 'Premier code',
            "simple_action": 'by_percent',
            "discount_amount": '10.0000'
        },
        {
            "description": 'Second code',
            "simple_action": 'cart_fixed',
            "discount_amount": '3.0000'
        }
    ],
    "discount_amount": "000",
    "address": {
        "city": "Lille",
        "customer_firstname": "Rémy",   
        "customer_lastname": "Cousin",
        "postcode": "59000",
        "customer_telephone": "0678245858",
        "street_line_1": "22 rue de Bergues",
        "street_line_2": ""
    },
    "merchant_information" : "Code de fidélité : XXXXX",
    "items": {
        "categories": [
            {
                "type": "Entrée",
                "choices": [
                    {
                        "config": [],
                        "item_id": "1",
                        "name": "Sandwish a la fraise",
                        "total": "10,00",
                        "qty": "1",
                        "product_type": "simple",
                        "product_id": "1",
                        "qty_remaining": "1",
                        "unit_price": "1"
                    },
                    {
                        "config": [],
                        "item_id": "18",
                        "name": "Glace vanille",
                        "total": "9,46",
                        "qty": "2",
                        "product_type": "simple",
                        "product_id": "35",
                        "qty_remaining": "1",
                        "unit_price": "1"
                    }
                ]
            },
            {
                "type": "Boisson",
                "choices": [
                    {
                        "config": [],
                        "item_id": "5",
                        "name": "Fanta (Taille: L)",
                        "total": "10,00",
                        "qty": "2",
                        "product_type": "simple",
                        "product_id": "38888",
                        "qty_remaining": "1",
                        "unit_price": "1"
                    },
                ]
            }
        ],
        "menus": [
            {
                "item_id": "3",
                "name": "Menu Big Mac",
                "total": "9,50",
                "qty": "1",
                "product_type": "bundle",
                "category": "Menu",
                "qty_remaining": "1",
                "product_id": "38",
                "unit_price": "1",
                "bundle_info": [
                    {
                        "type": "Burger",
                        "choices": [
                            {
                                "item_id": "4",
                                "name": "Big Mac (Taille: L)",
                                "qty": 1,
                            }
                        ]
                    },
                    {
                        "type": "Boisson",
                        "choices": [
                            {
                                "item_id": "5",
                                "name": "Fanta (Taille: L)",
                                "qty": 1                        
                            }
                        ]
                    }
                ]
            }
        ]
    }
}

export function makeAnExemple2(
    sub_order_id:string,
    delivery_date: string,
    status: string,
    delivery_type: string,
    sub_orders_related: CommandeMultiboutique[]
    ): Commande {
  return {...ExempleCommande4, sub_order_id, delivery_date, status, delivery_type, sub_orders_related}
}
