import { Component, OnInit } from '@angular/core';


type Tab = "config" | "attr" | "products" | "market";

@Component({
  selector: 'app-pack',
  templateUrl: './pack.component.html',
  styles: [
  ]
})
export class CatalogAddPackComponent implements OnInit {

  currentTab: Tab = "config";
  dataConfig = {
    name: "",
    ref: "",
    price: "",
    tax: "",
    categories: [],
    weight: "",
    qty: "",
    description: "",
    attributes: [],
    marketing: []
  }

  constructor() { }

  ngOnInit(): void {
    
  }

  createProduct(): void {
  }

  changeFiltre(ev: Event, tab: Tab):void {
    ev.preventDefault();
    this.currentTab = tab
  }

}
