import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PrintService } from 'src/app/utils/print/print.service';

@Component({
  selector: 'catalog-modal',
  templateUrl: './catalog-modal.component.html',
  styles: []
})
export class CatalogModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CatalogModalComponent>
    ) { }

  ngOnInit() {
    
  }

  closeModal() {
    this.dialogRef.close();
  }
}
