import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  isLoggedIn: boolean = false;
  redirectUrl: string = "/commande";

  
  constructor(
    private httpService: HttpService,
  ) {
  }

  adminGetShops(): Observable<any> {
    return this.httpService.apiRequest('get', 'shops/getlist')
  }

  adminSelectShop(id: number): Observable<any> {
    let obj = {
      "shop_id": id
    }
    return this.httpService.apiRequest('post', 'adminapp/select', obj)
  }

  fetchProfiles(): Observable<any> {
    return this.httpService.apiRequest('get', 'shopkeeper/profile/all')
  }

  getShopInfos(): Observable<any> {
    return this.httpService.apiRequest('get', 'shopkeeper/user/info')
  }

  editProfile(id: string, name: string, civility: number, image: string): Observable<any> {
    let obj = {name, civility, image}
    return this.httpService.apiRequest('put', `shopkeeper/profile/${id}`, obj)
  }

  deleteProfile(id: string): Observable<any> {
    return this.httpService.apiRequest('delete', `shopkeeper/profile/${id}`, {})
  }

  addProfile(name: string, civility: number, image?: string): Observable<any> {
    let obj = image ? {name, civility, image} : {name, civility}
    
    return this.httpService.apiRequest('post', `shopkeeper/profile/`, obj)
  }

}