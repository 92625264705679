import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, combineAll, map, switchMapTo, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, Subscription, timer } from 'rxjs';
import { Commande } from 'src/app/models/commande';
import { selectCommandeByIdAndType } from 'src/app/reducers/commande/commande.selector';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { MatDialog } from '@angular/material/dialog';
import { CancelOrderModalComponent } from 'src/app/_components/cancel-order-modal/cancel-order-modal.component';
import { PrintService } from 'src/app/utils/print/print.service';
import { ColissimoModalComponent } from 'src/app/_components/colissimo-modal/colissimo-modal.component';
import { ColissimoService } from 'src/app/utils/colissimo/colissimo.service';
import { MultiboutiqueListModalComponent } from 'src/app/_components/multiboutique-list/multiboutique-list-modal.component';
import { MultiboutiqueReadyModalComponent } from 'src/app/_components/multiboutique-ready/multiboutique-ready-modal.component';
import { setShowModalMultiboutiqueFirst } from 'src/app/reducers/settings/settings.selector';
import { selectProfilShopId } from 'src/app/reducers/profil/profil.selector';
import { MultiboutiqueCancelModalComponent } from 'src/app/_components/multiboutique-cancel/multiboutique-cancel-modal.component';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ChecklistModalComponent } from 'src/app/_components/checklist-modal/checklist-modal.component';
import * as moment from 'moment';
import { EditOrderModalComponent } from 'src/app/_components/edit-order-modal/edit-order-modal.component';
import { EditOrderNoProductLeftModal } from 'src/app/_components/edit-order-modal/edit-order-no-product-left-modal/edit-order-no-product-left-modal.component';
import { MessageService } from 'src/app/utils/message/message.service';
import { updateOrderQuantities } from 'src/app/reducers/commande/commande.action';


const STORAGE_KEY = "ocito_app_profil"

@Component({
  selector: 'commande-details',
  templateUrl: './commande-details.component.html',
  styles: [
  ],
  host: {
    class: 'commande__details'
  }
})
export class CommandeDetailsComponent implements OnInit, OnDestroy {
  shopId$: Observable<string> = this.store.select(selectProfilShopId)
  order$: Observable<Commande>
  timerWidth$: Observable<number>;
  stringAcceptedOrder: string;
  subscription: Subscription;
  shopId = this.storage.get(STORAGE_KEY)?.shop.id_shop
  showModalMultiboutiqueFirst$: Observable<boolean> = this.store.select(setShowModalMultiboutiqueFirst);
  phoneName: '';
  moment: any = moment;
  editMode: boolean = false;
  editableOrderForQuantities: Commande
  isRequesting: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private commandeService: CommandesService,
    private colissimoService: ColissimoService,
    private messageService: MessageService,
    public dialog: MatDialog,
    public printService: PrintService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  ngOnInit() {
    // Récupère dans le store la commande en fonction de l'id passé en param
    this.order$ = this.route.paramMap.pipe(
      switchMap(
        param => this.store.select(selectCommandeByIdAndType, {id: param.get('id'), type: 'accepted'})
      )
    )
    this.subscription = this.order$.subscribe(
      order => {
        this.editMode = false;
        this.editableOrderForQuantities = this.editMode ? this.editableOrderForQuantities : order
        this.getTimer(order)
        this.showModalCancelOrderMulti(order)
      }
    )
  }

  /**
   * Permet d'afficher la modale d'annulation d'une commande
   * que l'on aurait accepté mais qui a été refusé par un autre commerçant
   */
  showModalCancelOrderMulti(commande: Commande){
    //Si la commande a le status annulé + est multiboutique + n'est pas partielle
    if(commande?.status == 'canceled' && commande.sub_orders_related.length > 0 && commande.is_partial == '0'){
      //Si le shop actuel a déjà accepté
      let findIndexShop = commande.sub_orders_related.findIndex(el => {
        return el.id_shop === this.shopId
      })
      if(findIndexShop !== -1){
        if(commande.sub_orders_related[findIndexShop].app_status === 'accepted'){
          this.commandeService.changeAppStatusShop(commande.sub_order_id, 'canceled').subscribe(
            _ => {
              this.router.navigate(['commande'])
              const dialogRef = this.dialog.open(MultiboutiqueCancelModalComponent, {
                panelClass: "modal__ticket",
                disableClose: false,
                data: commande
              });
            }
          )
        }
      }
    }
  }

  /**
   * Désactivation du boutton terminé
   */
  getDisableButton(): Observable<boolean> {
    return this.order$.pipe(
      take(1),
      map(order => {
        //Seulement pour les multicommandes et colissimo
        if((order.sub_orders_related.length > 0 && order.delivery_type == 'colissimo') || (order.sub_orders_related.length > 0 && order.is_partial == "0")){
            //on check si personne n'est chef de la commande
            let someoneIsPrimary = false;
            order.sub_orders_related.forEach(el => {
              if(el.is_primary == '1'){
                someoneIsPrimary = true;
              }
            })

            //on check si tout le monde a intéragit avec la commande
            let allInteracted = true;
            if(order.delivery_type == "colissimo"){
              order.sub_orders_related.forEach(el => {
                if(el.status == 'pending' || el.status == 'accepted'){
                  allInteracted = false;
                }
              })
            }
            else{
              order.sub_orders_related.forEach(el => {
                if(el.status == 'pending' || el.status == 'new'){
                  allInteracted = false;
                }
              })
            }

            //Si le shop actuel est chef de la commande
            let findIndexShop = order.sub_orders_related.findIndex(el => {
              return el.id_shop === this.shopId
            })
            let currentShopLeader = false;
            if(findIndexShop !== -1){
              currentShopLeader = (order.sub_orders_related[findIndexShop].is_primary === '1')
            }


            /**
             * BOUTON READY
             */
            //Si je suis leader et que tout le monde n'a pas encore intéragit ou que quelqu'un d'autre est déjà leader et que tout le monde n'a pas encore intéragit
            if((currentShopLeader && !allInteracted) || (someoneIsPrimary && !currentShopLeader && allInteracted) || (order.delivery_type !== "colissimo" && !allInteracted) || (order.delivery_type == "colissimo" && !allInteracted && order.is_partial == "0" && order.global_status !== "accepted")){
              return true
            }else{
              return false
            }
        }
        else return false
      })
    )
  }

  /**
   * Désactivation du boutton impression
   */
  getDisableButtonPrint(): Observable<boolean> {
    return this.order$.pipe(
      take(1),
      map(order => {
        //Seulement pour les multicommandes et colissimo
        if((order.sub_orders_related.length > 0 && order.delivery_type == 'colissimo') || (order.sub_orders_related.length > 0 && order.is_partial == "0")){
            //on check si personne n'est chef de la commande
            let someoneIsPrimary = false;
            order.sub_orders_related.forEach(el => {
              if(el.is_primary == '1'){
                someoneIsPrimary = true;
              }
            })

            //Si le shop actuel est chef de la commande
            let findIndexShop = order.sub_orders_related.findIndex(el => {
              return el.id_shop === this.shopId
            })
            let currentShopLeader = false;
            if(findIndexShop !== -1){
              currentShopLeader = (order.sub_orders_related[findIndexShop].is_primary === '1')
            }

            /**
             * BOUTON D'IMPRESSION
             */
            if(currentShopLeader || !someoneIsPrimary){
              return false;
            }else{
              return true
            }
        }
        else return false
      })
    )
  }

  /**
   * Savoir si on est leader de la commande
   */
  getOrderLeader(): Observable<boolean> {
    return this.order$.pipe(
      take(1),
      map(order => {
        if(order.sub_orders_related.length > 0 && order.delivery_type == 'colissimo'){
          let findIndexShop = order.sub_orders_related.findIndex(el => {
            return el.id_shop === this.shopId
          })
          if(findIndexShop !== -1){
            if(order.sub_orders_related[findIndexShop].is_primary === '1'){
              return true;
            }
          }
        }
      })
    )
  }

  /**
   * Nombre de personne qui a accepté la commande
   */
  getStringAcceptedOrder(): Observable<string>{
    return this.order$.pipe(
      take(1),
      map(order => {
        if(order.sub_orders_related.length > 0){
          let numberAcceptedOrder = 0;
          order.sub_orders_related.forEach(el => {
            if(el.status == 'accepted' || el.status == 'pending_ready_colissimo' || el.status == 'ready' || el.status == 'shipped')
              numberAcceptedOrder++
          })
          return `${numberAcceptedOrder}/${order.sub_orders_related.length} acceptés`
        }
      })
    )
  }

  getStringPhoneName(): Observable<string>{
    return this.order$.pipe(
      take(1),
      map(order => {
        return `Client : ${order.address.customer_firstname} ${order.address.customer_lastname}. (${order.address.customer_telephone})`
      })
    )
  }


  getStringButtonOrder(): Observable<string>{
    return this.order$.pipe(
      take(1),
      map(order => {
        // Si commande multiboutique et colissimo
        if(order.sub_orders_related.length > 0 && order.delivery_type == 'colissimo'){

          //on check si personne n'est chef de la commande
          let someoneIsPrimary = false;
          order.sub_orders_related.forEach(el => {
            if(el.is_primary == '1'){
              someoneIsPrimary = true;
            }
          })

          //on check si tous les autres ont accepté
          let allAccepted = true;
          order.sub_orders_related.forEach(el => {
            if(el.status !== 'accepted' && el.status !== 'pending_ready_colissimo'){
              allAccepted = false;
            }
          })

          //on check si tout le monde a intéragit avec la commande
          let allInteracted = true;
          order.sub_orders_related.forEach(el => {
            if(el.status == 'pending'){
              allInteracted = false;
            }
          })

          //Si le shop actuel est chef de la commande
          let findIndexShop = order.sub_orders_related.findIndex(el => {
            return el.id_shop === this.shopId
          })
          let currentShopLeader = false;
          if(findIndexShop !== -1){
            currentShopLeader = (order.sub_orders_related[findIndexShop].is_primary === '1')
          }

          //Si personne n'est leader
          if(!someoneIsPrimary){
            return `Je suis prêt`
          }
          //Si je ne suis pas leader de la commande
          else if(!currentShopLeader && order.is_partial === "1"){
            return `Remettre au commerçant`
          }else if(!currentShopLeader && order.is_partial === "0"){
            return `Livrer au commerçant`
          }
          else{
            return `Expédier`
          }
        }
        else if(order.delivery_type !== 'colissimo'){
          return `Terminer`
        }
        else return `Expédier`
      })
    )
  }

  addClassButtonBig(): Observable<boolean>{
    return this.order$.pipe(
      take(1),
      map(order => {
        // Si commande multiboutique et colissimo
        if(order.sub_orders_related.length > 0 && order.delivery_type == 'colissimo'){
          //on check si personne n'est chef de la commande
          let someoneIsPrimary = false;
          order.sub_orders_related.forEach(el => {
            if(el.is_primary == '1'){
              someoneIsPrimary = true;
            }
          })

          //on check si tous les autres ont accepté
          let allAccepted = true;
          order.sub_orders_related.forEach(el => {
            if(el.status !== 'accepted' && el.status !== 'pending_ready_colissimo'){
              allAccepted = false;
            }
          })

          //on check si tout le monde a intéragit avec la commande
          let allInteracted = true;
          order.sub_orders_related.forEach(el => {
            if(el.status == 'pending'){
              allInteracted = false;
            }
          })

          //Si le shop actuel est chef de la commande
          let findIndexShop = order.sub_orders_related.findIndex(el => {
            return el.id_shop === this.shopId
          })
          let currentShopLeader = false;
          if(findIndexShop !== -1){
            currentShopLeader = (order.sub_orders_related[findIndexShop].is_primary === '1')
          }

          //Si je suis leader et que tout le monde a intéragit
          if((someoneIsPrimary && allInteracted && currentShopLeader) || !someoneIsPrimary){
            return false
          }
          //Si je ne suis pas leader de la commande
          else if(!currentShopLeader){
            return true
          }else{
            return false
          }
        }
      })
    )
  }

  ngOnDestroy(){
    this.subscription.unsubscribe()
  }

  getTimer(order: Commande){
    this.timerWidth$ = timer(0, 1000).pipe(
      switchMapTo(this.commandeService.timer(order))
    )
  }

  openModaleCancel(){
    const dialogRef = this.dialog.open(CancelOrderModalComponent, {
      panelClass: "modal__cancel",
      data: this.order$
    });
  }

  openModaleMultiboutique(){
    const dialogRef = this.dialog.open(MultiboutiqueListModalComponent, {
      panelClass: "modal__multiboutique",
      data: this.order$
    });
  }

  ready(){
    this.order$.pipe(
      take(1)
    ).subscribe(
      order => {
        //CHECKLIST MODALE
        let dialogRef = this.dialog.open(ChecklistModalComponent, {
          panelClass: "modal__checklist",
          data: order
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result){
            // MULTIBOUTIQUE
            if(order.sub_orders_related.length > 0){
              //COLISSIMO
              if(order.delivery_type === 'colissimo'){
                //on check si personne n'est chef de la commande
                let someoneIsPrimary = false;
                order.sub_orders_related.forEach(el => {
                  if(el.is_primary == '1'){
                    someoneIsPrimary = true;
                  }
                })

                let findIndexShop = order.sub_orders_related.findIndex(el => {
                  return el.id_shop === this.shopId
                })
                let currentShopLeader = false;
                if(findIndexShop !== -1){
                  currentShopLeader = (order.sub_orders_related[findIndexShop].is_primary === '1')
                }

                //Si je suis le premier à accepter alors j'ouvre la modale
                if(!someoneIsPrimary){
                  this.showModalMultiboutiqueFirst$.subscribe(bool => {
                    if(bool){
                      this.commandeService.readyOrderColissimoMulti(order)
                      const dialogRef = this.dialog.open(MultiboutiqueReadyModalComponent, {
                        panelClass: "modal__ticket",
                        data: this.order$,
                        disableClose: true
                      });
                    }
                  })
                }
                //Si je clique sur remettre au commerçant
                else if(!currentShopLeader){
                  this.commandeService.readyOrder(order)
                  this.router.navigate(['commande'])
                }
                else{
                  const dialogRef = this.dialog.open(ColissimoModalComponent, {
                    panelClass: "modal__colissimo",
                    data: this.order$
                  });
                  this.colissimoService.getPdfColissimo(order.sub_order_id)
                }
              }
              //Pour CLICK&COLLECT & DELIVERY
              else{
                this.commandeService.readyOrder(order)
                this.router.navigate(['commande'])
              }
            }
            //Si pas Multiboutique et Colissimo
            else if(order.sub_orders_related.length == 0 && order.delivery_type === 'colissimo'){
              const dialogRef = this.dialog.open(ColissimoModalComponent, {
                panelClass: "modal__colissimo",
                data: this.order$
              });
              this.colissimoService.getPdfColissimo(order.sub_order_id)
            }
            //Pour le reste
            else{
              this.commandeService.readyOrder(order)
              this.router.navigate(['commande'])
            }
          }
        });
      }
    )
  }

  changeQtyMenu(menu){
    //On cherche l'index du menu à modifier
    const indexMenu = this.editableOrderForQuantities.items.menus.findIndex(element => element === menu)
    let commandeIndex = this.editableOrderForQuantities
    //On cherche la valeur de la quantité à modifier
    let qtyMenu; 
    qtyMenu = parseInt(commandeIndex.items.menus[indexMenu].qty)
    qtyMenu = (qtyMenu - 1).toString()

    //On parse le tableau pour modifier la quantité du produit.
    this.editableOrderForQuantities = {
      ...this.editableOrderForQuantities, 
      items: {
        menus: [ 
          ...commandeIndex.items.menus.slice(0,indexMenu),
          {
            ...commandeIndex.items.menus[indexMenu], 
            qty: qtyMenu,
            total: (parseFloat(commandeIndex.items.menus[indexMenu].unit_price) * qtyMenu).toFixed(2)
          },
          ...commandeIndex.items.menus.slice(indexMenu+1),
        ],
        categories: [...commandeIndex.items.categories]
      },
      total: (parseFloat(this.editableOrderForQuantities.total.replace(',', '.')) - parseFloat(menu.unit_price)).toFixed(2)
    }
  }

  /**
   * 
   * @param slide 
   * @param category
   * @param product 
   */
  changeQtyProduct(category, product) {
    //On cherche l'index de la commande à modifier
    const indexCategory = this.editableOrderForQuantities.items.categories.findIndex(element => element === category)
    //On cherche l'index du produit à modifier
    const indexProduct = this.editableOrderForQuantities.items.categories[indexCategory].choices.findIndex(element => element === product)
    let commandeIndex = this.editableOrderForQuantities
    let categoryIndex = commandeIndex.items.categories[indexCategory]
    //On cherche la valeur de la quantité à modifier
    let qtyProduct; 
    qtyProduct = parseInt(categoryIndex.choices[indexProduct].qty)
    qtyProduct = (qtyProduct - 1).toString(); 


    //On parse le tableau pour modifier la quantité dans le tableau.
    this.editableOrderForQuantities = {
      ...this.editableOrderForQuantities, 
      items: {
        categories: [ 
          ...commandeIndex.items.categories.slice(0,indexCategory),
          {
            ...categoryIndex, 
            choices: [
              ...categoryIndex.choices.slice(0,indexProduct),
              {
                ...categoryIndex.choices[indexProduct], 
                qty: qtyProduct,
                total: (parseFloat(categoryIndex.choices[indexProduct].unit_price) * qtyProduct).toFixed(2)
              },
              ...categoryIndex.choices.slice(indexProduct+1),
            ]
          },
          ...commandeIndex.items.categories.slice(indexCategory+1),
        ],
        menus: [...commandeIndex.items.menus]
      },
      total: (parseFloat(this.editableOrderForQuantities.total.replace(',', '.')) - parseFloat(product.unit_price)).toFixed(2)
    }
  }

  checkIfQuantityTotalProductsIsNotNull(slide: Commande){
    let quantityTotalProductsIsNotNull = false;
    slide.items.categories.forEach(el => {
      el.choices.forEach(element => {
        if(parseInt(element.qty) > 0){
          quantityTotalProductsIsNotNull = true;
        }
      })
    })
    slide.items.menus.forEach(menu => {
      if(parseInt(menu.qty) > 0){
        quantityTotalProductsIsNotNull = true;
      }
    })
    return quantityTotalProductsIsNotNull;
  }

  toggleEdit(){
    this.editMode = true;
  }

  //Validation changement quantité
  acceptModifications(){
    /**
     * On vérifie qu'il reste des produits/menu dans la commande
     * Si la commande est en mode édition on ouvre la popup de changement de commande
     * Sinon on l'accepte directement
     */
    if(!this.checkIfQuantityTotalProductsIsNotNull(this.editableOrderForQuantities)){
      let dialogRef3 = this.dialog.open(EditOrderNoProductLeftModal, {
        panelClass: ["modal__cancel", "modal__refuse", "modal__edit__order"],
        disableClose: true,
        data: this.editableOrderForQuantities
      });
      dialogRef3.afterClosed().subscribe(confirm => {
        if(confirm)
          this.openModaleCancel()
        else{
          this.editMode = false;
          this.order$.subscribe(
            order => {
              this.editableOrderForQuantities = order
            }
          )
        }
      })
    }
    else if(this.editMode){
      let dialogRef2 = this.dialog.open(EditOrderModalComponent, {
        panelClass: ["modal__cancel", "modal__refuse", "modal__edit__order"],
        disableClose: true,
        data: this.editableOrderForQuantities
      });
      dialogRef2.afterClosed().subscribe(result => {
        if(result){
          let items = this.commandeService.setPayloadModificationQuantity(this.editableOrderForQuantities)
          this.isRequesting = true
          
          this.commandeService.editQuantityOrder(this.editableOrderForQuantities.sub_order_id, items).subscribe(    
            order => {
              this.commandeService.refreshAccepted();
              this.messageService.openSnackBar('La commande a été modifiée avec succès.', 'success')
            }, error => {
              this.messageService.openSnackBar('Echec lors de la modification de la commande', 'error')
            }, () => {
              this.isRequesting = false, 
              this.editMode = false
            }
          )
        }else{
          this.editMode = false;
          this.order$.subscribe(
            order => {
              this.editableOrderForQuantities = order
            }
          )
        }
      })
    } 
  }

  print(){
    this.order$.pipe(
      take(1)
    ).subscribe(
      order => {
        //on check si personne n'est chef de la commande
        let someoneIsPrimary = false;
        order.sub_orders_related.forEach(el => {
          if(el.is_primary == '1'){
            someoneIsPrimary = true;
          }
        })
        if(order.delivery_type === 'colissimo' && order.sub_orders_related.length > 0){
          //Si je suis le premier à accepter alors j'ouvre la modale
          if(!someoneIsPrimary){
            this.showModalMultiboutiqueFirst$.subscribe(bool => {
              if(bool){
                this.commandeService.readyOrderColissimoMulti(order)
                const dialogRef = this.dialog.open(MultiboutiqueReadyModalComponent, {
                  panelClass: "modal__ticket",
                  data: this.order$,
                  disableClose: true
                });
              }
            })
          }
        }
        //Pour les autres types de commandes
        else{
          this.printService.print(order,1)
        }
      }
    )
  }
}
