import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Commande } from 'src/app/models/commande';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { switchMap, tap, take, first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'cancel-order-modal',
  templateUrl: './refuse-order-modal.component.html',
  styles: []
})
export class RefuseOrderModalComponent implements OnInit {
  subscription: Subscription;
  listMotif: any[]
  disabledButton: boolean = true;
  selectedMotif: string;

  constructor(
    public router: Router,
    public commandeService: CommandesService,
    public dialogRef: MatDialogRef<RefuseOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Commande
    ) { }

  ngOnInit() {
    this.commandeService.getMotifRefusSelect().pipe(first()).subscribe(list => {
      this.listMotif = list.refuse_reason;
    })
  }

  confirmCancel() {
    this.dialogRef.close(true); 
    this.commandeService.refuseCommande(this.data, this.selectedMotif)
    this.router.navigate(['commande'])
  }

  changeMotifSelect(e) {
    this.selectedMotif = e;
    this.disabledButton = false;
  }


  closeModal() {
    this.dialogRef.close(false);
  }
}
