import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectProfilShopName } from 'src/app/reducers/profil/profil.selector';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from 'src/app/_components/search-modal/search-modal.component';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/utils/message/message.service';
import { Refund, RefundType } from 'src/app/models/refund';
import { RefundService } from 'src/app/utils/refund/refund.service';
import * as moment from 'moment'; 

interface RefundsDisplay {
  all: RefundType[], 
  pending: RefundType[], 
  finished: RefundType[],
  refused: RefundType[],
  canceled: RefundType[],
}

const emptyRefundsDisplay = {all:[], pending: [], finished: [], refused: [], canceled: []}

@Component({
  selector: 'app-reporting',
  templateUrl: './refund.component.html' 
})
export class RefundComponent implements OnInit { 
  shopName$: Observable<string> = this.store.select(selectProfilShopName)
  currentFilter: "all" | "pending" | "finished" | "refused" | "canceled" = "all"
  filteredRefunds: RefundsDisplay = {...emptyRefundsDisplay}
  originalRefunds: Refund[]
  search: string = ""
  loader: boolean = true;
  moment: any = moment;

  constructor(  
    private store: Store,
    public dialog: MatDialog,
    private refundService: RefundService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.refundService.getRefunds().pipe(
      first()
    ).subscribe(data => {
      this.originalRefunds = data;
      // on regroupe en fonction de l'état du produit
      this.filteredRefunds = this._filterData(data)
      this.loader = false;
    })
  }
  
  /**
   * Filtre les datas par catégories 
   */
  private _filterData(data: Refund[]): RefundsDisplay {
    let retour = {...emptyRefundsDisplay}
    data.forEach( element => {
        // le produit n'est pas en stock
        if(element.status == "processing" || element.status == "processing_sap" || element.status == "En cours de traitement" || element.status == "pending"){
          retour.pending = [...retour.pending, element]
        }
        // la demande est accepté 
        else if(element.status == "finished") {
          retour.finished = [...retour.finished, element]
        }
        // la demande est accepté 
        else if(element.status == "canceled") {
          retour.canceled = [...retour.canceled, element]
        }
        // la demande est refusé
        else {
          retour.refused = [...retour.refused, element]
        }
    })
    retour.all = data
    return retour
  }

  getTotal(filter: RefundType[]):number{
    return filter.length
  }

  noRefundTranslate(): string {
    switch (this.currentFilter) {
      case "pending":
        return "Aucune demande de remboursement en attente"
      case "finished":
        return "Aucune demande de remboursement terminée"
      case "refused":
        return "Aucune demande de remboursement refusée"
      case "canceled":
        return "Aucune demande de remboursement annulée"
      default:
        return "Aucune demande de remboursement"
    }
  }


  changeFiltre(ev: Event, filter):void {
    ev.preventDefault();
    this.currentFilter = filter
  }
  
  openSearchRefund() :void{        
    const dialogRef = this.dialog.open(SearchModalComponent, {
      panelClass: "modal__search",
      data: {
        onSubmit: (data) => this.onSubmit(data),
        label: 'Entrez la référence de la demande de remboursement à rechercher :'
      }
    });
  }

  cancelRefund(refund_id: number): void{
    this.refundService.cancelRefund(refund_id).pipe(  
      first()
    ).subscribe(data => {
      this.loader = true;
      this.messageService.openSnackBar('Demande de remboursement annulée avec succès', 'success')
      this.refundService.getRefunds().pipe(
        first()
      ).subscribe(data => {
        this.originalRefunds = data;
        // on regroupe en fonction de l'état du produit
        this.filteredRefunds = this._filterData(data)
        this.loader = false;
      })
    }, error => {
      this.messageService.openSnackBar(error, 'error')  
    })
  }

  private onSubmit(search: string): void {
    // this.search = search;
    // const newData = this._filterSearch()
    // this.filteredRefunds = this._filterData(newData)
  }

  // private _filterSearch(): Refund[] {
  //   return (
  //     this.originalRefunds.reduce((filtered:Refund[], element) => {
  //       const filteredRefunds = this.originalRefunds.filter(refund => {
  //         const regex = RegExp(this.search, 'gi')
  //         const test = regex.test(refund.reference)
  //         return test
  //       })
  //       if(filteredRefunds.length){
  //         filtered.push({refunds: filteredRefunds})
  //       }
  //       return filtered
  //     }, [])
  //   )
  // }

  removeSearch(): void {
    this.search = "";
    this.filteredRefunds = this._filterData(this.originalRefunds)
  }
}
