<form (ngSubmit)="onSubmit()" [formGroup]="settingsForm" id="settings-form">
  <mat-accordion>
    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Impression
        </mat-panel-title>
        <mat-panel-description>
          Configuration de l'imprimante
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="row">
        <div class="column small-12 medium-6">
          <mat-form-field>
            <mat-label>Adresse IP de l'imprimante</mat-label>
            <input formControlName="ip" matInput placeholder="192.168.x.x">
            <mat-error>{{getErrorMessage(ip)}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="column small-12">
          <div class="printer-status" [ngSwitch]="printService.status">
            <ng-container *ngSwitchCase="'connected'">
              <span class="printer-status--connected">
                <mat-icon aria-hidden="false">done</mat-icon> Imprimante connectée
              </span>
              <button type="button" mat-flat-button color="warn" class="red" (click)="disconnect()">Déconnecter</button>
              <button type="button" mat-flat-button color="primary" (click)="testPrint()">Impression &laquo;Test&raquo;</button>
              <button type="button" mat-flat-button color="primary" class="blue" (click)="reload()">Recharger l'application</button>
            </ng-container>
            <ng-container *ngSwitchCase="'pending'">
              <span class="printer-status--pending">
                <mat-icon aria-hidden="false">hourglass_empty</mat-icon> Imprimante en cours de connexion
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="'disconnected'">
              <span class="printer-status--disconnected">
                <mat-icon aria-hidden="false">cancel</mat-icon> Aucune imprimante connectée
              </span>
              <button type="button" mat-flat-button color="warn" (click)="connect()">Connecter</button>
              <button type="button" mat-flat-button color="primary" class="blue" (click)="reload()">Recharger l'application</button>
              <a [routerLink]="['help-print']">Aide à la configuration de l'imprimante</a>
            </ng-container>
          </div>

        </div>
      </div>
    </mat-expansion-panel>

  </mat-accordion>
  <div class="change-settings">
    <button mat-flat-button color="primary" type="submit">Modifier</button>
  </div>
</form>

<div class="test_button_container">
  <button type="button" mat-flat-button color="warn" class="sound_new_order" (click)="testSound()">Tester le son de nouvelle commande</button>
  <button type="button" mat-flat-button color="warn" (click)="testSoundWarning()">Tester le son de rappel</button>
</div>
