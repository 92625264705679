import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { selectStripeAccountId } from 'src/app/reducers/stripe/stripe.selector';
import { StripeService } from 'src/app/utils/stripe/stripe.service';
import { StripeModalTosComponent } from './modal-tos/stripe-modal-tos.component';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html'
})
export class StripeComponent implements OnInit {
  currentTab: "identite" | "entreprise" | "account" | "externe" | "attachments" = "identite";
  stripeIsValid: boolean = false;
  stripe_status_loader: boolean = true;
  loader: boolean = true
  dataIdentity: any;
  dataEntreprise: any;
  dataAccount: any;
  dataExtern: any;
  dataAttachments: any;
  dataFiles: any;

  constructor(
    public router: Router,
    public store: Store,
    private stripeService: StripeService,
    private dialog: MatDialog,
  ) { }

  openModalLegales(idAccount){
    const dialogRef = this.dialog.open(StripeModalTosComponent, {
      panelClass: "modal__ticket",
      disableClose: true,
      data: idAccount,
    });
  }

  ngOnInit(): void {
    this.store.pipe(
      take(1),
      select(selectStripeAccountId)
    ).subscribe(idAccount => {
      this.stripeService.fetchStripeGetAllPersons(idAccount).subscribe(
        payload => {
          let data = payload.data[0]; //TODO CHECK CURRENT PERSON
          if(data){
            let personId = data.id
            this.dataIdentity = {
              idAccount,
              idPerson: personId,
              first_name: data.first_name,
              last_name : data.last_name,
              email : data.email,
              day: data.dob.day,
              month: data.dob.month,
              year: data.dob.year,
              postal_code: data.address.postal_code,
              city: data.address.city,
              country: data.address.country,
              line1: data.address.line1,
              phone: data.phone,
              title: data.relationship.title
            }
            this.stripeService.fetchStripeAccount(idAccount).subscribe(
              data => {
                //On check si le profil stripe est complet ou s'il manque des informations
                this.stripeIsValid = data.requirements.currently_due.length === 0;
                //On ouvre la popup d'acceptation des conditions d'utilisation si pas ok
                if(data.future_requirements.currently_due.length > 0){
                  data.future_requirements.currently_due.find(el => {
                    if(el === "tos_acceptance.date"){
                      this.openModalLegales(idAccount)
                    }
                  })
                }
                if(data.requirements.currently_due.length > 0){
                  data.requirements.currently_due.find(el => {
                    if(el === "tos_acceptance.date"){
                      this.openModalLegales(idAccount)
                    }
                  })
                }


                this.dataEntreprise = {
                  idAccount,
                  idPerson: data.id,
                  mcc: data.business_profile?.mcc,
                  url: data.business_profile?.url,
                  tax_id: data.company?.tax_id_provided,
                  name: data.business_profile?.name
                }
                this.stripeService.fetchStripeGetExtern(idAccount).subscribe(
                  payload => {
                    let data = payload.data[0];
                    if(data){
                      this.dataExtern = {
                        idAccount,
                        account_holder_name: data.account_holder_name,
                        account_holder_type: data.account_holder_type,
                        country: data.country,
                        account_number: data.account_number,
                        last4: data.last4,
                      }
                    }else{
                      this.dataExtern = {idAccount}
                    }
                    this.loader = false;
                  }
                )
              }
            );
            this.dataFiles = {
              idAccount, 
              idPerson: personId,
              document_front: data.verification.additional_document.front,
              document_back: data.verification.additional_document.back,
            }
          }else{
            this.dataIdentity = { idAccount }
            this.dataEntreprise = { idAccount }
            this.dataAccount = { idAccount }
            this.dataAttachments = { idAccount }
            this.dataExtern = { idAccount }
            this.dataFiles = { idAccount }
            this.loader = false;
          }
          this.stripe_status_loader = false;
        }
      )
    })
  }

  changeTab(ev: Event, tab):void {
    ev.preventDefault();
    this.currentTab = tab
  }
}
