import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ShopState } from 'src/app/models/shop';
import { ShopService } from 'src/app/utils/shop/shop.service';
import { Store } from '@ngrx/store';
import { Observable, Subscription, forkJoin, of, zip } from 'rxjs';
import { selectShopDelay, selectQuota } from 'src/app/reducers/shop/shop.selector';
import { first, take, map } from 'rxjs/operators';

@Component({
  selector: 'app-online',
  templateUrl: './online.component.html',
  styles: [
  ]
})
export class OnlineComponent implements OnInit, OnDestroy {
  subscription: Subscription[] = []

  quota$: Observable<string> = this.store.select(selectQuota)
  quotaForm : FormGroup 
  quota = new FormControl(null, [
    Validators.required,
  ]);

  delayForm : FormGroup 
  delay = new FormControl(null, [
    Validators.required,
  ]);
  timeoutInput: any;
  intervalInput: any;
  increment: number = 5;
  delay$: Observable<string> = this.store.select(selectShopDelay)


  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private shopService: ShopService
  ) { }

  ngOnInit(): void {
    this.delayForm = this.formBuilder.group({
      delay: this.delay
    })
    this.quotaForm = this.formBuilder.group({
      quota: this.quota
    })

    
    this.subscription = [
      this.delay$.subscribe(delay => this.delay.setValue(parseInt(delay) + " minutes")),
      this.quota$.subscribe(quota => this.quota.setValue(quota)), 
    ] 
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe())
  }

  clearTimer(): void {
    clearTimeout(this.timeoutInput);
    clearInterval(this.intervalInput);
    this.increment = 5;
  }

  addInput(ev: Event): void {
    this._changeValue('add', ev)
  }

  removeInput(ev: Event): void {
    this._changeValue('remove', ev)
  }

  parseInt(str: string): number {
    return parseInt(str)
  }

  /**
   * Change la valeur de l'input
   */
  private _changeValue(type: string, ev: Event): void {
    const coeff = type === 'add' ? 1 : -1;
    const min = 5;
    const max = 90;

    // Modifie la valeur
    const setValue = () => {
      const val: number = Math.max(Math.min(parseInt(this.delay.value) + this.increment*coeff, max), min);
      this.delay.setValue(val+ " minutes")
    }
    setValue()

    // on augmente l'incrémentation au bout d'un certain temps
    const doubleIncrement = () => {
      this.timeoutInput = setTimeout(() => {
        this.increment = this.increment * 2;
        doubleIncrement()
      }, 500)
    }
    doubleIncrement();

    // on incrémente à interval régulier
    this.intervalInput = setInterval(() => {
      setValue()
    }, 150);
  }

  
  submitDelay(): void {
    if(this.delay.valid){
      this.shopService.setDelay(this.delay.value)
    }
    this.shopService.setQuota(this.quota.value)
  }

  submitFormQuota(): void {
    this.checkChange().pipe(
      take(1)
    ).subscribe(haveChange => {
      document.getElementById("id-quota").blur();
      if(haveChange)
        this.submitDelay()
    })
  }

  checkChange(): Observable<boolean> {
    return zip(this.delay$, this.quota$).pipe(
      take(1),
      map(([delay, quota]) => {
        return (
            (this.delay.valid && this.quota.valid) &&
            (
              (parseInt(this.delay.value) !== parseInt(delay)) ||
              (this.quota.value !== quota)
            )
          )
      }),
    )
  }
  
}
