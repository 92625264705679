import { Component, OnInit } from "@angular/core";
import { ProfileService } from "src/app/utils/profile/profile.service";
import { Store } from "@ngrx/store";
import {
  setAllProfil,
  resetProfil,
  chooseProfil,
  setManagerId,
} from "src/app/reducers/profil/profil.action";
import {
  selectProfilGetAll,
  selectProfilCallCenter,
  selectProfilShopName,
  selectProfilShop,
} from "src/app/reducers/profil/profil.selector";
import { Observable } from "rxjs";
import { Profil, Shop } from "src/app/models/profil";
import { Router } from "@angular/router";
import { first, map, startWith } from "rxjs/operators";
import { AuthService } from "src/app/utils/auth/auth.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-select-profil",
  templateUrl: "./select-profil.component.html",
})
export class SelectProfilComponent implements OnInit {
  profiles: Array<any> = [];
  shopCallCenter$: Observable<string> = this.store.select(
    selectProfilCallCenter
  );
  shopName$: Observable<string> = this.store.select(selectProfilShopName);
  choiceIndex: number = 0;
  shopList: Array<any> = [];
  adminShopChoice: number = -1;

  shopControl = new FormControl("");

  constructor(
    private profileService: ProfileService,
    private store: Store,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // On supprime l'utilisateur courant
    this.store.dispatch(resetProfil());

    // Appel API pour récupérer les profils
    this.profileService.fetchProfiles().subscribe((profils) => {
      // Mettre à jour les profils dans le store
      this.store.dispatch(setAllProfil({ profils }));
      this.profiles = profils;
    });

    this.profileService.adminGetShops().subscribe((shopList) => {
      if (!shopList) {
        this.getShopInfosForCommercant();
      } else {
        this.shopList = shopList;
      }
    });
  }

  getShopInfosForCommercant(): void {
    this.profileService.getShopInfos().subscribe((shop) => {
      // Mettre à jour les informations du magasin dans le store
      this.store.dispatch(setManagerId({ shop }));
    });
  }

  setChoiceIndex(index: number): void {
    this.choiceIndex = index;
  }

  _logout(): void {
    this.authService.logout();
  }

  start(): void {
    this.store.dispatch(
      chooseProfil({ profil: this.profiles[this.choiceIndex] })
    );
    this.router.navigate([this.profileService.redirectUrl]);
  }

  onChangeShop(event): void {
    const shopChoice = this.shopList.find((shop) => shop.id === event.value);
    this.shopControl.setValue(shopChoice.label);
    this.adminShopChoice = shopChoice.id;
  }

  get shopControlValue() {
    return this.shopControl.value;
  }

  get filteredShopList(): Array<any> {
    return this.shopList.filter((shop) =>
      shop.label.toLowerCase().includes(this.shopControlValue.toLowerCase())
    );
  }

  validateAdminChoice(): void {
    this.profileService
      .adminSelectShop(this.adminShopChoice)
      .subscribe((res) => {
        this.profileService.getShopInfos().subscribe((shop) => {
          // Mettre à jour les informations du magasin dans le store
          this.store.dispatch(setManagerId({ shop }));
          this.router.navigate([this.profileService.redirectUrl]);
        });
      });
  }
}
