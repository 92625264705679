import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommandesService } from 'src/app/utils/commandes/commandes.service';
import { Subscription, timer, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCommandeAccepted, selectCommandeAcceptedBefore, selectCommandeAcceptedWaiting } from 'src/app/reducers/commande/commande.selector';
import { CommandeDate } from 'src/app/models/commande';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { StripeModalComponent } from 'src/app/_components/stripe-modal/stripe-modal.component';
import { StripeService } from 'src/app/utils/stripe/stripe.service';
import { setStripeAccountId } from 'src/app/reducers/stripe/stripe.action';

@Component({
  selector: 'commande',
  templateUrl: './commande.component.html',
})
export class CommandeComponent implements OnInit, OnDestroy {
  interval: number = 120000
  subscription: Subscription;
  commandes$: Observable<CommandeDate[]> = this.store.select(selectCommandeAccepted)
  commandesBefore$: Observable<CommandeDate[]> = this.store.select(selectCommandeAcceptedBefore)
  commandesWaiting$: Observable<CommandeDate[]> = this.store.select(selectCommandeAcceptedWaiting)

  constructor(
    private store: Store,
    private commandesService: CommandesService,
    private dialog: MatDialog,
    private stripeService: StripeService
  ) { }

  ngOnInit() {
    this.subscription = timer(0, this.interval).subscribe(
      () => {
        this.commandesService.refreshAccepted()
      }
    )


    this.stripeService.fetchStripeAccountId().subscribe(
      data => {
        if(data.accountId.length > 0){
          this.store.dispatch(setStripeAccountId({accountId: data.accountId}))
        }
        if(data.currentlyDue.length > 0){
          const dialogRef = this.dialog.open(StripeModalComponent, {
            panelClass: "modal__ticket",
            disableClose: false,
          });
        }
      }
    );

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
