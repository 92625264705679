import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CatalogProductService } from 'src/app/utils/catalog/catalog-product.service';
import { forkJoin } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

interface DataAttributes  {
  attributes?: string[]
}

@Component({
  selector: 'app-catalog-quantities',
  templateUrl: './quantities.component.html',
  styles: [
  ]
})
export class CatalogQuantitiesComponent implements OnInit {
  loader: boolean = true;
  
  



  formGroup: FormGroup;
  attributes = new FormControl();
  allergenes = new FormControl();

  
  @Input() data: DataAttributes
  @Output() dataChange = new EventEmitter<DataAttributes>();

  constructor(
    private formBuilder: FormBuilder,
    private catalogProductService: CatalogProductService
  ) { }

  ngOnInit(): void {
    // Valeur par défault
    this.data = {...this.data}
    this.dataChange.emit(this.data);

    this.allergenes.setValue(this.data.attributes)
    this.formGroup = this.formBuilder.group({
      attributes: this.allergenes
    })

    // Récupération des options disponibles
    // const observable = forkJoin({
    //   attributes: this.catalogProductService.getAvailableAttributes(),
    //   allergenes: this.catalogProductService.getAvailableAllergenes(),
    // });
    // observable.subscribe({
    //   next: value => {
    //     this.availableAttributes = value.attributes;
    //     this.availableAllergenes = value.allergenes;
    //   },
    //   complete: () => this.loader = false,
    // });
  }

  changeSelect(e) {
    this.data = {...this.data, ...this.formGroup.value}
    this.dataChange.emit(this.data)
  }

}
