import { Component, OnInit } from '@angular/core';


type Tab = "config" | "attr" | "decli" | "images" | "prices" |  "quantities" | "market";

@Component({
  selector: 'app-configurable',
  templateUrl: './configurable.component.html',
  styles: [
  ]
})
export class CatalogAddConfigurableComponent implements OnInit {
  currentTab: Tab = "config";
  dataConfig = {
    name: "",
    ref: "",
    price: "",
    sku: "",
    tax: "",
    categories: [],
    weight: "",
    qty: "",
    description: "",
    attributes: [],
    marketing: []
  }

  constructor() { }

  ngOnInit(): void {
    
  }

  createProduct(): void {
  }

  changeFiltre(ev: Event, tab: Tab):void {
    ev.preventDefault();
    this.currentTab = tab
  }

}
