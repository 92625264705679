import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommandeModaleComponent } from './app-commande-modale.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CategoryPriceModule } from 'src/app/_components/category-price/category-price.module';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { MaterialModule } from 'src/app/material.module';
import { LoaderModule } from 'src/app/_components/loader/loader.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CarouselModule,
    CategoryPriceModule,
    CustomPipeModule,
    MaterialModule,
    LoaderModule
  ],
  declarations: [
    AppCommandeModaleComponent
  ],
  exports: [
    AppCommandeModaleComponent
  ]
})
export class AppCommandeModaleModule { }
