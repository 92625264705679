import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { CatalogDataForm, CatalogProductDetails, CatalogDataConfig } from 'src/app/models/catalog';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

const STORAGE_KEY = "ocito_app_profil"

@Injectable({
  providedIn: 'root',
})
export class CatalogProductService {
  constructor(
    private httpService: HttpService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  getEditProductDetails(idProduct: number): Observable<CatalogDataForm>{
    return this.httpService.apiRequest('get', `shopkeeper/product/${idProduct}`)
  }

  postEditProductDetails(dataConfig: CatalogDataConfig): Observable<CatalogDataForm>{
    const storeCode = this.storage.get(STORAGE_KEY)?.shop.store_code
    return this.httpService.apiRequest('post', `shopkeeper/product/`, {dataConfig}, {}, `rest/${storeCode}/V1/`)
  }

  getEditProductEditAttributeSet(idProduct: number, attribute_set_id: string){
    return this.httpService.apiRequest('get', `shopkeeper/product/${idProduct}/${parseInt(attribute_set_id)}`)
  }

  getAddProductDetails(): Observable<CatalogProductDetails>{
    return this.httpService.apiRequest('get', `shopkeeper/product/`) 
  }

  getAddProductEditAttributeSet(attribute_set_id: string): Observable<CatalogProductDetails>{
    return this.httpService.apiRequest('get', `shopkeeper/product/?attributeSetId=${parseInt(attribute_set_id)}`)
  }

  postAddNewProduct(dataConfig: CatalogDataConfig): Observable<any>{
    const storeCode = this.storage.get(STORAGE_KEY)?.shop.store_code
    return this.httpService.apiRequest('post', `shopkeeper/product/`, {dataConfig}, {}, `rest/${storeCode}/V1/`)
  }

  removeProduct(sku: string): Observable<any>{
    const storeCode = this.storage.get(STORAGE_KEY)?.shop.store_code
    return this.httpService.apiRequest('delete', `shopkeeper/product/${sku.trim()}`, {}, {}, `rest/${storeCode}/V1/`)
  }

  activeProducts(payload): Observable<any>{
    return this.httpService.apiRequest('post', `shopkeeper/product/status`, payload, {}, `rest/V2/`)
  }

  disableProducts(payload): Observable<any>{
    return this.httpService.apiRequest('post', `shopkeeper/product/status`, payload, {}, `rest/V2/`)
  }

  inStockProducts(payload): Observable<any>{
    return this.httpService.apiRequest('post', `shopkeeper/product/massRestock`, payload, {}, `rest/V2/`)
  }

  outStockProducts(payload): Observable<any>{
    return this.httpService.apiRequest('post', `shopkeeper/product/massDestock`, payload, {}, `rest/V2/`)
  }

  removeProducts(payload): Observable<any>{
    return this.httpService.apiRequest('post', `shopkeeper/product/sku`, payload, {}, `rest/V2/`)
  }

  getConfigurationsProduct(sku: string){
    return this.httpService.apiRequest('get', `shopkeeper/child/product/${sku.trim()}`, {}, {}, `/rest/all/V1/`)  
  }
}