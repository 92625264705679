import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { EditOrderModalComponent } from './edit-order-modal.component';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { EditOrderNoProductLeftModal } from './edit-order-no-product-left-modal/edit-order-no-product-left-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CustomPipeModule,
    RouterModule,
    MaterialModule
  ],
  declarations: [
    EditOrderModalComponent,
    EditOrderNoProductLeftModal
  ],
  exports: [
    EditOrderModalComponent,
    EditOrderNoProductLeftModal
  ]
})
export class EditOrderModalModule { }
