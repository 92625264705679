import { createReducer, on, Action } from '@ngrx/store';
import { StripeState } from 'src/app/models/stripe';
import * as StripeActions from './stripe.action'

export const initialState: StripeState = {
  accountId: "",
};

const _stripeReducer = createReducer(
  initialState,
  on(StripeActions.setStripeAccountId, 
    (state, { accountId }) => ({...state, accountId})),
);

export function stripeReducer(state: StripeState | undefined, action: Action) {
  return _stripeReducer(state, action);
}