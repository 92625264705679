import {
  Component,
  OnInit,
  OnDestroy,
  HostBinding,
  Inject,
} from "@angular/core";
import { AuthService } from "src/app/utils/auth/auth.service";
import { Store, select } from "@ngrx/store";
import { toggleDarkMode } from "src/app/reducers/colors/colors.action";
import { selectDarkMode } from "src/app/reducers/colors/colors.selector";
import { Observable, Subscription } from "rxjs";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { Router } from "@angular/router";
import { ProfileService } from "src/app/utils/profile/profile.service";
import { environment } from "src/environments/environment";

const STORAGE_KEY = "ocito_app_profil";

@Component({
  selector: "app-navigation",
  templateUrl: "./app-navigation.component.html",
})
export class AppNavigationComponent implements OnInit, OnDestroy {
  subscriptionDarkMode: Subscription;
  isDarkMode$: Observable<boolean> = this.store.select(selectDarkMode);
  guidePdf: any;

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private store: Store,
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  @HostBinding("class.dark") isDarkMode: boolean;

  ngOnDestroy(): void {
    this.subscriptionDarkMode.unsubscribe();
  }

  ngOnInit() {
    this.guidePdf = this.storage.get(STORAGE_KEY)?.shop.guide_pdf;
    this.subscriptionDarkMode = this.store
      .pipe(select(selectDarkMode))
      .subscribe((bool) => {
        this.isDarkMode = bool;
      });
  }

  _logout(): void {
    this.authService.logout();
  }

  _redirectCatalog(): void {
    this.profileService.getShopInfos().subscribe((profile) => {
      if (profile.use_new_app === "1") {
        this.authService.getSSOToken().subscribe((token) => {
          if (token) {
            window.open(
              `${environment.catalogUrl}autologin?ssoToken=${token}`,
              "_self"
            );
          }
        });
      } else this.router.navigate(["/catalog"]);
    });
  }

  /**
   * Activation/Desactivation du DarkMode
   */
  toggleDarkMode() {
    this.store.dispatch(toggleDarkMode());
  }
}
