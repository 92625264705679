import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/utils/auth/auth.service";
import { MessageService } from "src/app/utils/message/message.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-autologin",
  templateUrl: "./autologin.component.html",
})
export class AutoLoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // const isLoggedIn: boolean = this.authService.isLoggedIn;
    this.route.queryParams.subscribe((params) => {
      if (params.token) {
        // if (isLoggedIn) {
        //   this.authService.logout(true);
        // }
        this.authService
          .autologin(params.token)
          .subscribe(this.redirect.bind(this), this.showLoginError.bind(this));
      } else {
        this.router.navigate(["/"]);
      }
    });
  }

  redirect(redirectUrl): void {
    this.router.navigateByUrl(redirectUrl);
  }

  showLoginError(error): void {
    this.messageService.openSnackBar(error, "error");
  }
}
