<div class="gestion__block--attributes">
  <div class="large-6 small-12">
    <div class="gestion__block">
      <div class="gestion__input-box">
        <label for="product-tax">Jeu d'attribut</label>
        <mat-form-field class="gestion__input">
          <mat-select 
            id="attribute_set_id"  
            [formControl]="attribute_set_id"
            (selectionChange)="changeSetAttributeSelect($event)"
          >
            <mat-option *ngFor="let opt of data?.dataForm?.attribute_set" [value]="opt.value">{{opt.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="gestion__block">
        <div class="gestion__input-box">
            <label for="product-tax">Attribut(s)</label>
            <mat-form-field class="gestion__input">
              <mat-select 
                id="product-attributes" 
                multiple
                [formControl]="attributes"
                (selectionChange)="changeChosenSelect($event.value)"
              >
                <mat-option *ngFor="let opt of data?.dataForm?.attributes" [value]="opt.code">{{opt.label}}</mat-option>
              </mat-select>
            </mat-form-field> 
          </div>
    </div>
  </div>
  <div class="large-6 small-12 right_column">
    <form [formGroup]="formGroup">
      <div class="gestion__block" *ngFor="let item of formGroup.controls | keyvalue; let index">
        <div class="gestion__input-box">
            <label for="product-tax">{{getDataFromCode(item.key, 'label')}}</label>
            <mat-form-field class="gestion__input">
              <ng-container *ngIf="getDataFromCode(item.key, 'type') != 'text'">
                <mat-select
                  id="item.id"
                  [multiple]="getDataFromCode(item.key, 'type') == 'multiselect'"
                  [formControlName]="item.key"
                  (selectionChange)="changeSelectAttributes($event.value)"
                >
                  <mat-option *ngFor="let opt of getDataFromCode(item.key, 'values')" [value]="opt.value">{{opt.label}}</mat-option>
                </mat-select>
              </ng-container>
              <ng-container *ngIf="getDataFromCode(item.key, 'type') == 'text'">
                <input 
                  matInput
                  id="item.id" 
                  type="text"
                  [formControlName]="item.key"
                  (input)="changeInputText()"
                >
              </ng-container>
            </mat-form-field>
          </div>
      </div>
    </form>
  </div>
  <!-- <div class="delete_product_button" *ngIf="isEdit">
    <button type="button"  mat-flat-button color="secondary" class="delete" (click)=deleteProduct(data?.dataConfig?.sku)>
      Supprimer le produit
    </button> 
  </div> -->
</div>
