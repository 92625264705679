import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/utils/message/message.service';
import { StripeService } from 'src/app/utils/stripe/stripe.service';

@Component({
  selector: 'stripe-attachments',
  templateUrl: './stripe-attachments.component.html',  
  host: {
    'class': 'stripe-home__wrapper'
  }
})
export class StripeAttachmentsComponent implements OnInit {
  loader: boolean = false;
  isRequesting: boolean = false;
  formGroup: FormGroup;
  imageError: string = null;
  photo_identite_recto: any = null;
  photo_identite_verso: any = null;
  photo_identite_recto_id: any = null;
  photo_identite_verso_id: any = null;
  attachmentType = new FormControl()
  
  @Input() data: any;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private stripeService: StripeService
  ) { }

  ngOnInit(): void {
    // Construction du formulaire
    this.formGroup = this.formBuilder.group({
      attachmentType: this.attachmentType,
    })

    this.attachmentType.setValue('passeport')
  }

  changeInputText() {
    this.data = {...this.data, ...this.formGroup.value}
  }

  deleteImageProduct() {
    this.data = {...this.data, ...this.formGroup.value}
  }

  attachmentTypeChange() {
    this.photo_identite_recto = null, 
    this.photo_identite_verso = null, 
    this.photo_identite_recto_id = null, 
    this.photo_identite_verso_id = null 
    this.data = {...this.data, ...this.formGroup.value}
  }

  /**
   * Upload en base64
   * @param fileInput 
   */
   fileChangeEvent(fileInput: any, type: string) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];

      if (fileInput.target.files[0].size > max_size) {
        this.imageError = 'Taille maximum autorisée ' + max_size / 1000 + 'Mb';
        return false;
      }
      if (!allowed_types.includes(fileInput.target.files[0].type)) {
        this.imageError = 'Extensions autorisées : ( JPG | PNG | PDF )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgBase64Path = e.target.result;
        const {idAccount} = this.data
        if(type === 'verso'){
          this.photo_identite_verso = imgBase64Path
        }else{
          this.photo_identite_recto = imgBase64Path
        }
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  saveForm() {
    let payload = this.data;
    const {idAccount, idPerson} = payload
    if(this.formGroup.value.attachmentType === 'identite' ? (this.photo_identite_verso && this.photo_identite_recto && !this.isRequesting)
    : (this.photo_identite_recto && !this.isRequesting)){
      this.isRequesting = true;
      // Si pièce d'identité on envoie recto verso
      // Sinon on envoie deux fois la même photo pour le passeport pour l'api stripe
      if(this.formGroup.value.attachmentType === 'identite'){
        this.stripeService.updateStripeFile(idAccount, idPerson, this.photo_identite_verso, this.photo_identite_recto).subscribe(_ => {
          this.messageService.openSnackBar('Pièces jointes sauvegardées avec succès', 'success')
        }, error => {
          this.messageService.openSnackBar('Echec lors de la sauvegarde des pièces jointes', 'error')
          this.isRequesting = false; 
        }, () => {
          this.isRequesting = false; 
        })
      }else{
        this.stripeService.updateStripeFile(idAccount, idPerson, this.photo_identite_recto, this.photo_identite_recto).subscribe(_ => {
          this.messageService.openSnackBar('Pièces jointes sauvegardées avec succès', 'success')
        }, error => {
          this.messageService.openSnackBar('Echec lors de la sauvegarde des pièces jointes', 'error')
          this.isRequesting = false; 
        }, () => {
          this.isRequesting = false; 
        })
      }
    }else{
      this.messageService.openSnackBar('Veuillez ajouter les pièces jointes nécéssaires.', 'error')
      this.isRequesting = false; 
    }
    
  }
}
