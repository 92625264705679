<div class="help-wrapper">
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          1 - Installation
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="help-container">
        <div class="help-title">Matériels</div>
        <div class="img-container img-2">
          <img src="/assets/images/help-print/materiel-1.jpg">
          <img src="/assets/images/help-print/materiel-2.jpg">
          <img src="/assets/images/help-print/materiel-3.jpg">
          <img src="/assets/images/help-print/materiel-4.jpg">
        </div>
        <div class="help-title">Branchements</div>
        <p>
          Afin d'accéder aux branchements beaucoup plus facilement, 
          vous pouvez déclipser les caches en plastiques noirs
          situés sur le dessus et à l'arrière de l'imprimante
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/materiel-5.jpg">
        </div>
        <p>
          Après avoir correctement assemblé le bloc d'alimentation, 
          connecter l'embout du câble d'alimentation à l'imprimante comme ci-dessous.
          <br />
          Attention à ce que la flèche sur l'embout soit tournée vers le bas comme sur la photo 
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/materiel-6.jpg">
        </div>
        <p>
          Brancher maintenant le dongle wifi en usb sur votre imprimante.
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/materiel-7.jpg">
        </div>
        <p>
          Vous pouvez ensuite re-clipser les caches, en laissant passer le câble 
          d'alimentation sur le côté, et le brancher à une prise électrique.
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/materiel-8.jpg">
        </div>
        <p>
          Avant d'allumer l'imprimante, insérez-y un rouleau de ticket de caisse 
          grâce au bouton gris situé sur le dessus, côté droit de l'imprimante quand elle est face à vous.
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/materiel-9.jpg">
        </div>
        <mat-action-row>
          <button mat-button color="primary" (click)="nextStep()">Suivant</button>
        </mat-action-row>
      </div>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          2 - Initialisation de l'imprimante
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="help-container">
        <div class="help-title">Allumer l'imprimante</div>
        <p>
          Attendez plusieurs secondes après la mise en marche de l'imprimante, un ticket devrait être imprimé.
        </p>
        <ul>
          <li>
            Si celui-ci ressemble à la photo ci-dessous vous pouvez passer à la prochaine <a (click)="setStep(3)">étape.</a> <br />
            Par contre si vous avez des difficultés à vous connecter au panneau 
            de configuration de l'imprimante lors de l'étape suivante,
            vous devrez peut-être réinitialiser votre imprimante en suivant le reste de cette étape.
          </li>
          <li>
            Si aucun ticket ne sort vous devez réinitialiser votre imprimante en suivant le reste de cette étape
          </li>
        </ul>
        <div class="img-container">
          <img src="/assets/images/help-print/init-1.jpg">
        </div>
        <div class="help-title">Réinitialisation de l'imprimante</div>
        <ul>
          <li>Eteignez l'imprimante</li>
          <li>Allumez la tout en laissant le doigt enfoncé sur le bouton feed (juste en dessous du bouton d'allumage)</li>
          <li>Un ticket va sortir, relâchez le bouton</li>
          <li>Enfoncez ensuite de nouveau le bouton jusqu'à ce qu'un autre ticket sorte</li>
          <li>Sur ce ticket il y a des choix (choix 1, choix 2 etc...)</li>
          <li>Faire le choix 5 (Interface Setup)</li>
          <li>Pour cela, appuyez 5 fois rapidement sur le bouton feed et une 6ème fois plus longuement pour valider</li>
          <li>Ensuite dans les propositions faire le choix 2 (Wi-Fi)</li>
          <li>Pour cela, appuyez 2 fois rapidement sur le bouton feed et une 3ème fois plus longuement pour valider</li>
          <li>Ensuite dans les propositions faire le choix 2 (SimpleAP)</li>
          <li>Pour cela, appuyez 2 fois rapidement sur le bouton feed et une 3ème fois plus longuement pour valider</li>
          <li>Si le numéro du choix change suite à une mise à jour, suivez evidemment simplement la même logique pour accéder aux menus indiqués : Interface setup &gt; Wi-Fi &gt; SimpleAP</li>
        </ul>
        <p>
          Une fois les instructions précédentes, vous devriez avoir un ticket comme ci-dessous
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/init-2.jpg">
        </div>
        <p>
          Patientez quelques instants et le ticket SimpleAp devrait sortir
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/init-1.jpg">
        </div>
        <mat-action-row>
          <button mat-button color="primary" (click)="nextStep()">Suivant</button>
        </mat-action-row>
      </div>

    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          3 - Configuration de l'imprimante
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="help-container">
        <div class="help-title">Connexion au réseau de l'imprimante</div>
        <p>
          Connectez-vous au réseau Wi-Fi de l'imprimante avec votre tablette ou autre appareil.<br />
          On retrouve les paramètres de connexion sur le ticket SimpleAP de 
          <a (click)="prevStep()">l'étape précédente</a>. <br />
          Par défault le réseau se nomme <b>EPSON_Printer</b> et le mot de passe est <b>12345678</b>
        </p>
        <div class="help-title">Connexion de l'imprimante au réseau local</div>
        <p>
          Avant de pouvoir continuer, il y a plusieurs informations essentielles que vous devez connaître dont nous allons avoir besoin pour la suite :
        </p>
        <ul>
          <li>
            Le SSID de votre box (le nom du wifi de votre box)
          </li>
          <li>
            Le mot de passe de votre wifi
          </li>
          <li>
            Le type d'encryption du signal (WPA2-PSK souvent par défaut, WPA2-PSK/AES ou TKIP pour les box récentes, ou même WPA-PSK parfois sur les box plus anciennes). 
            <br/>Connectez vous à l'interface de votre box pour obtenir cette information si nécessaire
          </li>
        </ul>
        <p>
          Maintenant que nous sommes sur le réseau générée par l'imprimante vous pouvez accéder a son panneau de configuration via son adresse IP. <br />
          Pour cela ouvrez Chrome et tapez dans la barre d'adresse, l'IP de l'imprimante.<br/>
          On la retrouve sur le ticket SimpleAP de <a (click)="prevStep()">l'étape précédente</a>.
          <br />Par défault l'adresse est <b>192.168.192.168</b>
        </p>
        <p>
          Avant d'arriver sur la page de configuration vous pourrez tomber sur ceci :
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/config-1.jpg">
        </div>
        <p>
          Cliquez alors sur le bouton "Paramètres avancés", puis sur "continuer vers le site ... (dangereux)".
          Rassurez-vous ce n'est pas dangereux, c'est juste un certificat de sécurité manquant pour le HTTPS.
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/config-2.jpg">
        </div>
        <p>
          En arrivant sur l'interface, à la première connexion il vous sera demandé un identifiant et un mot de passe.<br/>
          Par défault:
        </p>
        <ul>
          <li>
            identifiant : epson
          </li>
          <li>
            mot de passe : epson
          </li>
        </ul>
        <div class="img-container">
          <img src="/assets/images/help-print/config-3.jpg">
        </div>
        <p>
          Vous êtes maintenant sur l'interface de configuration. <br />
          Sur le panneau de gauche, cliquez sur le bouton "Wireless"
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/config-6.jpg">
        </div>
        <p>
          Sur cette page il va falloir maintenant renseigner toutes les informations de votre box.<br />
          Attention à bien les saisir, à la lettre et à la majuscule prêt car si vous vous trompez 
          il faudra reinitialiser l'imprimante et tout recommencer depuis <a (click)="prevStep()">l'étape précédente</a>.
        </p>
        <ul>
          <li>
            Network Mode : Infrastructure
          </li>
          <li>
            SSID : nom du wifi de votre box
          </li>
          <li>
            Encryption Type : WPA2-PSK par défaut (WPA-PSK parfois sur les anciennes box, vérifiez si vous avez un doute)
          </li>
          <li>
            WPA Pre-Shared Key : Le mot de passe de votre wifi
          </li>
        </ul>
        <p>
          Après avoir renseignez les informations, cliquez sur le bouton "send".
        </p>
        <p>Vous arrivez alors sur la page ci-dessous, cliquez sur le bouton "Reset".</p>
        <div class="img-container">
          <img src="/assets/images/help-print/config-4.jpg">
        </div>
        <p>
          Attendez un instant et si vous avez bien renseigné vos informations de connexion, 
          l'imprimante va redémarrer et sortir un ticket avec sa nouvelle adresse ip.
          Vous pouvez alors fermer le panneau de configuration sur votre navigateur web
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/config-5.jpg">
        </div>
        <mat-action-row>
          <button mat-button color="primary" (click)="nextStep()">Suivant</button>
        </mat-action-row>
      </div>
    </mat-expansion-panel>
    
    <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          4 - Erreur de sécurité
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="help-container">
        <div class="help-title">Invalidité du certificat</div>
        <p>
          <b>
            Ceci est une étape obligatoire qu'il faut répéter sur chaque appareil
            utilisant l'impression et à chaque fois que l'adresse IP change
          </b>
        </p>
        <p>
          Pour fonctionnez en HTTPS, il faut accepter l'invalidité du certificat 
          sur le panneau de configuration de l'imprimante.<br/>
          Pour cela il faut, comme lors de l'étape précédente:
        </p>
        <ul>
          <li>Ouvrir Chrome</li>
          <li>
            Taper la nouvelle adresse IP de l'imprimante dans la barre d'adresse<br /> 
            On retrouve cette adresse sur le ticket imprimé au démarage de l'imprimante.
          </li>
          <li>
            Cliquer sur le bouton "Paramètres avancés", puis sur "continuer vers le site ... (dangereux)".
          </li>
        </ul>
        <div class="img-container img-2">
          <img src="/assets/images/help-print/config-5.jpg">
        </div>
        <div class="img-container">
          <img src="/assets/images/help-print/config-2-b.jpg">
        </div>
        <p>
          Vous serez maintenant capable de vous connecter à l'imprimante avec l'application
        </p>
      </div>
      <mat-action-row>
        <button mat-button color="primary" (click)="nextStep()">Suivant</button>
      </mat-action-row>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          5 - Configuration dans l'application
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="help-container">
        <div class="help-title">Paramètres</div>
        <p>
          Dans les <a [routerLink]="['/settings']">paramètres</a>, 
          renseignez l'adresse ip de l'imprimante.<br />
          On retrouve cette adresse sur le ticket imprimé au démarage de l'imprimante.
        </p>
        <div class="img-container">
          <img src="/assets/images/help-print/config-5.jpg">
        </div>
        <p>
          Faites une impression "test" pour vérifier que la connexion s'est bien effectuée.<br />
          Si ça ne fonctionne pas:
        </p> 
        <ul>
          <li>Essayez de recharger l'application et de recommencer.</li>
          <li>Vérifier si l'invalidité du certificat a été acceptée. <a (click)="setStep(4)">(étape 4)</a></li>
          <li>Vérifier que votre navigateur Chrome est à jour.</li>
        </ul>
        

      </div>
    </mat-expansion-panel>


  </mat-accordion>
</div>