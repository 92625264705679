<form [formGroup]="formGroup">
  <ng-container *ngIf="!loader; else loadBlock">
    <div class="row">
      <div class="column small-12 medium-6">
        <div class="gestion__block">
          <div class="gestion__input-box">
            <label for="stripe-name">Nom de l'entreprise</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-name" 
                type="text"
                [formControl]="name"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="stripe-tax_id" style="position: relative">SIREN<div class="edit_siren" (click)="openModalChangeSiren()" *ngIf="data.tax_id === true"><mat-icon aria-hidden="true" class="ok">edit</mat-icon></div></label>
            <ng-container *ngIf="data.tax_id !== true">
              <mat-form-field class="gestion__input">
                <input 
                  matInput
                  id="stripe-tax_id" 
                  type="text"
                  [formControl]="tax_id"
                  (input)="changeInputText()"
                >
              </mat-form-field>
            </ng-container>
            <div *ngIf="data?.tax_id === true" class="stripe_field_ok">Déjà renseigné</div>
          </div>
          <div class="gestion__input-box">
            <label for="stripe-mcc" class="stripe_mcc_id_container">Secteur <a target="_blank" href="https://stripe.com/docs/connect/setting-mcc" class="stripe_mcc_id">i</a></label>
            <mat-form-field class="gestion__input" disabled>              
              <mat-select 
                id="stripe_mcc" 
                [formControl]="mcc"
                (selectionChange)="changeInputText()"
              >
                <input matInput class="search_in_select" type="text" (keyup)="onSearch($event.target.value)" placeholder="Rechercher un secteur">
                <mat-option *ngFor="let opt of mccArray" [value]="opt.id">{{opt.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="gestion__input-box">
            <label for="stripe-url">Site web</label>
            <mat-form-field class="gestion__input">
              <input 
                matInput
                id="stripe-url" 
                type="text"
                [formControl]="url"
                (input)="changeInputText()"
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="save_stripe_form">
      <a mat-flat-button color="primary" class="commande__ready catalog-create-product" (click)="saveForm()" [disabled]="isRequesting">
        <mat-icon aria-hidden="true" class="ok">done</mat-icon>Sauvegarder
      </a>
    </div>
  </ng-container>
  <ng-template #loadBlock>
    <div class="loader_container">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </ng-template>
</form>
