import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MessageService } from 'src/app/utils/message/message.service';
import { emailValidator } from 'src/app/utils/validator/validators.service';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { Router } from '@angular/router';
// @ts-ignore
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  resetLogin = new FormControl(
    '',
    [
      Validators.required,
    ]
  );

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      resetLogin: this.resetLogin
    })
  }
  onSubmit(): void {    
    this.authService.requestResetPassword(this.resetLogin.value).subscribe(
      success => { 
        this.messageService.openSnackBar('Un email vous a été envoyé pour réinitialiser votre mot de passe', 'success')
      },
      error => { 
        this.messageService.openSnackBar(error, 'error')
       }
    )
  }
  getErrorMessage(control: FormControl): string {
    return this.messageService.getErrorMessage(control);
  }
}
