import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { SidebarLineComponent } from './sidebar-line/sidebar-line.component';
import { AppSidebarComponent, MatPaginatorIntlCro } from './app-sidebar.component';
import { CategoryPriceModule } from 'src/app/_components/category-price/category-price.module';
import { BulleAvatarModule } from 'src/app/_components/bulle-avatar/bulle-avatar.module';
import { BulleStatusModule } from 'src/app/_components/bulle-status/bulle-status.module';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';
import { DateHeureModule } from 'src/app/_components/date-heure/date-heure.module';
import { SearchModalModule } from 'src/app/_components/search-modal/search-modal.module';
import { MatPaginatorIntl } from '@angular/material/paginator';


@NgModule({
   imports: [
      CommonModule,
      RouterModule,
      MaterialModule,
      CategoryPriceModule,
      BulleAvatarModule,
      BulleStatusModule,
      DateHeureModule,
      CustomPipeModule,
      SearchModalModule
   ],
   declarations: [
      AppSidebarComponent,
      SidebarLineComponent
   ],
   exports: [  
      AppSidebarComponent,
      SidebarLineComponent
   ],
   providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro}],
})
export class AppSidebarModule { }