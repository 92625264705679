<div class="ticket-modale--item">
    <!-- <section class="item--head">
        <button mat-icon-button class="close__modale" (click)="closeModal()"></button>
    </section> -->
    <section class="text">
        <div class="image_container">
            <img src="assets/images/deco/attention_blue.png.png" alt="">
        </div>
        <p>
            Vous êtes le premier commerçant
            prêt pour l’envoi de cette commande
            multi-enseignes !
        </p>

        <p>
            Vous êtes donc le responsable de l'envoi du colis pour l'ensemble des commerçants, 
            et vous seul pourrez générer l'étiquette postale unique.
        </p>

        <p>
            Les autres commerçants devront
            vous fournir leurs commandes respectives
            afin que vous puissiez envoyer l’ensemble
            dans un seul et unique colis.
        </p>
        <div class="show_again">
            <mat-checkbox (change)="toggleModalTicket($event)" class="example-margin">Ne plus afficher</mat-checkbox>
        </div>
    </section>
    <div class="button_container">
        <button mat-flat-button color="primary" class="commande__ready accept" (click)="closeModal()">
            Valider
        </button>
    </div>
</div>