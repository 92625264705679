import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { MessageService } from 'src/app/utils/message/message.service';
import { Router } from '@angular/router';
import { passwordValidator, passwordMinLength } from 'src/app/utils/validator/validators.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  login = new FormControl('', [Validators.required]);
  password = new FormControl('', [
    Validators.required, 
    Validators.minLength(passwordMinLength), 
    passwordValidator()
  ]);
  hide: boolean = true;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router, 
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      login: this.login,
      password: this.password,
    })
  }

  redirect(redirectUrl): void {
    this.router.navigateByUrl(redirectUrl)
  }

  showLoginError(error): void {
    this.messageService.openSnackBar(error, 'error')
  }

  onSubmit(): void {
    const {login, password} = this.loginForm.value
    this.authService.login(login, password).subscribe(
      this.redirect.bind(this),
      this.showLoginError.bind(this),
    );
  }
  getErrorMessage(control: FormControl): string {
    return this.messageService.getErrorMessage(control);
  }

}
