import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-print',
  templateUrl: './help-print.component.html',
  styles: [
  ]
})
export class HelpPrintComponent implements OnInit {
  step = 0;

  constructor() { }

  ngOnInit(): void {
  }

  setStep(index: number): void {
    this.step = index;
  }
  
  nextStep(): void {
    this.step++;
  }
  
  prevStep(): void {
    this.step--;
  }

}
