import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateHeureComponent } from './date-heure.component';
import { CustomPipeModule } from 'src/app/utils/pipe/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    CustomPipeModule
  ],
  declarations: [
    DateHeureComponent
  ],
  exports: [
    DateHeureComponent
  ]
})
export class DateHeureModule { }
