import { createReducer, on, Action } from '@ngrx/store';
import * as ShopAction from './shop.action'
import { ShopState } from 'src/app/models/shop';

export const initialState: ShopState = {
  visual_url: '',
  preparation_time: '',
  contact_email: '',
  description: '',
  delay: 5,
  time_slot_disable_today: "0",
  exceptionalClosings: [],
  horaires: [],
  order_quota: "0",
};


const _shopReducer = createReducer(
  initialState,
  on(ShopAction.setShop, 
    (state, {shop}) => {
      return ({...state, ...shop })
    }),
  on(ShopAction.setVisualUrl, 
    (state, {visual_url}) => {
      return ({...state, visual_url })
    }),
  on(ShopAction.setEmail, 
    (state, {contact_email}) => { 
      return ({...state, contact_email })
    }),
  on(ShopAction.setDescription, 
    (state, {description}) => {
      return ({...state, description })
    }),
  on(ShopAction.setDelay, 
    (state, {preparation_time}) => {
      return ({...state, preparation_time })
    }),
  on(ShopAction.setDisableToday, 
    (state, {time_slot_disable_today}) => {
      return ({...state, time_slot_disable_today })
    }),
  on(ShopAction.setExceptionalClosure, 
    (state, {dates}) => {
      return ({...state, exceptionalClosings: dates })
    }),
  on(ShopAction.addExceptionalClosure, 
    (state, {date}) => {
      return ({...state, exceptionalClosings: _addDateToExceptionalClosings(state, date) })
    }),
  on(ShopAction.removeExceptionalClosure, 
    (state, {date}) => {
      return ({...state, exceptionalClosings: _removeDateToExceptionalClosings(state, date) })
    }),
  on(ShopAction.setHoraires, 
    (state, {horaires}) => {
      return ({...state, horaires })
    }),
  on(ShopAction.setQuota, 
    (state, {order_quota}) => { 
      return ({...state, order_quota })
    }),
);


const _addDateToExceptionalClosings = (state: ShopState, date: string): string[] => {
  let dates = [...state.exceptionalClosings]
  if(!dates.includes(date)){
    dates.push(date)
  }
  return dates
}
const _removeDateToExceptionalClosings = (state: ShopState, date: string): string[] => {
  let dates = state.exceptionalClosings.filter(el => ( el !== date))
  return dates;
}

export function shopReducer(state: ShopState | undefined, action: Action) {
  return _shopReducer(state, action);
}