<div class="gestion__container">
    <div class="gestion__header">
        <div class="gestion__row">                
            <a mat-icon-button class="back_button" routerLink="/" routerLinkActive="active"></a>
            <div class="titles">            
                <h2>Reporting</h2>
                <span>{{shopName$ | async}}</span>
            </div>
        </div>
    </div>
    <div class="reporting__resume gestion__row">
        <div class="reporting__left">
            <mat-form-field>
                <mat-label>Voir par :</mat-label>
                <mat-select [formControl]="sortControl" (selectionChange)="onChangeSort($event.value)"> 
                  <mat-option *ngFor="let sort of sorts" [value]="sort.value">
                    {{sort.viewValue}}  
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="reporting__date">
                <span *ngIf="sortControl.value == 'daily'">{{date.format() | day}}</span>
                <span *ngIf="sortControl.value == 'weekly'">{{date.format() | week}}</span>
                <span *ngIf="sortControl.value == 'monthly'">{{date.format() | month}}</span>   
                <div class="prev__next">
                    <div 
                        class="datepicker" 
                        *ngIf="sortControl.value == 'daily'" 
                        (click)="picker.open()"
                    > 
                        <mat-form-field>
                            <input 
                                matInput 
                                [matDatepicker]="picker" 
                                [min]="minDate"
                                [max]="maxDate"
                                [formControl]="datepickerControl" 
                                (dateChange)="onSelectDatePicker($event.value)" 
                            />
                            <mat-datepicker #picker></mat-datepicker> 
                        </mat-form-field>
                    </div>
                    <button class="prev" (click)="onClickPrev()"></button>
                    <button class="next" (click)="onClickNext()"></button>
                </div>
            </div>
            <div class="reporting__stats">
                <div class="reporting__stats--bloc ca">
                    <span class="total">{{ca_global}} €</span>
                    <span class="desc">Chiffre d’affaires total</span>
                    <span class="desc">dont {{ca_pending}} € <b>en attente</b> et {{ca_finish}} € terminé</span>
                </div>
                <div class="reporting__stats--bloc orders">
                    <span class="total">{{numberOrders}}</span>
                    <span class="desc">Commande{{numberOrders | plural}}</span>
                </div>
            </div>
        </div>
        <div class="reporting__right">
            <div class="reporting__bestseller">
                <div class="title">
                    <div class="text">
                        <div>Meilleures ventes</div> 
                        <span>du mois de {{currentMonth | month}}</span>
                    </div>
                </div>
                <div class="reporting__ranking" *ngFor="let order of bestOrders$ | async; let last = last; let index = index">
                    <div class="reporting__ranking--item" [ngClass]="{ last: last }">
                        <span class="rank">{{index+1}}</span>
                        <span class="reporting__ranking--product">{{order.product_name}}</span>
                        <span class="reporting__ranking--qty">{{order.qty}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>